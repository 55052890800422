import { Typography } from "@mui/material";
import React from "react";
import LandingFooter from "./LandingFooter";

const Terms = () => {
  return (
    <>
      <div className="privacypolicy">
        <div className="privacybox">
          <Typography>
            <h3>Terms and Conditions for Cypher</h3>

            <p>
              Welcome to Cypher! These Terms and Conditions ("Terms") govern
              your access to and use of our website and services. By accessing
              or using our website, you agree to comply with these Terms. If you
              do not agree to these Terms, please do not use our website.
            </p>

            <h4>1. Purpose</h4>
            <p>
              These Terms establish the rules and guidelines for using the
              Cypher website and services, ensuring that users understand their
              rights and responsibilities. Our platform is dedicated to
              preventing corporate fraud and protecting corporate secrets, and
              these Terms help create a secure environment for all users.
            </p>

            <h4>2. Agreement</h4>
            <p>
              By using our website, you confirm that you have read, understood,
              and agree to be bound by these Terms. If you disagree with any
              part of these Terms, you must stop using the website immediately.
              Continued use of the website after any changes to these Terms will
              constitute your acceptance of those changes.
            </p>

            <h4>3. Eligibility</h4>
            <p>
              You must be at least 17 years old to use this website. By using
              our services, you represent and warrant that you meet this age
              requirement. If you are using the services on behalf of a
              corporation or other legal entity, you represent that you have the
              authority to bind that entity to these Terms. If you are under 17,
              you may not use our services.
            </p>

            <h4>4. Modifications</h4>
            <p>
              We reserve the right to modify these Terms at any time without
              prior notice. Significant changes will be posted on our website.
              It is your responsibility to review these Terms periodically. Your
              continued use of the website after changes are posted constitutes
              acceptance of the new Terms. Modifications will be effective
              immediately upon posting.
            </p>

            <h4>5. User Responsibilities</h4>
            <h5>Lawful Use</h5>
            <p>
              You agree to use the website in compliance with all applicable
              laws and regulations. You are responsible for ensuring that your
              use of the site does not violate any laws in your jurisdiction.
              You must not use the website in a manner that could damage,
              disable, overburden, or impair the website or interfere with any
              other party's use of the website.
            </p>

            <h5>Prohibited Activities</h5>
            <p>
              Users are prohibited from engaging in the following activities:
            </p>
            <ul>
              <li>
                <strong>Spamming:</strong> Sending unsolicited communications,
                advertisements, or promotional materials.
              </li>
              <li>
                <strong>Hacking:</strong> Attempting to gain unauthorized access
                to the website, accounts, or networks connected to the website.
              </li>
              <li>
                <strong>Posting Harmful Content:</strong> Uploading, posting, or
                transmitting any content that is harmful, threatening, abusive,
                harassing, defamatory, obscene, or otherwise objectionable.
              </li>
              <li>
                <strong>Impersonation:</strong> Impersonating any person or
                entity, or falsely stating or misrepresenting your affiliation
                with a person or entity.
              </li>
              <li>
                <strong>Data Mining:</strong> Using automated means, such as
                bots, spiders, or scrapers, to collect data from the website
                without our express written permission.
              </li>
              <li>
                <strong>Interfering with Security:</strong> Circumventing or
                attempting to circumvent any security measures on the website.
              </li>
              <li>
                <strong>Violating Rights:</strong> Violating any intellectual
                property rights or other rights of any person or entity.
              </li>
              <li>
                <strong>Harassment:</strong> Engaging in harassment of any kind,
                including but not limited to stalking, threatening, or abusive
                behavior.
              </li>
              <li>
                <strong>Fraudulent Activity:</strong> Engaging in any fraudulent
                or deceptive practices.
              </li>
              <li>
                <strong>Distributing Viruses:</strong> Transmitting any material
                that contains viruses, worms, trojan horses, or any other
                harmful code.
              </li>
              <li>
                <strong>Using the Site for Illegal Purposes:</strong> Using the
                website for any illegal or unauthorized purpose.
              </li>
            </ul>

            <h4>6. Ownership of Content</h4>
            <p>
              All content on the Cypher website, including text, graphics,
              logos, and images, is owned by Cypher or our content suppliers and
              is protected by copyright, trademark, and other intellectual
              property laws. You may not use, reproduce, distribute, or modify
              any content without our express written permission. We reserve all
              rights not expressly granted to you in these Terms. Unauthorized
              use of our content may result in legal action.
            </p>

            <h4>7. Privacy</h4>
            <p>
              Your privacy is important to us. We collect and process your
              personal information in accordance with our Privacy Policy. By
              using our services, you consent to the collection and use of your
              information as described in our{" "}
              <a href="/privacy">Privacy Policy</a>. We are committed to
              protecting your personal information and ensuring its
              confidentiality, including how we collect, use, and store your
              information, and under what circumstances we may share it with
              third parties.
            </p>

            <h4>8. Disclaimer</h4>
            <p>
              The information and services provided by Cypher are intended for
              educational and informational purposes only. While we strive for
              accuracy, we make no guarantees regarding the completeness or
              reliability of the information. For more details, please see our{" "}
              <a href="/disclaimer">Disclaimer</a>. We do not assume any
              liability for any errors or omissions in the information provided.
              Your reliance on any information provided by us is at your own
              risk.
            </p>

            <h4>9. Limitation of Liability</h4>
            <p>
              To the maximum extent permitted by law, Cypher shall not be liable
              for any direct, indirect, incidental, or consequential damages
              arising from your use or inability to use the website, including
              but not limited to loss of profits, goodwill, or any other
              intangible losses, even if we have been advised of the possibility
              of such damages. This limitation applies to any damages or
              injuries caused by any failure of performance, error, omission,
              interruption, deletion, defect, delay in operation or
              transmission, computer virus, communication line failure, theft,
              destruction, or unauthorized access to, alteration of, or use of
              any record. You agree that your sole remedy for any
              dissatisfaction with the website is to stop using it.
            </p>

            <h4>10. Jurisdiction</h4>
            <p>
              These Terms shall be governed by and construed in accordance with
              the laws of Switzerland. Any disputes arising from these Terms or
              your use of the website shall be subject to the exclusive
              jurisdiction of the courts in Switzerland. You agree to submit to
              the personal jurisdiction of such courts and waive any
              jurisdictional, venue, or inconvenient forum objections to such
              courts. You further agree that any claims must be brought
              individually and not as part of any class action.
            </p>

            <h4>11. Termination Rights</h4>
            <p>
              We reserve the right to terminate or suspend your access to the
              website at our sole discretion, without prior notice, for conduct
              that we believe violates these Terms or is harmful to other users,
              the website, or our business interests. Upon termination, your
              right to use the website will immediately cease. If you wish to
              terminate your account, you may simply discontinue using the
              website. We also reserve the right to modify or discontinue the
              website at any time without notice.
            </p>

            <h4>12. User Accounts</h4>
            <p>
              To access certain features of the website, you may be required to
              create an account. When you create an account, you agree to:
            </p>
            <ul>
              <li>Provide accurate, current, and complete information.</li>
              <li>
                Maintain the security of your password and accept all risks of
                unauthorized access to your account.
              </li>
              <li>
                Notify us immediately if you suspect any unauthorized use of
                your account or any other breach of security.
              </li>
              <li>
                Take responsibility for all activities that occur under your
                account.
              </li>
              <li>
                Ensure that your account details are kept up to date, including
                your email address.
              </li>
            </ul>

            <h4>13. Third-Party Links</h4>
            <p>
              Our website may contain links to third-party websites or services
              that are not operated by us. We have no control over, and assume
              no responsibility for, the content, privacy policies, or practices
              of any third-party sites or services. We encourage you to read the
              terms and conditions and privacy policies of any third-party
              websites that you visit. We are not responsible for the accuracy
              or reliability of any information, opinions, claims, or advice
              shared on third-party sites.
            </p>

            <h4>14. Indemnification</h4>
            <p>
              You agree to indemnify, defend, and hold harmless Cypher and its
              affiliates, licensors, and service providers from and against any
              claims, liabilities, damages, judgments, awards, losses, costs,
              expenses, or fees (including reasonable attorneys' fees) arising
              out of or relating to your violation of these Terms or your use of
              the website, including, but not limited to, your user
              contributions, any use of the website's content, services, or
              products other than as expressly authorized in these Terms, or
              your violation of any law or the rights of any third party.
            </p>

            <h4>15. Entire Agreement</h4>
            <p>
              These Terms constitute the entire agreement between you and Cypher
              regarding your use of the website and supersede any prior
              agreements. If any provision of these Terms is found to be
              unenforceable, the remaining provisions will remain in effect and
              will be construed to reflect the original intent of the parties.
            </p>

            <h4>16. Waiver</h4>
            <p>
              The failure of Cypher to enforce any right or provision of these
              Terms will not be deemed a waiver of such right or provision. Any
              waiver of any provision of these Terms will be effective only if
              in writing and signed by an authorized representative of Cypher.
            </p>

            <h4>17. Force Majeure</h4>
            <p>
              Cypher will not be liable for any failure to perform its
              obligations under these Terms where such failure results from any
              cause beyond Cypher's reasonable control, including, but not
              limited to, mechanical, electronic, or communications failure or
              degradation.
            </p>

            <h4>18. Contact Information</h4>
            <p>
              If you have any questions about these Terms, please contact us at{" "}
              <a href="mailto:support@cypherforensics.com">
                support@cypherforensics.com
              </a>
              . We will make every effort to respond to your inquiries in a
              timely manner.
            </p>
          </Typography>
        </div>
      </div>
      <LandingFooter />
    </>
  );
};

export default Terms;
