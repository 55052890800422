import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

const PaymentHistory = ({ caselist }) => {
  const sumCaseValues = (caseList) => {
    let total = 0;

    caseList.forEach((caseItem) => {
      const value = caseItem.value; // Convert to number
      if (!isNaN(value)) {
        total += value; // Accumulate total
      }
    });

    return total; // Return the total sum
  };

  function formatAmount(amount) {
    if (isNaN(amount)) {
      return "Invalid amount";
    }

    return `USD ${amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  }

  return (
    <div className="paymenthistory">
      <div className="casehistorybox">
        <Table sx={{ width: `40%` }}>
          <TableHead>
            <TableRow>
              <TableCell> Asset History</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Assets lost</TableCell>
              <TableCell sx={{ color: "red", textWrap: "nowrap" }}>
                {formatAmount(sumCaseValues(caselist))}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Assets recovered</TableCell>
              <TableCell sx={{ color: "green", textWrap: "nowrap" }}>
                NA
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Recovery ratio</TableCell>
              <TableCell>NA</TableCell>
            </TableRow>
          </TableBody>
          <TableHead>
            <TableRow>
              <TableCell>Bill History</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Expenses</TableCell>
              <TableCell sx={{ color: "red" }}>
                {caselist.bill || "NA"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                P<sub>K</sub> discounts
              </TableCell>
              <TableCell>USD 0.00</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                P<sub>K</sub> induced bill
              </TableCell>
              <TableCell>USD 0.00</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Guranteed refund</TableCell>
              <TableCell>USD 0.00 refunded</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default PaymentHistory;
