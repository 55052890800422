import React, { useState, useEffect } from "react";
import GridWrapper from "../../../common/GridWrapper/GridWrapper2";
import { Avatar, Button, Typography } from "@mui/material";
import profileImg from "../../../../images/profile.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBusinessTime,
  faPersonCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import { getUserMe } from "../../../services/user";
import { useNavigate } from "react-router";

const Profile = () => {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await getUserMe();
        const newdata = response.data;
        setUser(newdata);
      } catch (err) {
        setError("Failed to fetch User.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, []);

  const lol = <FontAwesomeIcon icon={faPersonCircleCheck} color="green" />;

  const {
    isFirm,
    firstName,
    lastName,
    email,
    country,
    phone,
    rejected,
    isAdmin,
    isMaster,
  } = user || {};
  const getType = () => {
    if (isFirm) {
      return (
        <span className="margin1">
          Personal
          <FontAwesomeIcon
            icon={faPersonCircleCheck}
            color="green"
            style={{ marginLeft: "0.2rem" }}
          />
        </span>
      );
    } else {
      return (
        <span className="margin1">
          Buiness
          <FontAwesomeIcon
            icon={faBusinessTime}
            color="green"
            style={{ marginLeft: "0.2rem" }}
          />
        </span>
      );
    }
  };

  return (
    <GridWrapper>
      <div className="profile">
        <div className="profilebox">
          <div className="profileimgbox">
            <img src={profileImg} alt="" style={{ width: "100%" }} />
          </div>
          <div className="profiledetails">
            <span>
              Type: <span className="margin1"></span>
            </span>
            <span> {getType()}</span>
          </div>
          <div className="profilename">
            <div>{firstName}</div>
            <div style={{ marginLeft: "10%" }}>{lastName}</div>
          </div>
          <div className="profileemail">{email}</div>

          {country && <div className="profileemail">{country}</div>}

          <div className="profilecases">{phone}</div>
          {rejected && (
            <div className="profilerejected" style={{ marginBottom: "3rem" }}>
              {rejected}
            </div>
          )}

          <Button type="outlined" disabled>
            Update email
          </Button>

          <Button
            type="outlined"
            onClick={() => navigate(`/account/change-password`)}
          >
            Change password
          </Button>
          {isAdmin && (
            <Button onClick={() => navigate("/admin")}>Admin Hub</Button>
          )}
          {isAdmin && (
            <Button onClick={() => navigate("/admin/news")}> Post news</Button>
          )}
          {isMaster && (
            <Button onClick={() => navigate("/master")}>Master Hub</Button>
          )}
        </div>
      </div>
    </GridWrapper>
  );
};

export default Profile;
