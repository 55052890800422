import React, { useState } from "react";
import { useNavigate } from "react-router";
import {
  Card,
  CardContent,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Typography,
} from "@mui/material";
import "./RegisterMobileCard.css";
import { countries } from "./countries"; // Ensure this is correctly defined
import { registerUser } from "../../services/landing";
import { Link } from "react-router-dom";

export default function RegisterMobileCard() {
  const navigate = useNavigate();
  const [isFlipped, setIsFlipped] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    country: "",
    password: "",
    confirmPassword: "",
    terms: false,
    disclaimer: false,
    businessName: "",
    businessEmail: "",
    businessPhone: "",
    businessLocation: "",
    businessType: "",
  });

  const [passwordMatch, setPasswordMatch] = useState(true);
  const [regErrors, setRegErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleFlip = () => {
    setIsFlipped((prev) => !prev);
    setFormData((prev) => ({
      ...prev,
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      country: "",
      password: "",
      confirmPassword: "",
      businessName: "",
      businessEmail: "",
      businessPhone: "",
      businessLocation: "",
      businessType: "",
    }));
    setRegErrors({});
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const doSubmit = async () => {
    setLoading(true);
    try {
      const response = await registerUser(formData);
      localStorage.setItem("token", response.headers["x-auth-token"]);
      navigate("/account/home");
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        setRegErrors({ message: ex.response.data });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmPasswordBlur = () => {
    setPasswordMatch(formData.password === formData.confirmPassword);
  };

  const isIndividualFormValid = () => {
    return (
      formData.firstName &&
      formData.lastName &&
      formData.email &&
      formData.password &&
      passwordMatch &&
      formData.terms &&
      formData.disclaimer
    );
  };

  const isBusinessFormValid = () => {
    return (
      formData.businessName &&
      formData.businessEmail &&
      formData.businessPhone &&
      formData.businessLocation &&
      formData.businessType &&
      formData.password &&
      formData.terms &&
      formData.disclaimer
    );
  };

  return (
    <div className="register-mobile-card-container">
      <div
        className={`register-mobile-card ${
          isFlipped ? "register-mobile-flipped" : ""
        }`}
      >
        {/* Individual Registration Form */}
        <div className="register-mobile-card-face front-face">
          <Card>
            <CardContent>
              <Typography
                variant="h6"
                gutterBottom
                style={{ fontSize: "1rem" }}
              >
                Individual Registration (for personal use only)
              </Typography>
              {regErrors.message && (
                <p style={{ color: "red" }}>{regErrors.message}</p>
              )}

              <form noValidate autoComplete="off">
                <TextField
                  label="First Name"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                />
                <TextField
                  label="Last Name"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                />
                <TextField
                  label="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                />
                <TextField
                  label="Phone (optional)"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
                <FormControl fullWidth margin="normal">
                  <InputLabel>Country</InputLabel>
                  <Select
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                    required
                  >
                    {countries.map((country) => (
                      <MenuItem key={country.code} value={country.name}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  label="Password"
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                />
                <TextField
                  label="Confirm Password"
                  type="password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleConfirmPasswordBlur}
                  fullWidth
                  margin="normal"
                  error={!passwordMatch}
                  helperText={!passwordMatch && "Passwords do not match"}
                  required
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.terms}
                      onChange={handleChange}
                      name="terms"
                    />
                  }
                  label={
                    <Link to="/terms" style={{ textDecoration: "none" }}>
                      Agree to Terms and Conditions
                    </Link>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.disclaimer}
                      onChange={handleChange}
                      name="disclaimer"
                    />
                  }
                  label={
                    <Link to="/disclaimer" style={{ textDecoration: "none" }}>
                      Agree to Disclaimer
                    </Link>
                  }
                />
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!isIndividualFormValid() || loading}
                  fullWidth
                  onClick={doSubmit}
                >
                  {loading ? "Submitting..." : "Submit"}
                </Button>
              </form>
              <Button
                className="register-mobile-flip-button"
                onClick={handleFlip}
              >
                Switch to Business Registration
              </Button>
            </CardContent>
          </Card>
        </div>

        {/* Business Registration Form */}
        <div className="register-mobile-card-face back-face">
          <Card>
            <CardContent>
              <Typography
                variant="h6"
                gutterBottom
                style={{ fontSize: "1rem" }}
              >
                Business Registration (for corporate use only)
              </Typography>
              <form noValidate autoComplete="off">
                <TextField
                  label="Business Name"
                  name="businessName"
                  value={formData.businessName}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                />
                <TextField
                  label="Business Email"
                  name="businessEmail"
                  value={formData.businessEmail}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                />
                <TextField
                  label="Business Phone"
                  name="businessPhone"
                  value={formData.businessPhone}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                />
                <FormControl fullWidth margin="normal">
                  <InputLabel>Business Location</InputLabel>
                  <Select
                    name="businessLocation"
                    value={formData.businessLocation}
                    onChange={handleChange}
                    required
                  >
                    {countries.map((country) => (
                      <MenuItem key={country.code} value={country.name}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Business Type</InputLabel>
                  <Select
                    name="businessType"
                    value={formData.businessType}
                    onChange={handleChange}
                    required
                  >
                    <MenuItem value="Banking">Banking</MenuItem>
                    <MenuItem value="Tech">Tech</MenuItem>
                    <MenuItem value="Retail">Retail</MenuItem>
                    {/* Add more business types as needed */}
                  </Select>
                </FormControl>
                <TextField
                  label="Password"
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.terms}
                      onChange={handleChange}
                      name="terms"
                    />
                  }
                  label="Agree to Terms and Conditions"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.disclaimer}
                      onChange={handleChange}
                      name="disclaimer"
                    />
                  }
                  label="Agree to Disclaimer"
                />
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!isBusinessFormValid() || loading}
                  fullWidth
                  onClick={doSubmit}
                >
                  {loading ? "Submitting..." : "Submit"}
                </Button>
              </form>
              <Button
                className="register-mobile-flip-button"
                onClick={handleFlip}
              >
                Switch to Individual Registration
              </Button>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
}
