import {
  faFacebook,
  faLinkedin,
  faSquareInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { faCopyright } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextField } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import LandingFooterMobile from "./LandingFooterMobile";
import { toast } from "react-toastify";
import { addEmail } from "../../services/landing";

const LandingFooter = () => {
  const [formData, setFormData] = useState({
    email: "",
  });
  const [loading, setLoading] = useState(false); // New state for loading status
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });

  if (isMobile) {
    return <LandingFooterMobile />;
  }

  const handleChange = (event) => {
    const { name, value } = event.target;

    // Check if it's logging the correct value
    console.log("Field Name:", name);
    console.log("Field Value:", value);

    // Update the formData state
    setFormData((prev) => ({
      ...prev,
      [name]: value, // This will update the email field in formData
    }));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async () => {
    if (!validateEmail(formData.email)) {
      toast.error("Please enter a valid email address");
      return;
    }
    try {
      setLoading(true);
      await addEmail(formData);
      toast.success("Email added successfully");
      setFormData({ email: "" }); // Clear input after success
    } catch (ex) {
      toast.error("Something went wrong. Please try again.");
    } finally {
      setLoading(false); // Stop the loading spinner after request is done
    }
  };

  return (
    <div className="landingfooter">
      <div className="landingfooterdetails">
        <div className="footerinformation">
          <h5>Information</h5>

          <Link className="footerlinks" to={"/blog"}>
            <p>About</p>
          </Link>

          <Link className="footerlinks" to={"/disclaimer"}>
            <p>Disclaimer</p>
          </Link>
        </div>
        <div className="footerhelplinks">
          <h5>Help</h5>

          <a className="footerlinks" href="mailto:support@cypherforensics.com">
            <p>Customer Support</p>
          </a>

          <Link className="footerlinks" to={"/privacy"}>
            <p>Privacy Policy</p>
          </Link>
          <Link className="footerlinks" to={"/terms"}>
            {" "}
            <p>Terms & Conditions</p>
          </Link>
        </div>
        <div className="footernewsletter">
          <h5>Subscribe to our newsletter</h5>

          <p>
            Protect yourself by getting updates on the latest fraud practices
          </p>
          <div className="footerbtnbox">
            <div className="footeremail">
              <TextField
                style={{
                  backgroundColor: "white",
                  borderRight: 0,
                }}
                size="small"
                fullWidth
                label="Enter email address"
                name="email" // This is important for identifying the field
                value={formData.email}
                onChange={handleChange} // Ensure this points to the correct function
                aria-label="Enter your email"
              />
            </div>
            <div>
              <button
                onClick={handleSubmit}
                className="footerbtn"
                disabled={loading || !formData.email} // Disable during loading or if email is empty
                aria-busy={loading} // ARIA attribute for accessibility
              >
                {loading ? "Signing up..." : "Sign Up"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="landfootercc">
        <div className="landfooterccdetail">
          <FontAwesomeIcon icon={faCopyright} /> <span> </span>
          Copyright 2024. All Rights Reserved by Cypher Forensics
        </div>{" "}
        <div className="footersocials">
          follow us on
          <span className="marginlinks"></span>{" "}
          <FontAwesomeIcon icon={faLinkedin} />
          <span className="marginlinks"></span>
          <FontAwesomeIcon icon={faFacebook} />
          <span className="marginlinks"></span>
          <FontAwesomeIcon icon={faSquareInstagram} />
          <span className="marginlinks"></span>
          <FontAwesomeIcon icon={faWhatsapp} />
        </div>
      </div>
    </div>
  );
};

export default LandingFooter;
