import React, { useState, useEffect } from "react";
import "./inboxstyles.css";
import Grid from "@mui/material/Grid2";
import InboxHeader from "./InboxHeader";
import InboxCard from "./InboxCard";
import InboxWide from "./InboxWide";
import { patchInbox } from "../../../../services/inbox";

const MainInbox = ({ messages }) => {
  const [bodyContent, setBodyContent] = useState("");
  const [date, setDate] = useState("");
  const [subject, setSubject] = useState("");
  const [src, setSrc] = useState("");
  const [sender, setSender] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [messageStates, setMessageStates] = useState(messages);

  useEffect(() => {
    setMessageStates(messages);
  }, [messages]);

  const handlePatch = async (_id) => {
    try {
      await patchInbox(_id);
      setMessageStates((prevMessages) =>
        prevMessages.map((message) =>
          message._id === _id ? { ...message, new: false } : message
        )
      );
    } catch (ex) {
      console.log(ex);
      // Consider notifying the user of the error
    }
  };

  const handleClick = (_id) => {
    const selectedMessage = messageStates.find(
      (message) => message._id === _id
    );
    if (selectedMessage) {
      setBodyContent(selectedMessage.body);
      setDate(selectedMessage.date);
      setSubject(selectedMessage.subject);
      setSrc(selectedMessage.src);
      setSender(selectedMessage.sender);
      setActiveTab(selectedMessage._id);
      handlePatch(_id);
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid size={12} className="headerbox">
        <InboxHeader
          src={src}
          subject={subject}
          sender={sender}
          messages={messages}
        />
      </Grid>
      <Grid size={4} style={{ paddingRight: "1rem" }}>
        {messageStates.map((message) => (
          <InboxCard
            key={message._id}
            sender={message.sender}
            subject={message.subject}
            src={message.src}
            isNew={message.new}
            date={message.date}
            isActive={activeTab === message._id}
            onClick={() => handleClick(message._id)}
          />
        ))}
      </Grid>
      <Grid
        size={7}
        style={{
          minHeight: "100%",
          boxShadow: "0.3rem 0 0 rgba(0, 0 , 0, 0.25)",
        }}
      >
        <InboxWide body={bodyContent} date={date} />
      </Grid>
    </Grid>
  );
};

export default MainInbox;
