import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { cardStyles } from "../BasicCard/styles";

const BasicCard = ({ header, content, results }) => {
  return (
    <Card sx={cardStyles}>
      {header}
      <div className="searchresults">{results}</div>
      <CardContent>{content}</CardContent>
    </Card>
  );
};

export default BasicCard;
