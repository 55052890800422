import React from "react";
import "./loader.css";

const Loader3 = () => {
  return (
    <div className="loaderpage">
      <div className="item">
        <i className="loader --5"></i>
      </div>
    </div>
  );
};

export default Loader3;
