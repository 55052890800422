import React from "react";
import GridWrapper from "../../../common/GridWrapper/GridWrapper";
import HelpDeskMain from "./HelpDeskMainComponents/HelpDeskMain";
import GridWrapper2 from "../../../common/GridWrapper/GridWrapper2";
import { useMediaQuery } from "react-responsive";

const HelpDesk = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  if (isMobile) {
    return (
      <GridWrapper2>
        <HelpDeskMain />
      </GridWrapper2>
    );
  }
  return (
    <GridWrapper>
      <HelpDeskMain />
    </GridWrapper>
  );
};

export default HelpDesk;
