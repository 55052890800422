import React from "react";
import "./landingstyles.css";
import LandingHeader from "./LandingComponents/LandingHeader";
import LandingProductCard from "./LandingComponents/LandingProductCard";
import LandingMain from "./LandingComponents/LandingMain";
import LandingFooter from "./LandingComponents/LandingFooter";
import LandingHeaderMobile from "./LandingComponents/LandingHeaderMobile";

const LandingPage = () => {
  return (
    <div className="landingpagewrapper">
      <div className="mainlandingpage">
        <LandingHeader />
        <LandingProductCard />
      </div>
      <div className="sectionA">
        <LandingMain />
      </div>
      <LandingFooter />
    </div>
  );
};

export default LandingPage;
