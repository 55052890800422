import React from "react";
import "./inboxstyles.css";
import { Typography } from "@mui/material";

const InboxWide = ({ date, body }) => {
  function formatMessageDate(dateString) {
    const now = new Date();
    const messageDate = new Date(dateString);
    const elapsedTime = now - messageDate;

    const seconds = Math.floor(elapsedTime / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);

    if (seconds < 60) {
      return `${seconds} second${seconds !== 1 ? "s" : ""} ago`;
    } else if (minutes < 60) {
      return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
    } else if (hours < 24) {
      return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
    } else if (days < 7) {
      return `${days} day${days !== 1 ? "s" : ""} ago`;
    } else if (weeks < 5) {
      return `${weeks} week${weeks !== 1 ? "s" : ""} ago`;
    } else {
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      return messageDate.toLocaleDateString(undefined, options);
    }
  }

  console.log("desktop", body);

  return (
    <div className="wideinboxbox">
      <div className="wideinboxdate">{date ? formatMessageDate(date) : ""}</div>
      <div className="wideinboxbody">
        <Typography variant="body1" component="div">
          <div dangerouslySetInnerHTML={{ __html: body }} />
        </Typography>
      </div>
    </div>
  );
};

export default InboxWide;
