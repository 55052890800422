import React from "react";
import "./styles.css";
import ScamInfoCard from "./ScamInfoCard";
import Grid from "@mui/material/Grid2";
import ScamInfoSide from "./ScamInfoSide";
import { useMediaQuery } from "react-responsive";

const ScamInfo = ({ scamupdates, articles }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  return (
    <Grid container>
      <Grid size={isMobile ? 12 : 8}>
        <div className="scaminfo">
          <header>Scam updates and alerts</header>
          {scamupdates &&
            scamupdates.map((u) => (
              <ScamInfoCard
                key={u._id}
                subject={u.subject}
                preview={u.preview}
                link={`/account/scaminfo/${u._id}`}
              />
            ))}
        </div>
      </Grid>
      <Grid size={4}>
        <div className="scaminfoside">
          <header>Popular Updates</header>

          {articles &&
            articles.map((u) => (
              <ScamInfoSide
                key={u._id}
                sidelinks={u.link}
                sidelinkstitle={u.info}
              />
            ))}
        </div>
      </Grid>
    </Grid>
  );
};

export default ScamInfo;
