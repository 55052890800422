import { Typography } from "@mui/material";
import React from "react";
import LandingFooter from "./LandingFooter";

const Privacy = () => {
  return (
    <>
      <div className="privacypolicy">
        <div className="privacybox">
          <Typography>
            {" "}
            <h3>Privacy Policy for Cypher</h3>
            <p>
              <strong>Effective Date:</strong> 12/2023
            </p>
            <p>
              At Cypher, we prioritize the privacy and security of our users.
              This Privacy Policy outlines how we collect, use, protect, and
              disclose information about you when you interact with our
              services. By using our platform, you agree to the terms outlined
              in this policy.
            </p>
            <h4>1. Information We Collect</h4>
            <p>We may collect the following types of information:</p>
            <ul>
              <li>
                <strong>Personal Information:</strong> This includes your name,
                email address, phone number, and any other information you
                provide when you register for our services, submit inquiries, or
                engage with us.
              </li>
              <li>
                <strong>Case Details:</strong> Any information related to
                specific cases or incidents that you share with us as part of
                our fraud prevention services is considered strictly
                confidential.
              </li>
              <li>
                <strong>Usage Data:</strong> We may collect information about
                how you access and use our platform, including your IP address,
                browser type, pages visited, and the time and date of your
                visits. This information helps us improve our services and user
                experience.
              </li>
            </ul>
            <h4>2. How We Use Your Information</h4>
            <p>
              We use the information we collect for various purposes, including:
            </p>
            <ul>
              <li>To provide, maintain, and improve our services.</li>
              <li>
                To communicate with you regarding your account, services, or
                inquiries.
              </li>
              <li>
                To monitor and analyze usage patterns to enhance our platform.
              </li>
              <li>To ensure the security and integrity of our services.</li>
            </ul>
            <h4>3. Confidentiality and Security</h4>
            <p>
              At Cypher, we are committed to safeguarding the confidentiality of
              your information. We do not sell, rent, or lease any personal
              information to marketers or third parties. Your email address and
              details of any case you share with us will remain strictly
              confidential and will only be used for the purposes outlined in
              this policy.
            </p>
            <p>
              We implement appropriate technical and organizational measures to
              protect your personal information from unauthorized access, loss,
              or misuse. However, no method of transmission over the internet or
              electronic storage is 100% secure, and we cannot guarantee
              absolute security.
            </p>
            <h4>4. Information Sharing and Disclosure</h4>
            <p>
              We do not share your personal information with third parties,
              except in the following circumstances:
            </p>
            <ul>
              <li>
                <strong>With Your Consent:</strong> We may share your
                information with third parties if you provide explicit consent
                for us to do so.
              </li>
              <li>
                <strong>Legal Obligations:</strong> We may disclose your
                information if required to do so by law or in response to valid
                requests by public authorities (e.g., a court or government
                agency).
              </li>
              <li>
                <strong>Business Transfers:</strong> In the event of a merger,
                acquisition, or sale of all or a portion of our assets, your
                information may be transferred as part of that business
                transaction. We will notify you before your personal information
                is transferred and becomes subject to a different privacy
                policy.
              </li>
            </ul>
            <h4>5. Your Rights</h4>
            <p>
              You have the following rights regarding your personal information:
            </p>
            <ul>
              <li>
                <strong>Access and Update:</strong> You have the right to access
                and update your personal information at any time. You can do
                this by contacting us directly.
              </li>
              <li>
                <strong>Withdrawal of Consent:</strong> If you have previously
                provided consent for us to process your information, you have
                the right to withdraw that consent at any time.
              </li>
              <li>
                <strong>Data Deletion:</strong> You may request that we delete
                your personal information, subject to any legal obligations we
                may have to retain it.
              </li>
            </ul>
            <h4>6. Third-Party Services</h4>
            <p>
              Our website may contain links to third-party websites or services
              that are not operated by us. This Privacy Policy does not apply to
              those third-party services. We encourage you to review the privacy
              policies of any third-party sites you visit.
            </p>
            <h4>7. Changes to This Privacy Policy</h4>
            <p>
              We may update this Privacy Policy from time to time to reflect
              changes in our practices or for other operational, legal, or
              regulatory reasons. We will notify you of any material changes by
              posting the new policy on our website with a revised effective
              date. We encourage you to review this policy periodically for any
              updates.
            </p>
            <h4>8. Contact Us</h4>
            <p>
              If you have any questions, concerns, or requests regarding this
              Privacy Policy or our practices, please contact us at:
            </p>
            <p>
              <strong>Cypher Forensics</strong> <br />
              <br />
              info@cypherforensics.com <br /> support@cypherforensics.com
              <br />
              +41 43 584 19 05
            </p>
            <p>
              By using our services, you acknowledge that you have read and
              understood this Privacy Policy and agree to its terms.
            </p>
          </Typography>
        </div>
      </div>
      <LandingFooter />
    </>
  );
};

export default Privacy;
