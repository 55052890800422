import React from "react";
import "./styles.css";
import CaseHistory from "./CaseHistory";
import PaymentHistory from "./PaymentHistory";

const HistoryMain = ({ caselist }) => {
  return (
    <div className="historymain">
      <CaseHistory caselist={caselist} />
      <PaymentHistory caselist={caselist} />
    </div>
  );
};

export default HistoryMain;
