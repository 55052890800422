import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import "../CasesComponent/styles.css";
import { useEffect } from "react";
import { getCases } from "../../../../services/cases";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBitcoin } from "@fortawesome/free-brands-svg-icons";
import {
  faHeartCrack,
  faBuildingColumns,
  faCreditCard,
  faMoneyBillTransfer,
} from "@fortawesome/free-solid-svg-icons";
import Loader3 from "../../Loaders/Loader3";

const getFraudType = (fraudtype) => {
  if (!fraudtype) return null;
  switch (fraudtype) {
    case "Cryptocurrency Fraud":
      return (
        <div>
          <FontAwesomeIcon color={"orange"} icon={faBitcoin} /> Cryptocurrency
          Fraud
        </div>
      );
    case "Bank Fraud":
      return (
        <div>
          <FontAwesomeIcon icon={faBuildingColumns} color={"grey"} />{" "}
          Banking/Wire Fraud
        </div>
      );

    case "Romance Fraud":
      return (
        <div>
          <FontAwesomeIcon icon={faHeartCrack} color={"red"} /> Romance Fraud
        </div>
      );
    case "Credit Fraud":
      return (
        <div>
          <FontAwesomeIcon icon={faCreditCard} color={"gold"} /> Credit Card
          Fraud
        </div>
      );
    case "Investment Fraud":
      return (
        <div>
          <FontAwesomeIcon icon={faMoneyBillTransfer} color={"green"} />{" "}
          Investment/Forex Fraud
        </div>
      );
    default:
      return null;
  }
};

const CaseList = () => {
  const [caselist, setCaselist] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchCases = async () => {
      try {
        const response = await getCases();
        const newdata = response.data;
        setCaselist(newdata);
      } catch (err) {
        setError("Failed to fetch cases.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCases(); // Call the async function
  }, []);

  if (loading) return <Loader3 />;

  const calculateDays = (date) => {
    const givenDate = new Date(date.split("/").reverse().join("-"));
    const today = new Date();
    const timeDifference = today - givenDate;
    return Math.floor(timeDifference / (1000 * 3600 * 24));
  };

  const format = (value) => {
    if (value <= 0.2) {
      return { color: "darkred" }; // Dark red
    } else if (value >= 0.3 && value < 0.45) {
      return { color: "red" }; // Red
    } else if (value === 0.5) {
      return {}; // Unchanged
    } else if (value >= 0.6 && value < 0.8) {
      return { color: "darkgreen" }; // Dark green
    } else if (value >= 0.8 && value <= 1) {
      return { color: "limegreen" }; // Bright green
    }
    return {}; // Default case (optional)
  };

  return (
    <TableContainer component={Paper} className="case-table-container">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>
              P<sub>k</sub>
            </TableCell>
            <TableCell>
              Value <br /> <span style={{ fontSize: "0.5rem" }}>(in USD)</span>
            </TableCell>
            <TableCell>Duration</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {caselist.map((caseItem) => (
            <TableRow key={caseItem._id} className="case-row">
              <TableCell className="case-name">{caseItem.name}</TableCell>
              <TableCell className="case-type">
                {getFraudType(caseItem.fraudtype)}
              </TableCell>
              <TableCell
                className={`case-status ${
                  caseItem.status?.toLowerCase() || ""
                }`}
              >
                {caseItem.status}
              </TableCell>
              <TableCell
                className="case-target"
                style={format(caseItem.pk.value)}
              >
                {caseItem.pk.value || "NA"}
              </TableCell>
              <TableCell>{caseItem.value}</TableCell>
              <TableCell style={{ color: "red" }}>
                {calculateDays(caseItem.datestarted)} days
              </TableCell>
              <TableCell>
                <Link
                  to={`/account/cases/${caseItem._id}`}
                  className="expandbutton"
                >
                  Expand
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CaseList;
