import { Route, Routes } from "react-router-dom";
import Home from "./components/dashcomponents/pages/Home/Home";
import Inbox from "./components/dashcomponents/pages/Inbox/Inbox";
import Cases from "./components/dashcomponents/pages/Cases/Cases";
import Status from "./components/dashcomponents/pages/Status/Status";
import ScamInfo from "./components/dashcomponents/pages/ScamInfo/ScamInfo";
import "./App.css";
import CaseData from "./components/dashcomponents/pages/Cases/CasesComponent/CaseData";
import ScamInfoData from "./components/dashcomponents/pages/ScamInfo/ScamInfoComponents/ScamInfoData";
import Analytics from "./components/dashcomponents/pages/Analytics/Analytics";
import FraudTips from "./components/dashcomponents/pages/FraudTips/FraudTips";
import FraudTipsData from "./components/dashcomponents/pages/FraudTips/FraudTipsComponents/FraudTipsData";
import CypherTools from "./components/dashcomponents/pages/CypherTools/CypherTools";
import History from "./components/dashcomponents/pages/History/HIstory";
import AddCase from "./components/dashcomponents/pages/Modals/AddCase";
import EditCase from "./components/dashcomponents/pages/Modals/EditCase";
import HelpDesk from "./components/dashcomponents/pages/HelpDesk/HelpDesk";
import HelpNewCases from "./components/dashcomponents/pages/HelpDesk/HelpDeskMainComponents/HelpDeskPages/HelpNewcases";
import HelpEditcases from "./components/dashcomponents/pages/HelpDesk/HelpDeskMainComponents/HelpDeskPages/HelpEditcases";
import HelpStatus from "./components/dashcomponents/pages/HelpDesk/HelpDeskMainComponents/HelpDeskPages/HelpStatus";
import HelpAnalytics from "./components/dashcomponents/pages/HelpDesk/HelpDeskMainComponents/HelpDeskPages/HelpAnalytics";
import HelpPk from "./components/dashcomponents/pages/HelpDesk/HelpDeskMainComponents/HelpDeskPages/HelpPk";
import HelpConf from "./components/dashcomponents/pages/HelpDesk/HelpDeskMainComponents/HelpDeskPages/HelpConf";
import HelpBA from "./components/dashcomponents/pages/HelpDesk/HelpDeskMainComponents/HelpDeskPages/HelpBA";
import InboxMobileData from "./components/dashcomponents/pages/Inbox/InboxComponents/InboxMobileData";
import LandingPage from "./components/landingComponents/LandingPage";
import Login from "./components/landingComponents/LandingComponents/Login";
import Register from "./components/landingComponents/LandingComponents/Register";
import Blog from "./components/landingComponents/Blog";
import API from "./components/landingComponents/LandingComponents/API";
import RAF from "./components/landingComponents/LandingComponents/RAF";
import NotFound from "./components/dashcomponents/pages/NotFound";
import DashBoard from "./components/dashcomponents/DashBoard";
import Profile from "./components/dashcomponents/pages/Modals/Profile";
import Admin from "./components/dashcomponents/pages/Admin/Admin";
import AdminCases from "./components/dashcomponents/pages/Admin/AdminCases";
import AdminInbox from "./components/dashcomponents/pages/Admin/AdminInbox";
import Master from "./components/dashcomponents/pages/Master/Master";
import EditUser from "./components/dashcomponents/pages/Master/EditUser";
import News from "./components/dashcomponents/pages/Admin/News";
import { getUserMe } from "./components/services/user";
import { useEffect, useState } from "react";
import ProtectedRoute from "./ProtectedRoute"; // Import your ProtectedRoute component
import Requests from "./components/dashcomponents/pages/Requests";
import PublicRoute from "./PublicRoute";
import NewsList from "./components/dashcomponents/pages/Admin/NewsList";
import Disclaimer from "./components/landingComponents/LandingComponents/Disclaimer";
import Privacy from "./components/landingComponents/LandingComponents/Privacy";
import Terms from "./components/landingComponents/LandingComponents/Terms";
import Newslettersm from "./components/dashcomponents/pages/Master/Newslettersm";
import ResetPassword from "./components/landingComponents/ResetPassword";
import Password from "./components/dashcomponents/pages/Modals/Password";
import AuthEmail from "./components/landingComponents/AuthEmail";
import ErrorLogs from "./components/dashcomponents/pages/Master/ErrorLogs";

function App() {
  const [user, setUser] = useState({});

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await getUserMe();
        const newdata = response.data;
        setUser(newdata);
      } catch (err) {
        // Handle error if needed
      }
    };

    fetchUser();
  }, []);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <PublicRoute>
            <LandingPage />
          </PublicRoute>
        }
      />
      <Route
        path="login"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
      <Route
        path="register"
        element={
          <PublicRoute>
            <Register />
          </PublicRoute>
        }
      />
      <Route
        path="blog"
        element={
          <PublicRoute>
            <Blog />
          </PublicRoute>
        }
      />
      <Route
        path="reset-password"
        element={
          <PublicRoute>
            <ResetPassword />
          </PublicRoute>
        }
      />
      <Route
        path="auth-email"
        element={
          <PublicRoute>
            <AuthEmail />
          </PublicRoute>
        }
      />
      <Route
        path="api"
        element={
          <PublicRoute>
            <API />
          </PublicRoute>
        }
      />
      <Route
        path="report-a-fraud"
        element={
          <PublicRoute>
            <RAF />
          </PublicRoute>
        }
      />
      <Route
        path="disclaimer"
        element={
          <PublicRoute>
            <Disclaimer />
          </PublicRoute>
        }
      />
      <Route
        path="privacy"
        element={
          <PublicRoute>
            <Privacy />
          </PublicRoute>
        }
      />
      <Route
        path="terms"
        element={
          <PublicRoute>
            <Terms />
          </PublicRoute>
        }
      />

      {/* Protecting account routes */}
      <Route path="/account" element={<DashBoard />}>
        <Route
          path="home"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path="inbox"
          element={
            <ProtectedRoute>
              <Inbox />
            </ProtectedRoute>
          }
        />
        <Route
          path="cases"
          element={
            <ProtectedRoute>
              <Cases />
            </ProtectedRoute>
          }
        />
        <Route
          path="status"
          element={
            <ProtectedRoute>
              <Status />
            </ProtectedRoute>
          }
        />
        <Route
          path="scaminfo"
          element={
            <ProtectedRoute>
              <ScamInfo />
            </ProtectedRoute>
          }
        />
        <Route
          path="help-desk"
          element={
            <ProtectedRoute>
              <HelpDesk />
            </ProtectedRoute>
          }
        />
        <Route
          path="cases/:id"
          element={
            <ProtectedRoute>
              <CaseData />
            </ProtectedRoute>
          }
        />
        <Route
          path="scaminfo/:id"
          element={
            <ProtectedRoute>
              <ScamInfoData />
            </ProtectedRoute>
          }
        />
        <Route
          path="analytics"
          element={
            <ProtectedRoute>
              <Analytics />
            </ProtectedRoute>
          }
        />
        <Route
          path="fraudtips"
          element={
            <ProtectedRoute>
              <FraudTips />
            </ProtectedRoute>
          }
        />
        <Route
          path="fraudtips/:id"
          element={
            <ProtectedRoute>
              <FraudTipsData />
            </ProtectedRoute>
          }
        />
        <Route
          path="cyphertools"
          element={
            <ProtectedRoute>
              <CypherTools />
            </ProtectedRoute>
          }
        />
        <Route
          path="history"
          element={
            <ProtectedRoute>
              <History />
            </ProtectedRoute>
          }
        />
        <Route
          path="add-case"
          element={
            <ProtectedRoute>
              <AddCase />
            </ProtectedRoute>
          }
        />
        <Route
          path="edit-case/:id"
          element={
            <ProtectedRoute>
              <EditCase />
            </ProtectedRoute>
          }
        />
        <Route
          path="help-desk/newcase"
          element={
            <ProtectedRoute>
              <HelpNewCases />
            </ProtectedRoute>
          }
        />
        <Route
          path="help-desk/editcase"
          element={
            <ProtectedRoute>
              <HelpEditcases />
            </ProtectedRoute>
          }
        />
        <Route
          path="help-desk/help-status"
          element={
            <ProtectedRoute>
              <HelpStatus />
            </ProtectedRoute>
          }
        />
        <Route
          path="inbox/:id"
          element={
            <ProtectedRoute>
              <InboxMobileData />
            </ProtectedRoute>
          }
        />
        <Route
          path="help-desk/help-analytics"
          element={
            <ProtectedRoute>
              <HelpAnalytics />
            </ProtectedRoute>
          }
        />
        <Route
          path="help-desk/help-outcome"
          element={
            <ProtectedRoute>
              <HelpPk />
            </ProtectedRoute>
          }
        />
        <Route
          path="help-desk/help-boosters-antagonists"
          element={
            <ProtectedRoute>
              <HelpBA />
            </ProtectedRoute>
          }
        />
        <Route
          path="help-desk/help-secure"
          element={
            <ProtectedRoute>
              <HelpConf />
            </ProtectedRoute>
          }
        />
        <Route
          path="profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path="change-password"
          element={
            <ProtectedRoute>
              <Password />
            </ProtectedRoute>
          }
        />
        <Route
          path="requests"
          element={
            <ProtectedRoute>
              <Requests />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route path="admin" element={<Admin />} />
      <Route path="admin/cases/:id" element={<AdminCases />} />
      <Route path="admin/inbox/:id" element={<AdminInbox />} />
      <Route path="master" element={<Master />} />
      <Route path="master/edituser/:id" element={<EditUser />} />
      <Route path="master/errorlogs/:id" element={<ErrorLogs />} />
      <Route path="admin/news" element={<News />} />
      <Route path="admin/news/list" element={<NewsList />} />
      <Route path="newsletter" element={<Newslettersm />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
