import React, { useEffect, useState } from "react";
import { getUsers } from "../../../services/user";
import { useNavigate } from "react-router";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  Typography,
} from "@mui/material";
import { deleteUser } from "../../../services/master";

const Master = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await getUsers();
        setUsers(response.data);
      } catch (err) {
        setError("Failed to fetch users.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const navigate = useNavigate();

  const handleDelete = async (id) => {
    try {
      setDeleting(true);
      await deleteUser(id);
      setUsers((prevUsers) => prevUsers.filter((user) => user._id !== id));
    } catch (ex) {
      console.error("Error deleting user:", ex);
      setError("Failed to delete user.");
    } finally {
      setDeleting(false);
    }
  };

  // Handle sorting logic
  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedUsers = [...users].sort((a, b) => {
    if (sortConfig.key) {
      const direction = sortConfig.direction === "asc" ? 1 : -1;

      // Sorting by date for the 'logged' field
      if (sortConfig.key === "logged") {
        const dateA = new Date(a.logged);
        const dateB = new Date(b.logged);
        if (dateA < dateB) return -1 * direction;
        if (dateA > dateB) return 1 * direction;
        return 0;
      } else {
        // Default sorting for other fields (string comparison)
        if (a[sortConfig.key] < b[sortConfig.key]) return -1 * direction;
        if (a[sortConfig.key] > b[sortConfig.key]) return 1 * direction;
        return 0;
      }
    }
    return 0;
  });

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  // Time elapsed from date function
  function timeElapsedFromDate(isoDate) {
    const now = new Date();
    const date = new Date(isoDate);
    const elapsedMilliseconds = now - date;
    const seconds = Math.floor(elapsedMilliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return days === 1 ? "1 day ago" : `${days} days ago`;
    } else if (hours > 0) {
      return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
    } else if (minutes > 0) {
      return minutes === 1 ? "1 minute ago" : `${minutes} minutes ago`;
    } else {
      return seconds === 1 ? "1 second ago" : `${seconds} seconds ago`;
    }
  }

  return (
    <div className="master">
      <div className="usercard">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell onClick={() => handleSort("firstName")}>
                First Name
              </TableCell>
              <TableCell onClick={() => handleSort("lastName")}>
                Last Name
              </TableCell>
              <TableCell onClick={() => handleSort("email")}>Email</TableCell>
              <TableCell onClick={() => handleSort("country")}>
                Country
              </TableCell>
              <TableCell onClick={() => handleSort("phone")}>Phone</TableCell>
              <TableCell onClick={() => handleSort("ipAddress")}>
                IP Address
              </TableCell>
              <TableCell onClick={() => handleSort("logged")}>
                Time Logged
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell> <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedUsers.map((u) => (
              <TableRow key={u._id}>
                <TableCell>{u.firstName}</TableCell>
                <TableCell>{u.lastName}</TableCell>
                <TableCell>{u.email}</TableCell>
                <TableCell>{u.country}</TableCell>
                <TableCell>{u.phone}</TableCell>
                <TableCell>{u.ipAddress}</TableCell>
                <TableCell>{timeElapsedFromDate(u.logged)}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleDelete(u._id)}
                    disabled={deleting}
                  >
                    {deleting ? "Deleting..." : "Delete"}
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    onClick={() => navigate(`/master/edituser/${u._id}`)}
                  >
                    Edit
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    onClick={() => navigate(`/master/errorlogs/${u._id}`)}
                  >
                    Error
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      <Button
        variant="contained"
        style={{ margin: "2rem" }}
        onClick={() => navigate("/newsletter")}
      >
        Newsletters
      </Button>
    </div>
  );
};

export default Master;
