import React from "react";
import "./styles.css";
import {
  faFaceFrown,
  faFaceSmile,
  faFaceMeh,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuildingColumns,
  faFileInvoiceDollar,
  faMoneyCheckDollar,
} from "@fortawesome/free-solid-svg-icons";

const StatusPayment = ({ active, assetworth, bill, stage }) => {
  const getStatus = (status) => {
    let icon, color;

    if (status === "Active") {
      icon = faFaceSmile;
      color = "green";
    } else if (status === "Pending") {
      icon = faFaceMeh; // Add the neutral face icon
      color = "black"; // You can choose a color for the "Pending" state
    } else {
      icon = faFaceFrown;
      color = "red";
    }

    return (
      <div>
        <div className="smileicon">
          <FontAwesomeIcon icon={icon} color={color} />
        </div>
      </div>
    );
  };

  function formatAmount(amount) {
    if (isNaN(amount)) {
      return "Invalid amount";
    }

    return `$${amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  }

  return (
    <div className="statuspayment">
      <header>Payment Status</header>
      <div className="statuspaymentbox">
        <div className="statusbox">
          <div>
            <div className="statusicon">{getStatus(active)}</div>
            <h5>{active || "not available"}</h5>
          </div>
        </div>
        <div className="statusboxtype">
          <h5>Status Type</h5>
          <div className="statusboxtypestyle">{stage}</div>
        </div>
      </div>
      <header>Payment Details</header>
      <div className="statuspaymentdetails">
        <div className="statusassetworth">
          <article>
            <FontAwesomeIcon color="rgba(0,130,0,1" icon={faBuildingColumns} />{" "}
            <span className="margin1"></span>
            Total Asset Worth<span> (in USD)</span>
          </article>
          <p>
            {assetworth > 0
              ? `USD ${formatAmount(assetworth)}`
              : "not available"}{" "}
          </p>
        </div>
        <div className="statusassetbill">
          <article>
            <FontAwesomeIcon color="red" icon={faFileInvoiceDollar} />{" "}
            <span className="margin1"></span>
            Cypher Forensics Bill<span> (in USD)</span>
          </article>
          <p> {bill > 0 ? `USD ${formatAmount(bill)}` : "not available"}</p>
        </div>
      </div>
    </div>
  );
};

export default StatusPayment;
