import React, { Fragment } from "react";
import BasicCard from "../../../common/BasicCard/BasicCard";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import GridWrapper from "../../../common/GridWrapper/GridWrapper";
import { cardHeaderStyles } from "./styles";
import SearchBar from "../../../common/SearchBar/SearchBar";
import CaseList from "../Cases/CasesComponent/CaseList";
import { caselist } from "../../consts/caselist";
import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import HomeMobile from "./HomeMobile";
import { useEffect, useState } from "react";
import { getCases } from "../../../services/cases";
import ProductHome from "./ProductsHome";
import { getProducts } from "../../../services/products";
import { loader1 } from "../Loaders/loader";
import Loader1 from "../Loaders/Loader1";
import styled from "styled-components";
import { toast } from "react-toastify";

const CaseItem = styled.div`
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #e2e2e2;
  color: rgba(0, 0, 0, 0.8);
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
`;
const CaseListContainer = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  background: #f9f9f9;
  border-radius: 5px;
  max-height: 5rem;
  min-width: 100%;
  overflow-y: auto; // Scroll if the content exceeds the max height
`;

function Home() {
  const [caselist, setCaselist] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCases = async () => {
      try {
        const response = await getCases();
        setCaselist(response.data);
      } catch (err) {
        setError("Failed to fetch cases.");
        console.error(err);
      }
    };

    fetchCases(); // Call the async function
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await getProducts();
        setProducts(response.data);
      } catch (err) {
        setError("Failed to fetch cases.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts(); // Call the async function
  }, []);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  if (loading) {
    return <Loader1 />;
  }
  const getHeader = () => {
    const handleChange = (value) => {
      setSearchQuery(value);
    };

    return (
      <Box sx={cardHeaderStyles.wrapper}>
        <SearchBar
          placeholder="Search by email address, phone number, or user UID"
          onChange={(event) => handleChange(event.target.value)}
          searchBarWidth="720px"
          color={"rgba(0, 0, 0, 0.6)"}
        />

        <Box>
          <Button
            variant="contained"
            component={Link}
            to="/account/add-case"
            size="large"
            sx={cardHeaderStyles.addUserButton}
          >
            Add
          </Button>
          <IconButton>
            <RefreshIcon />
          </IconButton>
        </Box>
      </Box>
    );
  };

  const filteredCases = caselist.filter((caseName) =>
    caseName.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const searchResults = () => {
    return (
      searchQuery && (
        <CaseListContainer>
          {filteredCases.map((caseName, index) => (
            <CaseItem
              onClick={() => navigate(`/account/cases/${caseName._id}`)}
              key={index}
            >
              {caseName.name}
            </CaseItem>
          ))}
          {filteredCases.length === 0 && <CaseItem>No cases found</CaseItem>}
        </CaseListContainer>
      )
    );
  };

  const getContent = () => (
    <Typography
      align="center"
      sx={{
        margin: "40px 16px",
        color: "rgba(0, 0, 0, 0.6)",
        fontSize: "1.3rem",
      }}
    >
      No active cases
    </Typography>
  );

  const getcases = () => {
    if (caselist.length) return <CaseList />;
    else return getContent();
  };

  if (isMobile) {
    return <HomeMobile caselist={caselist} products={products} />;
  }

  const productClick = () => {
    toast.info("API endpoints are for commercial use only");
    navigate("/account/home");
  };

  return (
    <Fragment>
      <GridWrapper>
        <BasicCard
          header={getHeader()}
          results={searchResults()}
          content={getcases()}
        />

        {products &&
          products.map((p) => (
            <ProductHome products={p} productClick={productClick} />
          ))}
      </GridWrapper>
    </Fragment>
  );
}

export default Home;
