import React from "react";
import "./styles.css";

const ScamInfoSide = ({ sidelinks, sidelinkstitle }) => {
  return (
    <div className="scaminfosidelinks">
      <a
        className="scamupdateslink"
        href={sidelinks}
        target="_blank"
        rel="noopener noreferrer"
      >
        {sidelinkstitle}
      </a>
    </div>
  );
};

export default ScamInfoSide;
