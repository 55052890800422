import HomeIcon from "@mui/icons-material/Home";
import MailIcon from "@mui/icons-material/Mail";
import WorkIcon from "@mui/icons-material/Work";
import InsightsIcon from "@mui/icons-material/Insights";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import InfoIcon from "@mui/icons-material/Info";
import Badge from "@mui/material/Badge";

export const mainNavBarItems = [
  {
    id: 0,
    icon: <HomeIcon />,
    label: "Home",
    route: "home",
  },
  {
    id: 1,
    icon: (
      <Badge badgeContent={0} color="primary">
        <MailIcon />
      </Badge>
    ),
    label: "Inbox",
    route: "inbox",
  },
  {
    id: 2,
    icon: <WorkIcon />,
    label: "Cases",
    route: "cases",
  },
  {
    id: 3,
    icon: <InsightsIcon />,
    label: "Status",
    route: "status",
  },
  {
    id: 4,
    icon: <AnalyticsIcon />,
    label: "Analytics",
    route: "analytics",
  },
  {
    id: 5,
    icon: <InfoIcon />,
    label: "Scam Info",
    route: "scaminfo",
  },
];
