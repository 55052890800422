import React from "react";

const Loader2 = () => {
  return (
    <div className="loaderpage">
      <div class="item">
        <i class="loader --7"></i>
      </div>
    </div>
  );
};

export default Loader2;
