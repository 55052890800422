import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getInboxes } from "../../../services/admin";
import {
  Button,
  FormControl,
  InputLabel,
  NativeSelect,
  TextField,
} from "@mui/material";
import axios from "axios";
import { sendMessage } from "../../../services/admin";

const AdminInbox = () => {
  const [inbox, setInbox] = useState([]);
  const { id } = useParams(); // Assuming 'id' is the user ID
  const [loading, setLoading] = useState(true);
  const [toggle, setToggle] = useState(true);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    sender: "",
    src: "",
    subject: "",
    message: "",
  });

  useEffect(() => {
    const fetchInboxData = async () => {
      try {
        const response = await getInboxes(id);
        setInbox(response.data);
      } catch (error) {
        console.error("Error fetching inbox data:", error);
        setError("Failed to load Inbox. Please try again.");
      } finally {
        setLoading(false);
      }
    };
    fetchInboxData();
  }, [id]);

  const users = [
    { id: 0, name: "John Doe", src: "JD" },
    { id: 1, name: "Adam Smith", src: "SM" },
    { id: 2, name: "Peter Drew", src: "PD" },
    { id: 3, name: "Jane Doe", src: "JD" },
    { id: 4, name: "Ami Tham", src: "AT" },
  ];

  const togglehandler = () => {
    if (toggle) {
      setToggle(false);
    } else setToggle(true);
  };

  // Handle input changes for the form
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = async () => {
    const { sender, src, subject, body } = formData;

    if (!sender || !src || !subject || !body) {
      alert("Please fill in all fields before submitting.");
      return;
    }

    try {
      await sendMessage(formData, id);

      alert("Message sent successfully!");
    } catch (error) {
      console.error("Error sending message:", error);
      alert("Failed to send message. Please try again.");
    }
  };

  return (
    <div className="admininbox" style={{ paddingTop: "1rem" }}>
      <Button variant="contained" onClick={togglehandler}>
        {" "}
        {toggle ? "Hide messages" : "Show messages"}
      </Button>
      {loading && <div>Loading...</div>}
      {error && <div>{error}</div>}

      {toggle &&
        inbox &&
        inbox.map((i) => (
          <div key={i._id} className="admininboxcard">
            <header className="adminsubject">{i.subject}</header>
            <div className="adminbody">{i.body}</div>
            <div className="admindate">{i.date}</div>
            <div className="inboxsender">{i.sender}</div>
            <div className="inboxstatus">{i.new ? "unread" : "opened"}</div>
          </div>
        ))}

      <div className="adminsendbox">
        <div className="adminsender">
          <FormControl fullWidth>
            <span>sender</span>
            <InputLabel
              variant="standard"
              htmlFor="uncontrolled-native"
            ></InputLabel>
            <NativeSelect
              value={formData.sender}
              onChange={handleInputChange}
              inputProps={{
                name: "sender",
                id: "uncontrolled-native",
              }}
            >
              {users.map((u) => (
                <option key={u.id} value={u.name}>
                  {u.name}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
        </div>
        <div className="adminsrc">
          <FormControl fullWidth>
            <span>Src</span>
            <InputLabel
              variant="standard"
              htmlFor="uncontrolled-native"
            ></InputLabel>
            <NativeSelect
              value={formData.src}
              onChange={handleInputChange}
              defaultValue={""}
              inputProps={{
                name: "src",
                id: "uncontrolled-native",
              }}
            >
              {users.map((u) => (
                <option key={u.id} value={u.src}>
                  {u.name}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
        </div>
        <div className="adminsendsub"></div>
        <div className="adminsendsub">
          <TextField
            name="subject"
            value={formData.subject}
            onChange={handleInputChange}
            id="outlined-textarea"
            label="Subject"
            placeholder="Not more than 30 letters"
            multiline
            fullWidth
          />
        </div>

        <div className="adminsendcontent">
          <TextField
            name="body"
            value={formData.body}
            onChange={handleInputChange}
            id="outlined-multiline-static"
            label="Message"
            multiline
            rows={10}
            placeholder="use chatGPT to check your message for errors before pasting here"
            fullWidth
          />
        </div>
        <Button variant="contained" onClick={handleSubmit}>
          Send
        </Button>
      </div>
    </div>
  );
};

export default AdminInbox;
