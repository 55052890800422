import { Button } from "@mui/material";
import { useState } from "react";
import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";

const LandingHeaderMobile = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="landingheadermobile">
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <FontAwesomeIcon color="wheat" icon={faBars} />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => navigate("/blog")}>Contact</MenuItem>
        <MenuItem onClick={() => navigate("/api")}>API</MenuItem>
        <MenuItem onClick={() => navigate("/report-a-fraud")}>
          Report a fraud
        </MenuItem>
      </Menu>

      <h1 onClick={() => navigate("/")}>Cypher</h1>

      <Button
        variant="outlined"
        sx={{ backgroundColor: "black" }}
        onClick={() => navigate("/login")}
      >
        Login
      </Button>
    </div>
  );
};

export default LandingHeaderMobile;
