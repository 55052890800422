import { Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode"; // Install this via npm if not already installed (npm install jwt-decode)

const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem("token");

  // Helper function to check if the token is expired
  const isTokenExpired = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Current time in seconds
      return decodedToken.exp < currentTime; // True if token has expired
    } catch (error) {
      return true; // If decoding fails, consider the token invalid
    }
  };

  // If no token or the token is expired, redirect to login
  if (!token || isTokenExpired(token)) {
    localStorage.removeItem("token");

    return <Navigate to="/login" replace />;
  }

  // Return child components if the token is valid
  return children;
};

export default ProtectedRoute;
