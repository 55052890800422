import React, { useEffect, useState } from "react";
import { getEmails } from "../../../services/landing";
import { toast } from "react-toastify";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

const Newslettersm = () => {
  const [emails, setEmails] = useState([]);

  useEffect(() => {
    const fetchEmails = async () => {
      try {
        const { data } = await getEmails();
        setEmails(data);
      } catch (ex) {
        toast.error("something failed");
      }
    };
    fetchEmails();
  }, []);
  return (
    <div>
      <Table container>
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {emails &&
            emails.map((e) => (
              <TableRow key={e._id}>
                <TableCell>{e.email}</TableCell>
                <TableCell>{e.date}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default Newslettersm;
