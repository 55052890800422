import React from "react";
import "./styles.css";
import { Link } from "react-router-dom";

const ScamInfoCard = ({ preview, subject, link }) => {
  return (
    <div className="scaminfocard">
      <div className="scaminfosubject">
        <Link className="scaminfolinks" to={link}>
          {" "}
          {subject}
        </Link>
      </div>
      <div className="scaminfobody">{preview}</div>
    </div>
  );
};

export default ScamInfoCard;
