import React from "react";
import GridWrapper from "../../../common/GridWrapper/GridWrapper";
import ScamList from "./ScamInfoComponents/ScamList";
import { useEffect, useState } from "react";
import { getArticle, getNews } from "../../../services/updates";
import Loader3 from "../Loaders/Loader3";

function ScamInfo() {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [articles, setArticles] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await getArticle();
        const newdata = response.data;
        setArticles(newdata);
      } catch (err) {
        setError("Failed to fetch Articles.");
        console.error(err);
      }
    };

    fetchArticles(); // Call the async function
  }, []);

  useEffect(() => {
    const fetchCases = async () => {
      try {
        const response = await getNews();
        const newdata = response.data;
        setNews(newdata);
      } catch (err) {
        setError("Failed to fetch cases.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCases(); // Call the async function
  }, []);

  if (loading) {
    return <Loader3 />;
  }

  return (
    <GridWrapper item xs={8}>
      <ScamList scamupdates={news} articles={articles} />
    </GridWrapper>
  );
}

export default ScamInfo;
