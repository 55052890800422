import React from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import LandingHeaderMobile from "./LandingHeaderMobile";

const LandingHeader = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  const navigate = useNavigate();

  if (isMobile) {
    return <LandingHeaderMobile />;
  }

  return (
    <div className="landnav">
      <div className="navlogo" style={{ cursor: "pointer" }}>
        <h1 onClick={() => navigate("/")}>Cypher</h1>
      </div>
      <div className="navitems">
        <ul>
          <NavLink to="/blog">
            <li>Contact</li>
          </NavLink>
          <NavLink to="/api">
            {" "}
            <li>API</li>
          </NavLink>
          <NavLink to="/report-a-fraud">
            <li>Report a fraud</li>
          </NavLink>
        </ul>
      </div>
      <div className="loginregisterbox">
        <Stack direction="row" spacing={2}>
          <Button variant="outlined" onClick={() => navigate("/register")}>
            Register
          </Button>
          <Button
            variant="outlined"
            onClick={() => navigate("/login")}
            sx={{ backgroundColor: "black" }}
          >
            Sign in
          </Button>
        </Stack>
      </div>
    </div>
  );
};

export default LandingHeader;
