import React from "react";
import "./loader.css";

const Loader1 = () => {
  return (
    <div className="loaderpage">
      <div className="item">
        <i className="loader --3"></i>
      </div>
    </div>
  );
};

export default Loader1;
