import React from "react";
import { Typography } from "@mui/material";
import GridWrapper from "../../../../../common/GridWrapper/GridWrapper";
import editcaseimg from "../../../../../../assets/helpimg/editcase.png";
import { useMediaQuery } from "react-responsive";
import GridWrapper2 from "../../../../../common/GridWrapper/GridWrapper2";

const HelpEditcases = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  if (isMobile) {
    return (
      <GridWrapper2>
        {" "}
        <div className="scaminfodata">
          <header>How to start a new case</header>
        </div>
        <div className="updateimgbox"></div>
        <div className="helpinfodata">
          <Typography>
            <h4>Tutorial: How to Edit an Old Case</h4>

            <p>
              Welcome to this detailed guide on how to edit an existing case.
              Editing your case is an essential step to ensure that all relevant
              details are accurate and up-to-date. Whether you've made a mistake
              or have new information to add, this tutorial will provide you
              with a comprehensive overview of the process.
            </p>

            <h4>Step 1: Accessing the Cases Tab</h4>

            <p>
              To begin editing an old case, you first need to access the
              <b>Cases</b> tab on our platform. This section is dedicated to
              displaying all your previously submitted cases.
            </p>

            <ul>
              <li>Navigate to the main menu of the platform.</li>
              <li>
                Click on the <b>Cases</b> tab. This will direct you to a list of
                all your submitted cases.
              </li>
            </ul>

            <h4>Step 2: Selecting the Case to Edit</h4>

            <p>
              Once you are in the Cases section, you can select the specific
              case you wish to edit.
            </p>

            <ul>
              <li>
                Scroll through your list of cases to locate the one you want to
                edit.
              </li>
              <li>
                Click on the case title or the associated <b>Edit</b> button to
                open its details page.
              </li>
            </ul>

            <h4>Step 3: Viewing Existing Case Details</h4>

            <p>
              After selecting your case, you will be directed to its details
              page where you can view all previously submitted information.
            </p>

            <ul>
              <li>
                Click on the <b>Details</b> button to access the complete view
                of the case.
              </li>
              <li>
                Review the information carefully to identify any entries that
                need updating.
              </li>
            </ul>

            <div className="updateimgbox">
              <img src={editcaseimg} alt="" />
            </div>

            <h4>Step 4: Understanding Editing Limitations</h4>

            <p>
              It’s important to understand the editing capabilities available to
              you:
            </p>

            <ul>
              <li>
                <b>Non-removable Details:</b> Previously added information
                cannot be deleted or modified. This means that original entries
                will remain as part of the case record.
              </li>
              <li>
                <b>Adding New Information:</b> You can add new details that
                supplement or clarify the existing case data.
              </li>
              <li>
                <b>Correcting Mistakes:</b> If you realize that you made an
                error in any previous entry, you can correct it by entering a
                new detail. The system will discard invalid links or transaction
                histories automatically.
              </li>
            </ul>

            <h4>Step 5: Handling Faulty Links</h4>

            <p>
              If you provide links that are no longer valid or do not lead to
              useful information, our system has measures in place to notify
              you:
            </p>

            <ul>
              <li>
                If all links you provide are faulty, you will receive an alert
                indicating the issue.
              </li>
              <li>
                You will be prompted to add more valid details to ensure the
                case remains actionable and effective.
              </li>
            </ul>

            <h4>Step 6: Adding New Details</h4>

            <p>
              After reviewing and determining what new information to add,
              proceed to input the updates:
            </p>

            <ul>
              <li>
                Enter any additional details that you believe will aid in the
                case investigation.
              </li>
              <li>
                Make sure to double-check your entries for accuracy, as this
                information is critical for effective tracking and tracing.
              </li>
            </ul>

            <h4>Step 7: Submitting Your Changes</h4>

            <p>
              Once you have added the new information, it’s time to submit your
              changes:
            </p>

            <ul>
              <li>Review all newly added information one final time.</li>
              <li>
                Click the <b>Submit</b> button to confirm your changes. This
                action will update your case with the new details provided.
              </li>
            </ul>

            <h4>Conclusion</h4>

            <p>
              Editing an old case is a straightforward yet crucial process. By
              following the steps outlined in this tutorial, you can ensure that
              your case reflects the most accurate and relevant details.
              Accurate case information is essential for our team to effectively
              investigate and assist in the recovery of lost assets.
            </p>

            <p>
              If you have any questions or require further assistance during the
              editing process, please don’t hesitate to reach out through our
              help desk. Thank you for your commitment to making our community a
              safer place!
            </p>
          </Typography>
        </div>
      </GridWrapper2>
    );
  }
  return (
    <GridWrapper>
      <div className="scaminfodata">
        <header>How to start a new case</header>
      </div>

      <div className="updateimgbox"></div>

      <div className="helpinfodata">
        <Typography>
          <h4>Tutorial: How to Edit an Old Case</h4>

          <p>
            Welcome to this detailed guide on how to edit an existing case.
            Editing your case is an essential step to ensure that all relevant
            details are accurate and up-to-date. Whether you've made a mistake
            or have new information to add, this tutorial will provide you with
            a comprehensive overview of the process.
          </p>

          <h4>Step 1: Accessing the Cases Tab</h4>

          <p>
            To begin editing an old case, you first need to access the
            <b>Cases</b> tab on our platform. This section is dedicated to
            displaying all your previously submitted cases.
          </p>

          <ul>
            <li>Navigate to the main menu of the platform.</li>
            <li>
              Click on the <b>Cases</b> tab. This will direct you to a list of
              all your submitted cases.
            </li>
          </ul>

          <h4>Step 2: Selecting the Case to Edit</h4>

          <p>
            Once you are in the Cases section, you can select the specific case
            you wish to edit.
          </p>

          <ul>
            <li>
              Scroll through your list of cases to locate the one you want to
              edit.
            </li>
            <li>
              Click on the case title or the associated <b>Edit</b> button to
              open its details page.
            </li>
          </ul>

          <h4>Step 3: Viewing Existing Case Details</h4>

          <p>
            After selecting your case, you will be directed to its details page
            where you can view all previously submitted information.
          </p>

          <ul>
            <li>
              Click on the <b>Details</b> button to access the complete view of
              the case.
            </li>
            <li>
              Review the information carefully to identify any entries that need
              updating.
            </li>
          </ul>

          <div className="updateimgbox">
            <img src={editcaseimg} alt="" />
          </div>

          <h4>Step 4: Understanding Editing Limitations</h4>

          <p>
            It’s important to understand the editing capabilities available to
            you:
          </p>

          <ul>
            <li>
              <b>Non-removable Details:</b> Previously added information cannot
              be deleted or modified. This means that original entries will
              remain as part of the case record.
            </li>
            <li>
              <b>Adding New Information:</b> You can add new details that
              supplement or clarify the existing case data.
            </li>
            <li>
              <b>Correcting Mistakes:</b> If you realize that you made an error
              in any previous entry, you can correct it by entering a new
              detail. The system will discard invalid links or transaction
              histories automatically.
            </li>
          </ul>

          <h4>Step 5: Handling Faulty Links</h4>

          <p>
            If you provide links that are no longer valid or do not lead to
            useful information, our system has measures in place to notify you:
          </p>

          <ul>
            <li>
              If all links you provide are faulty, you will receive an alert
              indicating the issue.
            </li>
            <li>
              You will be prompted to add more valid details to ensure the case
              remains actionable and effective.
            </li>
          </ul>

          <h4>Step 6: Adding New Details</h4>

          <p>
            After reviewing and determining what new information to add, proceed
            to input the updates:
          </p>

          <ul>
            <li>
              Enter any additional details that you believe will aid in the case
              investigation.
            </li>
            <li>
              Make sure to double-check your entries for accuracy, as this
              information is critical for effective tracking and tracing.
            </li>
          </ul>

          <h4>Step 7: Submitting Your Changes</h4>

          <p>
            Once you have added the new information, it’s time to submit your
            changes:
          </p>

          <ul>
            <li>Review all newly added information one final time.</li>
            <li>
              Click the <b>Submit</b> button to confirm your changes. This
              action will update your case with the new details provided.
            </li>
          </ul>

          <h4>Conclusion</h4>

          <p>
            Editing an old case is a straightforward yet crucial process. By
            following the steps outlined in this tutorial, you can ensure that
            your case reflects the most accurate and relevant details. Accurate
            case information is essential for our team to effectively
            investigate and assist in the recovery of lost assets.
          </p>

          <p>
            If you have any questions or require further assistance during the
            editing process, please don’t hesitate to reach out through our help
            desk. Thank you for your commitment to making our community a safer
            place!
          </p>
        </Typography>
      </div>
    </GridWrapper>
  );
};

export default HelpEditcases;
