import React, { useState, useRef, useEffect } from "react";
import "./inboxstyles.css";
import { Avatar } from "@mui/material";
import SearchBar from "../../../../common/SearchBar/SearchBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { styled, alpha } from "@mui/material/styles";
import styled2 from "styled-components";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";

const EmailListContainer = styled2.div`
  margin-top: 1rem;
  padding: 1rem;
  background: #f9f9f9;
  border-radius: 5px;
  max-height: 25rem; // Max height for scrolling
  overflow-y: auto;
  position: absolute;
  top:0;
  left:0;
  z-index: 99

`;

const EmailItem = styled2.div`
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #e2e2e2;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
`;

const InboxHeader = ({ sender, src, subject, messages }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const inputRef = useRef(null);
  const handleChange = (value) => {
    setSearchQuery(value);
  };

  const filteredEmails = messages.filter(
    (email) =>
      email.sender.toLowerCase().includes(searchQuery.toLowerCase()) ||
      email.subject.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const userIcon = sender ? (
    <div className="usericon">
      <FontAwesomeIcon icon={faUser} color="rgba(0,0,0,0.5)" />
      <span className="margin1"></span>
      {sender}
    </div>
  ) : null;

  const subjectIcon = subject ? (
    <div className="subjecticon">
      <FontAwesomeIcon icon={faEnvelope} color="rgba(0,0,0,0.5)" />
      <span className="margin1"></span> {subject}
    </div>
  ) : null;

  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "black",
    width: "100%",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch",
        },
      },
    },
  }));

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div className="headercontainer">
      <div className="searchstyle">
        {" "}
        <Search style={{ backgroundColor: "rgba(0,0,0,0.06)" }}>
          <SearchIconWrapper>
            <SearchIcon color="black" />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            inputProps={{ "aria-label": "search" }}
            value={searchQuery} // Link the input value to the state
            onChange={(event) => handleChange(event.target.value)} // Call handleChange with the new value
            inputRef={inputRef} // Attach the ref to the input
          />
        </Search>{" "}
        {searchQuery && (
          <div style={{ position: "relative" }}>
            <EmailListContainer>
              {filteredEmails.map((email) => (
                <EmailItem key={email._id}>
                  <strong>{email.sender}</strong>: {email.subject}
                </EmailItem>
              ))}
              {filteredEmails.length === 0 && (
                <EmailItem>No emails found</EmailItem>
              )}
            </EmailListContainer>
          </div>
        )}
      </div>

      <div className="imgbox">
        <div>
          <div className="userh3">{userIcon}</div>
          <div className="imgboxspan">{subjectIcon}</div>
        </div>
        <div>
          {" "}
          <div className="avatar" style={{ textTransform: "capitalize" }}>
            {sender && <Avatar {...stringAvatar(`${sender}`)} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InboxHeader;
