import React from "react";

const NotFound = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <p style={{ fontSize: "1.5rem" }}>Page not found</p>
    </div>
  );
};

export default NotFound;
