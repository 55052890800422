import React from "react";
import MainCaseList from "./CasesComponent/MainCaseList";
import GridWrapper from "../../../common/GridWrapper/GridWrapper";
import { Typography } from "@mui/material";
import CasesMobile from "./CasesMobile";
import { useMediaQuery } from "react-responsive";
import { useEffect, useState } from "react";
import { getCases } from "../../../services/cases";

function Cases() {
  const [caselist, setCaselist] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCases = async () => {
      try {
        const response = await getCases();
        const newdata = response.data;
        setCaselist(newdata);
      } catch (err) {
        setError("Failed to fetch cases.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCases(); // Call the async function
  }, []);

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const getContent = () => (
    <Typography
      align="center"
      sx={{
        margin: "40px 16px",
        color: "rgba(0, 0, 0, 0.6)",
        fontSize: "1.3rem",
      }}
    >
      No active cases
    </Typography>
  );

  if (isMobile) {
    return <CasesMobile caselist={caselist} />;
  }

  return (
    <GridWrapper item xs={8}>
      {caselist.length ? <MainCaseList caselist={caselist} /> : getContent()}
    </GridWrapper>
  );
}

export default Cases;
