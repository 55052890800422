import React from "react";
import { useParams } from "react-router";
import { fraudtipitems } from "./fraudtipitems";
import GridWrapper from "../../../../common/GridWrapper/GridWrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTags } from "@fortawesome/free-solid-svg-icons";
import { Typography } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import GridWrapper2 from "../../../../common/GridWrapper/GridWrapper2";

const FraudTipsData = () => {
  const { id } = useParams();
  const caseId = parseInt(id, 10);
  const caseData = fraudtipitems.find((caseItem) => caseItem.id === caseId);
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  if (!caseData) {
    return <div className="scaminfodata">Item not found.</div>;
  }
  const { header, tag, body, img } = caseData;

  if (isMobile) {
    return (
      <GridWrapper2>
        <div className="scaminfodata">
          <header>{header}</header>
          <div className="tagdatebox">
            <div className="tagbox">
              <FontAwesomeIcon color="inherit" icon={faTags} />
              <span className="margin1"></span>
              {tag}
            </div>
          </div>
        </div>
        {img && (
          <div className="updateimgbox">
            <img src={img} />
          </div>
        )}

        <div className="scaminfodata">
          <Typography>{body}</Typography>
        </div>
      </GridWrapper2>
    );
  }

  return (
    <GridWrapper>
      <div className="scaminfodata">
        <header>{header}</header>
        <div className="tagdatebox">
          <div className="tagbox">
            <FontAwesomeIcon color="inherit" icon={faTags} />
            <span className="margin1"></span>
            {tag}
          </div>
        </div>
      </div>
      {img && (
        <div className="updateimgbox">
          <img src={img} />
        </div>
      )}

      <div className="scaminfodata">
        <Typography>{body}</Typography>
      </div>
    </GridWrapper>
  );
};

export default FraudTipsData;
