import React from "react";
import GridWrapper from "../../../common/GridWrapper/GridWrapper";
import HistoryMain from "./HistoryComponents/HistoryMain";
import GridWrapper2 from "../../../common/GridWrapper/GridWrapper2";
import { useMediaQuery } from "react-responsive";
import { useState, useEffect } from "react";
import { getCases } from "../../../services/cases";

const History = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [caselist, setCaselist] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchCases = async () => {
      try {
        const response = await getCases();
        const newdata = response.data;
        setCaselist(newdata);
        console.log("caselist", caselist);
        console.log("newdata", newdata);
      } catch (err) {
        setError("Failed to fetch cases.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCases();
  }, []);

  if (isMobile) {
    return (
      <GridWrapper2>
        <HistoryMain caselist={caselist} />
      </GridWrapper2>
    );
  }
  return (
    <GridWrapper>
      {" "}
      <HistoryMain caselist={caselist} />
    </GridWrapper>
  );
};

export default History;
