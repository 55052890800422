import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export function login(email, password) {
  return axios.post(`${apiUrl}/api/auth`, {
    email,
    password,
  });
}

export function resetPassword(currentPassword, newPassword) {
  const token = localStorage.getItem("token"); // Get token from local storage

  return axios.patch(
    `${apiUrl}/api/change-password`, // No id in the URL
    {
      currentPassword,
      newPassword,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`, // Include token in headers
      },
    }
  );
}
