import React, { useState } from "react";
import "../landingstyles.css";
import Input from "@mui/material/Input";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link, useNavigate } from "react-router-dom";
import LandingFooter from "./LandingFooter";
import { login } from "../../services/auth";

const Login = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [error, setError] = useState({});

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleClickShowPassword = () => setShowPassword((prev) => !prev);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const handleLogin = async () => {
    const { email, password } = formData;
    try {
      const { data } = await login(email, password);
      localStorage.setItem("token", data);
      navigate("/account/home");
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        setError({ message: ex.response.data });
      }
    }
  };

  return (
    <>
      <div className="mainlandingpage">
        <div className="loginwrapper">
          <div className="loginbox">
            <div className="loginlockicon">
              <LockOutlinedIcon
                style={{ backgroundColor: "black", borderRadius: "50%" }}
                color="white"
              />
            </div>
            <h3>Sign in</h3>
            <p className="loginwelcome">
              Welcome back, please sign in to continue
            </p>
            <div className="loginforms">
              <FormGroup>
                <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                  <Input
                    name="email"
                    type="email"
                    placeholder="Email"
                    onChange={handleChange}
                    style={{ backgroundColor: "white", paddingLeft: "0.5rem" }}
                  />
                </FormControl>
                <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                  <Input
                    name="password"
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    onChange={handleChange}
                    style={{ backgroundColor: "white", paddingLeft: "0.5rem" }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {error.message && (
                    <p style={{ color: "red" }}>{error.message}</p>
                  )}
                </FormControl>
                <FormControlLabel
                  control={
                    <Checkbox color="white" style={{ color: "white" }} />
                  }
                  label="Remember me"
                />
              </FormGroup>
              <button
                className="loginsubmitbtn"
                type="button"
                onClick={handleLogin}
              >
                <Typography> SIGN IN</Typography>
              </button>
              <div className="loginextras">
                <Link style={{ color: "wheat" }} to="/reset-password">
                  Forgot password?
                </Link>
                <Link style={{ color: "wheat" }} to="/register">
                  Sign up
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LandingFooter />
    </>
  );
};

export default Login;
