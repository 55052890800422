export const flags = [
  ["Afghanistan", "fi fi-af"],
  ["Albania", "fi fi-al"],
  ["Algeria", "fi fi-dz"],
  ["Andorra", "fi fi-ad"],
  ["Angola", "fi fi-ao"],
  ["Antigua and Barbuda", "fi fi-ag"],
  ["Argentina", "fi fi-ar"],
  ["Armenia", "fi fi-am"],
  ["Australia", "fi fi-au"],
  ["Austria", "fi fi-at"],
  ["Azerbaijan", "fi fi-az"],
  ["Bahamas", "fi fi-bs"],
  ["Bahrain", "fi fi-bh"],
  ["Bangladesh", "fi fi-bd"],
  ["Barbados", "fi fi-bb"],
  ["Belarus", "fi fi-by"],
  ["Belgium", "fi fi-be"],
  ["Belize", "fi fi-bz"],
  ["Benin", "fi fi-bj"],
  ["Bhutan", "fi fi-bt"],
  ["Bolivia", "fi fi-bo"],
  ["Bosnia and Herzegovina", "fi fi-ba"],
  ["Botswana", "fi fi-bw"],
  ["Brazil", "fi fi-br"],
  ["Brunei", "fi fi-bn"],
  ["Bulgaria", "fi fi-bg"],
  ["Burkina Faso", "fi fi-bf"],
  ["Burundi", "fi fi-bi"],
  ["Cabo Verde", "fi fi-cv"],
  ["Cambodia", "fi fi-kh"],
  ["Cameroon", "fi fi-cm"],
  ["Canada", "fi fi-ca"],
  ["Central African Republic", "fi fi-cf"],
  ["Chad", "fi fi-td"],
  ["Chile", "fi fi-cl"],
  ["China", "fi fi-cn"],
  ["Colombia", "fi fi-co"],
  ["Comoros", "fi fi-km"],
  ["Congo (Congo-Brazzaville)", "fi fi-cg"],
  ["Costa Rica", "fi fi-cr"],
  ["Croatia", "fi fi-hr"],
  ["Cuba", "fi fi-cu"],
  ["Cyprus", "fi fi-cy"],
  ["Czech Republic", "fi fi-cz"],
  ["Democratic Republic of the Congo", "fi fi-cd"],
  ["Denmark", "fi fi-dk"],
  ["Djibouti", "fi fi-dj"],
  ["Dominica", "fi fi-dm"],
  ["Dominican Republic", "fi fi-do"],
  ["Ecuador", "fi fi-ec"],
  ["Egypt", "fi fi-eg"],
  ["El Salvador", "fi fi-sv"],
  ["Equatorial Guinea", "fi fi-gq"],
  ["Eritrea", "fi fi-er"],
  ["Estonia", "fi fi-ee"],
  ["Eswatini", "fi fi-sz"],
  ["Ethiopia", "fi fi-et"],
  ["Fiji", "fi fi-fj"],
  ["Finland", "fi fi-fi"],
  ["France", "fi fi-fr"],
  ["Gabon", "fi fi-ga"],
  ["Gambia", "fi fi-gm"],
  ["Georgia", "fi fi-ge"],
  ["Germany", "fi fi-de"],
  ["Ghana", "fi fi-gh"],
  ["Greece", "fi fi-gr"],
  ["Grenada", "fi fi-gd"],
  ["Guatemala", "fi fi-gt"],
  ["Guinea", "fi fi-GN"],
  ["Guinea-Bissau", "fi fi-gw"],
  ["Guyana", "fi fi-gy"],
  ["Haiti", "fi fi-ht"],
  ["Honduras", "fi fi-hn"],
  ["Hungary", "fi fi-hu"],
  ["Iceland", "fi fi-is"],
  ["India", "fi fi-in"],
  ["Indonesia", "fi fi-id"],
  ["Iran", "fi fi-ir"],
  ["Iraq", "fi fi-iq"],
  ["Ireland", "fi fi-ie"],
  ["Israel", "fi fi-il"],
  ["Italy", "fi fi-it"],
  ["Jamaica", "fi fi-jm"],
  ["Japan", "fi fi-jp"],
  ["Jordan", "fi fi-jo"],
  ["Kazakhstan", "fi fi-kz"],
  ["Kenya", "fi fi-ke"],
  ["Kiribati", "fi fi-ki"],
  ["Kuwait", "fi fi-kw"],
  ["Kyrgyzstan", "fi fi-kg"],
  ["Laos", "fi fi-la"],
  ["Latvia", "fi fi-lv"],
  ["Lebanon", "fi fi-lb"],
  ["Lesotho", "fi fi-ls"],
  ["Liberia", "fi fi-lr"],
  ["Libya", "fi fi-ly"],
  ["Liechtenstein", "fi fi-li"],
  ["Lithuania", "fi fi-lt"],
  ["Luxembourg", "fi fi-lu"],
  ["Madagascar", "fi fi-mg"],
  ["Malawi", "fi fi-mw"],
  ["Malaysia", "fi fi-my"],
  ["Maldives", "fi fi-mv"],
  ["Mali", "fi fi-ml"],
  ["Malta", "fi fi-mt"],
  ["Marshall Islands", "fi fi-mh"],
  ["Mauritania", "fi fi-mr"],
  ["Mauritius", "fi fi-mu"],
  ["Mexico", "fi fi-mx"],
  ["Micronesia", "fi fi-fm"],
  ["Moldova", "fi fi-md"],
  ["Monaco", "fi fi-mc"],
  ["Mongolia", "fi fi-mn"],
  ["Montenegro", "fi fi-me"],
  ["Morocco", "fi fi-ma"],
  ["Mozambique", "fi fi-mz"],
  ["Myanmar", "fi fi-mm"],
  ["Namibia", "fi fi-na"],
  ["Nauru", "fi fi-nr"],
  ["Nepal", "fi fi-np"],
  ["Netherlands", "fi fi-nl"],
  ["New Zealand", "fi fi-nz"],
  ["Nicaragua", "fi fi-ni"],
  ["Niger", "fi fi-ne"],
  ["Nigeria", "fi fi-ng"],
  ["North Korea", "fi fi-kp"],
  ["North Macedonia", "fi fi-mk"],
  ["Norway", "fi fi-no"],
  ["Oman", "fi fi-om"],
  ["Pakistan", "fi fi-pk"],
  ["Palau", "fi fi-pw"],
  ["Palestine", "fi fi-ps"],
  ["Panama", "fi fi-pa"],
  ["Papua New Guinea", "fi fi-pg"],
  ["Paraguay", "fi fi-py"],
  ["Peru", "fi fi-pe"],
  ["Philippines", "fi fi-ph"],
  ["Poland", "fi fi-pl"],
  ["Portugal", "fi fi-pt"],
  ["Qatar", "fi fi-qa"],
  ["Romania", "fi fi-ro"],
  ["Russia", "fi fi-ru"],
  ["Rwanda", "fi fi-rw"],
  ["Saint Kitts and Nevis", "fi fi-kn"],
  ["Saint Lucia", "fi fi-lc"],
  ["Saint Vincent and the Grenadines", "fi fi-vc"],
  ["Samoa", "fi fi-ws"],
  ["San Marino", "fi fi-sm"],
  ["Sao Tome and Principe", "fi fi-st"],
  ["Saudi Arabia", "fi fi-sa"],
  ["Senegal", "fi fi-sn"],
  ["Serbia", "fi fi-rs"],
  ["Seychelles", "fi fi-sc"],
  ["Sierra Leone", "fi fi-sl"],
  ["Singapore", "fi fi-sg"],
  ["Slovakia", "fi fi-sk"],
  ["Slovenia", "fi fi-si"],
  ["Solomon Islands", "fi fi-sb"],
  ["Somalia", "fi fi-so"],
  ["South Africa", "fi fi-za"],
  ["South Korea", "fi fi-kr"],
  ["South Sudan", "fi fi-ss"],
  ["Spain", "fi fi-es"],
  ["Sri Lanka", "fi fi-lk"],
  ["Sudan", "fi fi-sd"],
  ["Suriname", "fi fi-sr"],
  ["Sweden", "fi fi-se"],
  ["Switzerland", "fi fi-ch"],
  ["Syria", "fi fi-sy"],
  ["Taiwan", "fi fi-tw"],
  ["Tajikistan", "fi fi-tj"],
  ["Tanzania", "fi fi-tz"],
  ["Thailand", "fi fi-th"],
  ["Timor-Leste", "fi fi-tl"],
  ["Togo", "fi fi-tg"],
  ["Tonga", "fi fi-to"],
  ["Trinidad and Tobago", "fi fi-tt"],
  ["Tunisia", "fi fi-tn"],
  ["Turkey", "fi fi-tr"],
  ["Turkmenistan", "fi fi-tm"],
  ["Tuvalu", "fi fi-tv"],
  ["Uganda", "fi fi-ug"],
  ["Ukraine", "fi fi-ua"],
  ["United Arab Emirates", "fi fi-ae"],
  ["United Kingdom", "fi fi-gb"],
  ["United States", "fi fi-us"],
  ["Uruguay", "fi fi-uy"],
  ["Uzbekistan", "fi fi-uz"],
  ["Vanuatu", "fi fi-vu"],
  ["Vatican City", "fi fi-va"],
  ["Venezuela", "fi fi-ve"],
  ["Vietnam", "fi fi-vn"],
  ["Yemen", "fi fi-ye"],
  ["Zambia", "fi fi-zm"],
  ["Zimbabwe", "fi fi-zw"],
];
