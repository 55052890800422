import React from "react";
import { Button, Typography } from "@mui/material";

const ProductHome = ({ products, productClick }) => {
  const { subject, body } = products || {};
  return (
    <div className="producthome">
      <div className="producthomebox">
        <div className="homepheader">
          <div className="homeptopic">{subject}</div>

          <Button
            variant="contained"
            className="homebutton"
            onClick={productClick}
          >
            Request
          </Button>
        </div>
        <div className="homepcontent">
          {" "}
          <Typography>{body}</Typography>
        </div>
      </div>
    </div>
  );
};

export default ProductHome;
