import React from "react";
import Grid from "@mui/material/Grid2";
import { scamupdates } from "../../../consts/scampudates";
import ScamInfoSide from "../../ScamInfo/ScamInfoComponents/ScamInfoSide";
import { fraudtipitems } from "./fraudtipitems";
import "./styles.css";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMediaQuery } from "react-responsive";

const FraudTipsMain = ({ articles }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  return (
    <Grid container>
      <Grid size={isMobile ? 12 : 8}>
        <div className="scaminfo">
          <header>Fraud Tips</header>{" "}
          {fraudtipitems.map((f) => (
            <Link
              className="fraudtipslinks"
              key={f.id}
              to={`/account/fraudtips/${f.id}`}
            >
              <h5>
                {" "}
                <Typography>
                  {" "}
                  <div className="fraudinfohead">
                    <FontAwesomeIcon icon={faInfo} />{" "}
                    <span className="margin1"></span>
                    {f.header}
                  </div>
                </Typography>{" "}
              </h5>
            </Link>
          ))}
        </div>
      </Grid>
      <Grid size={4}>
        <div className="scaminfoside">
          <header>Popular Updates</header>

          {articles &&
            articles.map((u) => (
              <ScamInfoSide
                key={u._id}
                sidelinks={u.link}
                sidelinkstitle={u.info}
              />
            ))}
        </div>
      </Grid>
    </Grid>
  );
};

export default FraudTipsMain;
