import React from "react";
import { Typography } from "@mui/material";
import GridWrapper from "../../../../../common/GridWrapper/GridWrapper";
import pkimg from "../../../../../../assets/helpimg/pk.png";
import { useMediaQuery } from "react-responsive";
import GridWrapper2 from "../../../../../common/GridWrapper/GridWrapper2";

const HelpPk = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  if (isMobile) {
    return (
      <GridWrapper2>
        <div className="scaminfodata">
          <header>
            Understanding Outcome Probability (P<sub>K</sub>): A Comprehensive
            Guide
          </header>
        </div>

        <div className="updateimgbox"></div>

        <div className="helpinfodata">
          <Typography>
            <p>
              When it comes to recovering assets lost to fraud, understanding
              the intricacies of how we assess each case is crucial. One of the
              key metrics we employ is the <b>Outcome Probability</b>, denoted
              as{" "}
              <b>
                P<sub>K</sub>
              </b>
              . This metric not only aids us in determining the potential for
              success in each case but also serves as a guiding principle in our
              operational framework.
            </p>

            <h2>
              What is Outcome Probability (P<sub>K</sub>)?
            </h2>

            <p>
              Outcome Probability, or the Probability of Kill (
              <b>
                P<sub>K</sub>
              </b>
              ), is a term that might sound familiar to fans of military video
              games, as it derives its name from a military concept. In those
              games,{" "}
              <b>
                P<sub>K</sub>
              </b>{" "}
              refers to the likelihood of a weapon successfully achieving its
              objective—essentially, the chance of a "kill." Similarly, in our
              context,{" "}
              <b>
                P<sub>K</sub>
              </b>{" "}
              represents the probability of a successful recovery of assets
              based on various data inputs.
            </p>

            <h3>
              The Scale of P<sub>K</sub>
            </h3>

            <div className="updateimgbox">
              <img src={pkimg} alt="" />
            </div>

            <p>
              <b>
                P<sub>K</sub>
              </b>{" "}
              is measured on a scale from 0 to 1:
            </p>
            <ul>
              <li>
                <b>0</b> indicates no chance of recovery.
              </li>
              <li>
                <b>1</b> signifies a 100% likelihood of a successful outcome.
              </li>
            </ul>

            <p>
              As we delve deeper into the factors influencing{" "}
              <b>
                P<sub>K</sub>
              </b>
              , you'll see how this metric is not just a number but a nuanced
              representation of a case's potential for recovery.
            </p>

            <h2>
              How is P<sub>K</sub> Calculated?
            </h2>

            <p>
              The calculation of{" "}
              <b>
                P<sub>K</sub>
              </b>{" "}
              is influenced by multiple factors:
            </p>

            <h3>1. Preliminary Trace Information</h3>
            <p>
              The initial data gathered during the early stages of a case plays
              a vital role in shaping{" "}
              <b>
                P<sub>K</sub>
              </b>
              . This includes any leads, social media connections, and forensic
              evidence that can point to the suspect's activities. The more
              robust this information is, the higher the{" "}
              <b>
                P<sub>K</sub>
              </b>{" "}
              value can potentially be.
            </p>

            <h3>2. Suspect Identified Location</h3>
            <p>
              The location of the suspect is a significant factor. However, it’s
              crucial to note that regional privacy laws may hinder our ability
              to fully utilize certain tools for uncovering a suspect's
              identity. This limitation can lower the{" "}
              <b>
                P<sub>K</sub>
              </b>{" "}
              value if we encounter legal obstacles in tracing the suspect’s
              true location.
            </p>

            <h3>3. Local Law Enforcement Rating</h3>
            <p>
              Our past experiences with local law enforcement agencies
              significantly affect our assessment. If we've had successful
              collaborations in the past, the{" "}
              <b>
                P<sub>K</sub>
              </b>{" "}
              value may be boosted. Conversely, if local law enforcement has
              proven to be uncooperative or ineffective, it may act as a
              deterrent to recovery efforts, thus lowering the{" "}
              <b>
                P<sub>K</sub>
              </b>
              .
            </p>

            <h3>4. Use of Cryptocurrency as Payment</h3>
            <p>
              The nature of payments—especially if they involve
              cryptocurrency—adds another layer of complexity. While
              cryptocurrency transactions can provide anonymity, they can also
              be tracked with the right tools. Our dedicated tool,{" "}
              <b>Cypherbrute</b>, helps in gathering identifiers that are
              critical for tracing these transactions, which can influence the{" "}
              <b>
                P<sub>K</sub>
              </b>{" "}
              value positively or negatively.
            </p>

            <h3>5. Date of Asset Loss</h3>
            <p>
              The duration between the asset loss and the case initiation is
              essential. The longer the time frame, the harder it becomes to
              recover assets. This time lapse can lower the{" "}
              <b>
                P<sub>K</sub>
              </b>{" "}
              value, as opportunities to trace the suspect diminish.
            </p>

            <h3>6. Suspect's Resistance to Social Constructs</h3>
            <p>
              Every suspect has a unique profile, and their willingness or
              resistance to engage with social media can influence recovery
              attempts. A suspect who actively uses social media may leave
              behind more trails for us to follow, enhancing the{" "}
              <b>
                P<sub>K</sub>
              </b>
              .
            </p>

            <h3>7. Invalid Social Links or Payment Details</h3>
            <p>
              If the social links or payment details provided by the user turn
              out to be invalid, this can significantly decrease the{" "}
              <b>
                P<sub>K</sub>
              </b>{" "}
              value. Our team relies on accurate and truthful information to
              perform effective preliminary tracing.
            </p>

            <h3>8. Cryptocurrency Outside Cypherbrute's Effective Spectrum</h3>
            <p>
              If a cryptocurrency is used that falls outside the effective
              tracking capabilities of our tools like Cypherbrute, this can lead
              to a decline in{" "}
              <b>
                P<sub>K</sub>
              </b>
              . Each cryptocurrency has its own level of traceability, and the
              more obscure the currency, the harder it is for us to track it.
            </p>

            <h2>
              The Importance of P<sub>K</sub> in Case Management
            </h2>

            <p>
              Understanding{" "}
              <b>
                P<sub>K</sub>
              </b>{" "}
              is crucial for our operational strategy. Here’s why:
            </p>

            <h3>Case Acceptance and Rejection</h3>
            <p>
              A{" "}
              <b>
                P<sub>K</sub>
              </b>{" "}
              value of <b>0.5</b> is considered the minimum threshold for a case
              to be accepted. If a case does not meet this criterion, we may
              advise the user to provide additional context or details to
              facilitate better tracing attempts.
            </p>

            <p>
              Moreover, any case with a{" "}
              <b>
                P<sub>K</sub>
              </b>{" "}
              value falling below 0.5 will trigger a request for more
              information within <b>12 hours</b> of case submission. If the{" "}
              <b>
                P<sub>K</sub>
              </b>{" "}
              remains below 0.5 for <b>five consecutive days</b>, the case will
              automatically be rejected unless further valid information is
              provided. This strict threshold helps us focus on cases with a
              genuine potential for recovery.
            </p>

            <h3>
              Drastic Changes in P<sub>K</sub>
            </h3>
            <p>
              <b>
                P<sub>K</sub>
              </b>{" "}
              values can experience significant fluctuations. Successful social
              constructs, effective use of cypher trace data from payments, or
              positive identifiers from tools like Cypherbrute can cause a sharp
              increase in the{" "}
              <b>
                P<sub>K</sub>
              </b>
              . Conversely, the lack of cooperation or invalid details can lead
              to a drastic drop.
            </p>

            <h3>New Cases Against Known Suspects</h3>
            <p>
              When new cases arise against suspects already under investigation,
              there is often a substantial boost in the{" "}
              <b>
                P<sub>K</sub>
              </b>{" "}
              value. This is due to the availability of existing data that can
              enhance the chances of a successful outcome. Such boosts are
              invaluable and can dramatically improve the recovery potential for
              new cases.
            </p>

            <h2>
              P<sub>K</sub> as a Recovery Indicator
            </h2>

            <p>
              A strong{" "}
              <b>
                P<sub>K</sub>
              </b>{" "}
              value is indicative of a higher likelihood of successful asset
              recovery. It serves as a critical metric that informs our
              strategies and decisions.
            </p>

            <p>
              In summary, understanding and leveraging{" "}
              <b>
                P<sub>K</sub>
              </b>{" "}
              is essential for both users and our team. It provides a clear
              indication of the viability of a case and helps us allocate
              resources effectively.
            </p>

            <h2>Conclusion</h2>

            <p>
              Outcome Probability (
              <b>
                P<sub>K</sub>
              </b>
              ) is not just a number; it's a sophisticated assessment that
              encapsulates various aspects of a case, from the quality of
              evidence to the nature of the suspect's actions. By understanding
              this concept, users can better appreciate how their information
              impacts the likelihood of recovery and can work collaboratively
              with our team to enhance their cases.
            </p>

            <p>
              If you have more questions about{" "}
              <b>
                P<sub>K</sub>
              </b>{" "}
              or need assistance with your case, don’t hesitate to reach out
              through our help desk. Together, we can navigate the complexities
              of asset recovery and work toward a successful resolution.
            </p>
          </Typography>
        </div>
      </GridWrapper2>
    );
  }
  return (
    <GridWrapper>
      <div className="scaminfodata">
        <header>
          Understanding Outcome Probability (P<sub>K</sub>): A Comprehensive
          Guide
        </header>
      </div>

      <div className="updateimgbox"></div>

      <div className="helpinfodata">
        <Typography>
          <p>
            When it comes to recovering assets lost to fraud, understanding the
            intricacies of how we assess each case is crucial. One of the key
            metrics we employ is the <b>Outcome Probability</b>, denoted as{" "}
            <b>
              P<sub>K</sub>
            </b>
            . This metric not only aids us in determining the potential for
            success in each case but also serves as a guiding principle in our
            operational framework.
          </p>

          <h2>
            What is Outcome Probability (P<sub>K</sub>)?
          </h2>

          <p>
            Outcome Probability, or the Probability of Kill (
            <b>
              P<sub>K</sub>
            </b>
            ), is a term that might sound familiar to fans of military video
            games, as it derives its name from a military concept. In those
            games,{" "}
            <b>
              P<sub>K</sub>
            </b>{" "}
            refers to the likelihood of a weapon successfully achieving its
            objective—essentially, the chance of a "kill." Similarly, in our
            context,{" "}
            <b>
              P<sub>K</sub>
            </b>{" "}
            represents the probability of a successful recovery of assets based
            on various data inputs.
          </p>

          <h3>
            The Scale of P<sub>K</sub>
          </h3>

          <div className="updateimgbox">
            <img src={pkimg} alt="" />
          </div>

          <p>
            <b>
              P<sub>K</sub>
            </b>{" "}
            is measured on a scale from 0 to 1:
          </p>
          <ul>
            <li>
              <b>0</b> indicates no chance of recovery.
            </li>
            <li>
              <b>1</b> signifies a 100% likelihood of a successful outcome.
            </li>
          </ul>

          <p>
            As we delve deeper into the factors influencing{" "}
            <b>
              P<sub>K</sub>
            </b>
            , you'll see how this metric is not just a number but a nuanced
            representation of a case's potential for recovery.
          </p>

          <h2>
            How is P<sub>K</sub> Calculated?
          </h2>

          <p>
            The calculation of{" "}
            <b>
              P<sub>K</sub>
            </b>{" "}
            is influenced by multiple factors:
          </p>

          <h3>1. Preliminary Trace Information</h3>
          <p>
            The initial data gathered during the early stages of a case plays a
            vital role in shaping{" "}
            <b>
              P<sub>K</sub>
            </b>
            . This includes any leads, social media connections, and forensic
            evidence that can point to the suspect's activities. The more robust
            this information is, the higher the{" "}
            <b>
              P<sub>K</sub>
            </b>{" "}
            value can potentially be.
          </p>

          <h3>2. Suspect Identified Location</h3>
          <p>
            The location of the suspect is a significant factor. However, it’s
            crucial to note that regional privacy laws may hinder our ability to
            fully utilize certain tools for uncovering a suspect's identity.
            This limitation can lower the{" "}
            <b>
              P<sub>K</sub>
            </b>{" "}
            value if we encounter legal obstacles in tracing the suspect’s true
            location.
          </p>

          <h3>3. Local Law Enforcement Rating</h3>
          <p>
            Our past experiences with local law enforcement agencies
            significantly affect our assessment. If we've had successful
            collaborations in the past, the{" "}
            <b>
              P<sub>K</sub>
            </b>{" "}
            value may be boosted. Conversely, if local law enforcement has
            proven to be uncooperative or ineffective, it may act as a deterrent
            to recovery efforts, thus lowering the{" "}
            <b>
              P<sub>K</sub>
            </b>
            .
          </p>

          <h3>4. Use of Cryptocurrency as Payment</h3>
          <p>
            The nature of payments—especially if they involve
            cryptocurrency—adds another layer of complexity. While
            cryptocurrency transactions can provide anonymity, they can also be
            tracked with the right tools. Our dedicated tool, <b>Cypherbrute</b>
            , helps in gathering identifiers that are critical for tracing these
            transactions, which can influence the{" "}
            <b>
              P<sub>K</sub>
            </b>{" "}
            value positively or negatively.
          </p>

          <h3>5. Date of Asset Loss</h3>
          <p>
            The duration between the asset loss and the case initiation is
            essential. The longer the time frame, the harder it becomes to
            recover assets. This time lapse can lower the{" "}
            <b>
              P<sub>K</sub>
            </b>{" "}
            value, as opportunities to trace the suspect diminish.
          </p>

          <h3>6. Suspect's Resistance to Social Constructs</h3>
          <p>
            Every suspect has a unique profile, and their willingness or
            resistance to engage with social media can influence recovery
            attempts. A suspect who actively uses social media may leave behind
            more trails for us to follow, enhancing the{" "}
            <b>
              P<sub>K</sub>
            </b>
            .
          </p>

          <h3>7. Invalid Social Links or Payment Details</h3>
          <p>
            If the social links or payment details provided by the user turn out
            to be invalid, this can significantly decrease the{" "}
            <b>
              P<sub>K</sub>
            </b>{" "}
            value. Our team relies on accurate and truthful information to
            perform effective preliminary tracing.
          </p>

          <h3>8. Cryptocurrency Outside Cypherbrute's Effective Spectrum</h3>
          <p>
            If a cryptocurrency is used that falls outside the effective
            tracking capabilities of our tools like Cypherbrute, this can lead
            to a decline in{" "}
            <b>
              P<sub>K</sub>
            </b>
            . Each cryptocurrency has its own level of traceability, and the
            more obscure the currency, the harder it is for us to track it.
          </p>

          <h2>
            The Importance of P<sub>K</sub> in Case Management
          </h2>

          <p>
            Understanding{" "}
            <b>
              P<sub>K</sub>
            </b>{" "}
            is crucial for our operational strategy. Here’s why:
          </p>

          <h3>Case Acceptance and Rejection</h3>
          <p>
            A{" "}
            <b>
              P<sub>K</sub>
            </b>{" "}
            value of <b>0.5</b> is considered the minimum threshold for a case
            to be accepted. If a case does not meet this criterion, we may
            advise the user to provide additional context or details to
            facilitate better tracing attempts.
          </p>

          <p>
            Moreover, any case with a{" "}
            <b>
              P<sub>K</sub>
            </b>{" "}
            value falling below 0.5 will trigger a request for more information
            within <b>12 hours</b> of case submission. If the{" "}
            <b>
              P<sub>K</sub>
            </b>{" "}
            remains below 0.5 for <b>five consecutive days</b>, the case will
            automatically be rejected unless further valid information is
            provided. This strict threshold helps us focus on cases with a
            genuine potential for recovery.
          </p>

          <h3>
            Drastic Changes in P<sub>K</sub>
          </h3>
          <p>
            <b>
              P<sub>K</sub>
            </b>{" "}
            values can experience significant fluctuations. Successful social
            constructs, effective use of cypher trace data from payments, or
            positive identifiers from tools like Cypherbrute can cause a sharp
            increase in the{" "}
            <b>
              P<sub>K</sub>
            </b>
            . Conversely, the lack of cooperation or invalid details can lead to
            a drastic drop.
          </p>

          <h3>New Cases Against Known Suspects</h3>
          <p>
            When new cases arise against suspects already under investigation,
            there is often a substantial boost in the{" "}
            <b>
              P<sub>K</sub>
            </b>{" "}
            value. This is due to the availability of existing data that can
            enhance the chances of a successful outcome. Such boosts are
            invaluable and can dramatically improve the recovery potential for
            new cases.
          </p>

          <h2>
            P<sub>K</sub> as a Recovery Indicator
          </h2>

          <p>
            A strong{" "}
            <b>
              P<sub>K</sub>
            </b>{" "}
            value is indicative of a higher likelihood of successful asset
            recovery. It serves as a critical metric that informs our strategies
            and decisions.
          </p>

          <p>
            In summary, understanding and leveraging{" "}
            <b>
              P<sub>K</sub>
            </b>{" "}
            is essential for both users and our team. It provides a clear
            indication of the viability of a case and helps us allocate
            resources effectively.
          </p>

          <h2>Conclusion</h2>

          <p>
            Outcome Probability (
            <b>
              P<sub>K</sub>
            </b>
            ) is not just a number; it's a sophisticated assessment that
            encapsulates various aspects of a case, from the quality of evidence
            to the nature of the suspect's actions. By understanding this
            concept, users can better appreciate how their information impacts
            the likelihood of recovery and can work collaboratively with our
            team to enhance their cases.
          </p>

          <p>
            If you have more questions about{" "}
            <b>
              P<sub>K</sub>
            </b>{" "}
            or need assistance with your case, don’t hesitate to reach out
            through our help desk. Together, we can navigate the complexities of
            asset recovery and work toward a successful resolution.
          </p>
        </Typography>
      </div>
    </GridWrapper>
  );
};

export default HelpPk;
