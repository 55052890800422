import "../landingstyles.css";
import React, { useState } from "react";
import RegisterCard from "./RegisterCard";
import RegisterMobileCard from "./RegisterMobileCard";

const Register = () => {
  return (
    <div className="parent-container">
      <RegisterMobileCard />
    </div>
  );
};

export default Register;
