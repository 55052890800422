import React, { useImperativeHandle, forwardRef } from "react";
import MainInbox from "./InboxComponents/MainInbox";
import GridWrapper from "../../../common/GridWrapper/GridWrapper";
import { useMediaQuery } from "react-responsive";
import InboxMobile from "./InboxMobile";
import { getInboxes } from "../../../services/inbox";
import { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import Loader2 from "../Loaders/Loader2";

function Inbox() {
  const isMobile = useMediaQuery({ query: "(max-width: 1300px)" });
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchCases = async () => {
      try {
        const response = await getInboxes();
        const newdata = response.data;
        setMessages(newdata);
      } catch (err) {
        setError("Failed to fetch inbox.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCases(); // Call the async function
  }, []);

  if (loading) {
    return <Loader2 />;
  }

  if (messages.length == 0) {
    return (
      <Typography
        align="center"
        sx={{
          margin: "40px 16px",
          color: "rgba(0, 0, 0, 0.6)",
          fontSize: "1.3rem",
        }}
      >
        Inbox empty
      </Typography>
    );
  }

  if (isMobile) {
    return <InboxMobile messages={messages} />;
  }
  return (
    <GridWrapper>
      <MainInbox messages={messages} />
    </GridWrapper>
  );
}

export default Inbox;
