import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export function getCases() {
  const token = localStorage.getItem("token");
  return axios.get(`${apiUrl}/api/cases`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function addCases(cases) {
  const token = localStorage.getItem("token");
  return axios.post(`${apiUrl}/api/cases`, cases, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getCase(id) {
  const token = localStorage.getItem("token");
  return axios.get(`${apiUrl}/api/cases/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function editCase(id, formData) {
  const token = localStorage.getItem("token");

  return axios.put(`${apiUrl}/api/cases/${id}`, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
