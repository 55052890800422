import React, { useEffect, useState } from "react";
import GridWrapper from "../../common/GridWrapper/GridWrapper2";
import { Typography } from "@mui/material";
import Loader3 from "./Loaders/Loader3";

const Requests = () => {
  const [loading, setLoading] = useState(true);

  setTimeout(() => {
    setLoading(false);
  }, 5000);

  if (loading) {
    return <Loader3 />;
  }

  return (
    <GridWrapper>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "4rem",
          color: "rgba(0,0,0,0.5)",
        }}
      >
        <Typography>No API request added</Typography>
      </div>
    </GridWrapper>
  );
};

export default Requests;
