import React, { useEffect, useState } from "react";
import { getUsers } from "../../../services/user";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router";

const Admin = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await getUsers();
        setUsers(response.data);
      } catch (err) {
        setError("Failed to fetch users.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const navigate = useNavigate();

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedUsers = [...users].sort((a, b) => {
    if (sortConfig.key) {
      const direction = sortConfig.direction === "asc" ? 1 : -1;
      if (a[sortConfig.key] < b[sortConfig.key]) return -1 * direction;
      if (a[sortConfig.key] > b[sortConfig.key]) return 1 * direction;
      return 0;
    }
    return 0;
  });

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <div className="adminhub">
      <div className="adminusers">
        <div className="usercard">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell onClick={() => handleSort("firstName")}>
                  First Name
                </TableCell>
                <TableCell onClick={() => handleSort("lastName")}>
                  Last Name
                </TableCell>
                <TableCell onClick={() => handleSort("email")}>Email</TableCell>
                <TableCell onClick={() => handleSort("country")}>
                  Country
                </TableCell>
                <TableCell onClick={() => handleSort("phone")}>Phone</TableCell>
                <TableCell onClick={() => handleSort("ipAddress")}>
                  IP Address
                </TableCell>
                <TableCell>Message</TableCell>
                <TableCell>Cases</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedUsers.map((u) => (
                <TableRow key={u._id}>
                  <TableCell>{u.firstName}</TableCell>
                  <TableCell>{u.lastName}</TableCell>
                  <TableCell>{u.email}</TableCell>
                  <TableCell>{u.country}</TableCell>
                  <TableCell>{u.phone}</TableCell>
                  <TableCell>{u.ipAddress}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      onClick={() => navigate(`/admin/inbox/${u._id}`)}
                    >
                      Message
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      onClick={() => navigate(`/admin/cases/${u._id}`)}
                    >
                      Cases
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
      <div className="admincontent"></div>
    </div>
  );
};

export default Admin;
