import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import btclogo from "../../../../images/logos/bitcoin.png";
import ethlogo from "../../../../images/logos/ethereum_eth_logo_e69b1c2368.png";
import dodgelogo from "../../../../images/logos/dogecoin_doge_logo_f18d59aae4.png";
import xrplogo from "../../../../images/logos/xrp_xrp_logo_c2ba16fdd9.png";
import bnblogo from "../../../../images/logos/bnb_bnb_logo_a2a64c3335.png";
import sollogo from "../../../../images/logos/solana-sol-logo.png";
import usdtlogo from "../../../../images/logos/tether_usdt_logo_0231fbcde8.png";
import usdclogo from "../../../../images/logos/usd_coin_usdc_logo_3e68fafa38.png";
import Typography from "@mui/material/Typography";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import GridWrapper from "../../../common/GridWrapper/GridWrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faLinkedin,
  faSquareInstagram,
  faSquareXTwitter,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import { editCase } from "../../../services/cases";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";

// Social Media Icons
const facebookIcon = <FontAwesomeIcon color="#1877F2" icon={faFacebook} />;
const instagramIcon = (
  <FontAwesomeIcon color="#DD2A7B" icon={faSquareInstagram} />
);
const twitterIcon = <FontAwesomeIcon color="black" icon={faSquareXTwitter} />;
const linkedinIcon = <FontAwesomeIcon color="#0077B5" icon={faLinkedin} />;
const tiktokIcon = <FontAwesomeIcon color="black" icon={faTiktok} />;

const cryptoTypes = [
  { value: "BTC", label: "Bitcoin", logo: btclogo },
  { value: "ETH", label: "Ethereum", logo: ethlogo },
  { value: "XRP", label: "XRP", logo: xrplogo },
  { value: "USDT", label: "Tether", logo: usdtlogo },
  { value: "USDC", label: "USD Coin", logo: usdclogo },
  { value: "SOL", label: "Solana", logo: sollogo },
  { value: "DODGE", label: "Dogecoin", logo: dodgelogo },
  { value: "BNB", label: "Binance Coin", logo: bnblogo },
  { value: "Others", label: "Others", logo: null },
];

const EditCase = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    socialLinks: [{ type: "", link: "" }],
    emails: [""],
    payments: [{ type: "", address: "", txid: "" }],
    bankDetails: [
      { bankName: "", bankAccount: "", amountPaid: "", datePaid: "" },
    ],
  });

  // Add a new social link
  const addSocialLink = () => {
    setFormData((prevData) => ({
      ...prevData,
      socialLinks: [...prevData.socialLinks, { type: "", link: "" }],
    }));
  };

  // Handle changes to the social link form fields
  const handleSocialChange = (index, event) => {
    const { name, value } = event.target;
    const updatedLinks = formData.socialLinks.map((link, i) =>
      i === index ? { ...link, [name]: value } : link
    );
    setFormData((prevData) => ({
      ...prevData,
      socialLinks: updatedLinks,
    }));
  };

  // Add a new email address
  const addEmail = () => {
    setFormData((prevData) => ({
      ...prevData,
      emails: [...prevData.emails, ""],
    }));
  };

  // Handle changes to email addresses
  const handleEmailChange = (index, event) => {
    const { value } = event.target;
    const updatedEmails = formData.emails.map((email, i) =>
      i === index ? value : email
    );
    setFormData((prevData) => ({
      ...prevData,
      emails: updatedEmails,
    }));
  };

  // Add a new payment information entry
  const addPayment = () => {
    setFormData((prevData) => ({
      ...prevData,
      payments: [...prevData.payments, { type: "", address: "", txid: "" }],
    }));
  };

  // Handle changes to payment information
  const handlePaymentChange = (index, event) => {
    const { name, value } = event.target;
    const updatedPayments = formData.payments.map((payment, i) =>
      i === index ? { ...payment, [name]: value } : payment
    );
    setFormData((prevData) => ({
      ...prevData,
      payments: updatedPayments,
    }));
  };

  // Add a new bank detail entry
  const addBankDetail = () => {
    setFormData((prevData) => ({
      ...prevData,
      bankDetails: [
        ...prevData.bankDetails,
        { bankName: "", bankAccount: "", amountPaid: "", datePaid: "" },
      ],
    }));
  };

  // Handle changes to bank details
  const handleBankDetailChange = (index, event) => {
    const { name, value } = event.target;
    const updatedBankDetails = formData.bankDetails.map((bank, i) =>
      i === index ? { ...bank, [name]: value } : bank
    );
    setFormData((prevData) => ({
      ...prevData,
      bankDetails: updatedBankDetails,
    }));
  };

  // Save changes to the case
  const saveChanges = async () => {
    try {
      await editCase(id, formData);
      toast.success("New case parameters added");
      navigate("/account/cases"); // Navigate away after saving
    } catch (ex) {
      toast.error("Error patching case. Try again later");
    }
  };

  return (
    <GridWrapper>
      <Box
        sx={{
          padding: 4,
          backgroundColor: "white",
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        <Typography variant="h5">Edit Case Information</Typography>

        {/* Social Links Section */}
        <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
          Add Social Links
        </Typography>
        {formData.socialLinks.map((social, index) => (
          <Box key={index} sx={{ mb: 2 }}>
            <FormControl fullWidth>
              <InputLabel>Type</InputLabel>
              <Select
                name="type"
                value={social.type}
                onChange={(e) => handleSocialChange(index, e)}
              >
                <MenuItem value="facebook">
                  {facebookIcon} <span className="margin1"></span> Facebook
                </MenuItem>
                <MenuItem value="twitter">
                  {twitterIcon} <span className="margin1"></span> Twitter
                </MenuItem>
                <MenuItem value="linkedin">
                  {linkedinIcon} <span className="margin1"></span> LinkedIn
                </MenuItem>
                <MenuItem value="instagram">
                  {instagramIcon} <span className="margin1"></span> Instagram
                </MenuItem>
                <MenuItem value="tiktok">
                  {tiktokIcon} <span className="margin1"></span> TikTok
                </MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Link"
              name="link" // Ensure proper name to differentiate inputs
              value={social.link}
              onChange={(e) => handleSocialChange(index, e)}
              fullWidth
              margin="normal"
            />
          </Box>
        ))}
        <Button variant="contained" onClick={addSocialLink}>
          + Add Social Link
        </Button>

        {/* Email Addresses Section */}
        <Typography variant="h6" sx={{ mt: 4 }}>
          Add Email Addresses
        </Typography>
        {formData.emails.map((email, index) => (
          <TextField
            key={index}
            label="Email Address"
            value={email}
            onChange={(e) => handleEmailChange(index, e)}
            fullWidth
            margin="normal"
          />
        ))}
        <Button variant="contained" onClick={addEmail}>
          + Add Email
        </Button>

        {/* Payment Information Section */}
        <Typography variant="h6" sx={{ mt: 4 }}>
          Add Payment Information
        </Typography>
        {formData.payments.map((payment, index) => (
          <Box key={index} sx={{ mb: 2 }}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Payment Type</InputLabel>
              <Select
                name="type"
                value={payment.type}
                onChange={(e) => handlePaymentChange(index, e)}
              >
                {cryptoTypes.map((crypto) => (
                  <MenuItem
                    key={crypto.value}
                    value={crypto.value}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    {crypto.logo && (
                      <img
                        src={crypto.logo}
                        alt={crypto.label}
                        style={{ width: 20, height: 20, marginRight: 8 }}
                      />
                    )}
                    {crypto.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Wallet Address"
              name="address"
              value={payment.address}
              onChange={(e) => handlePaymentChange(index, e)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Transaction ID"
              name="txid"
              value={payment.txid}
              onChange={(e) => handlePaymentChange(index, e)}
              fullWidth
              margin="normal"
            />
          </Box>
        ))}
        <Button variant="contained" onClick={addPayment}>
          + Add Payment
        </Button>

        {/* Bank Details Section */}
        <Typography variant="h6" sx={{ mt: 4 }}>
          Add Bank Details
        </Typography>
        {formData.bankDetails.map((bank, index) => (
          <Box key={index} sx={{ mb: 2 }}>
            <TextField
              label="Bank Name"
              name="bankName"
              value={bank.bankName}
              onChange={(e) => handleBankDetailChange(index, e)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Bank Account"
              name="bankAccount"
              value={bank.bankAccount}
              onChange={(e) => handleBankDetailChange(index, e)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Amount Paid"
              name="amountPaid"
              value={bank.amountPaid}
              onChange={(e) => handleBankDetailChange(index, e)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Date Paid"
              name="datePaid"
              value={bank.datePaid}
              onChange={(e) => handleBankDetailChange(index, e)}
              fullWidth
              margin="normal"
            />
          </Box>
        ))}
        <Button variant="contained" onClick={addBankDetail}>
          + Add Bank Detail
        </Button>

        {/* Save Changes Button */}
        <Button
          variant="contained"
          color="primary"
          onClick={saveChanges}
          sx={{ ml: 4 }}
        >
          Save Changes
        </Button>
      </Box>
    </GridWrapper>
  );
};

export default EditCase;
