import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export function getCases(id) {
  const token = localStorage.getItem("token");
  return axios.get(`${apiUrl}/api/admin/cases/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function updateCase(id, cases) {
  const token = localStorage.getItem("token");
  return axios.put(`${apiUrl}/api/admin/cases/${id}`, cases, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getCase(id) {
  const token = localStorage.getItem("token");
  return axios.get(`${apiUrl}/api/cases/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getInboxes(userId) {
  const token = localStorage.getItem("token");

  return axios.get(`${apiUrl}/api/admin/messages`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      userId: userId, // Pass userId as query parameter
    },
  });
}

export function sendMessage(formData, userId) {
  const token = localStorage.getItem("token");
  return axios.post(`${apiUrl}/api/admin/messages`, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      userId: userId,
    },
  });
}
