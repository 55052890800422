import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getNews,
  getArticle,
  deleteNews,
  deleteArticle,
} from "../../../services/updates";
import { toast } from "react-toastify"; // Import toast

const NewsList = () => {
  const [news, setNews] = useState([]);
  const [article, setArticle] = useState([]);
  const [shownews, setShownews] = useState(true);
  const [showart, setShowart] = useState(false);

  // Fetch news data
  useEffect(() => {
    const fetchNews = async () => {
      try {
        const { data } = await getNews();
        setNews(data);
      } catch (ex) {
        console.error("Error fetching news:", ex);
      }
    };
    fetchNews();
  }, []);

  // Fetch articles data
  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const { data } = await getArticle();
        setArticle(data);
      } catch (ex) {
        console.error("Error fetching articles:", ex);
      }
    };
    fetchArticles();
  }, []);

  // Handle deletion of news
  const handleDeleteNews = async (id) => {
    try {
      await deleteNews(id);
      setNews(news.filter((n) => n._id !== id)); // Remove from state
      toast.success("News deleted successfully"); // Correct toast method
    } catch (ex) {
      toast.error("Failed to delete news"); // Correct toast method
    }
  };

  // Handle deletion of article
  const handleDeleteArticle = async (id) => {
    try {
      await deleteArticle(id);
      setArticle(article.filter((a) => a._id !== id)); // Remove from state
      toast.success("Article deleted successfully"); // Correct toast method
    } catch (ex) {
      toast.error("Failed to delete article"); // Correct toast method
      console.log(ex);
    }
  };

  // Toggle visibility for news
  const toggleNews = () => {
    setShownews((prev) => !prev);
  };

  // Toggle visibility for articles
  const toggleArticle = () => {
    setShowart((prev) => !prev);
  };

  return (
    <div className="newslist">
      <Button onClick={toggleNews}>
        {shownews ? "Hide news" : "Show news"}
      </Button>
      {shownews && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Subject</TableCell>
              <TableCell>Date</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {news &&
              news.map((n) => (
                <TableRow key={n._id}>
                  <TableCell>{n.subject}</TableCell>
                  <TableCell>{n.date}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      sx={{ backgroundColor: "red", color: "white" }}
                      onClick={() => handleDeleteNews(n._id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      )}

      <div className="articlebox">
        <Button onClick={toggleArticle}>
          {showart ? "Hide article" : "Show article"}
        </Button>
        {showart && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Subject</TableCell>
                <TableCell>Date</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {article &&
                article.map((a) => (
                  <TableRow key={a._id}>
                    <TableCell>{a.info}</TableCell>
                    <TableCell>{a.date}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        sx={{ backgroundColor: "red", color: "white" }}
                        onClick={() => handleDeleteArticle(a._id)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        )}
      </div>
    </div>
  );
};

export default NewsList;
