import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";

import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import btclogo from "../../../../../images/logos/bitcoin.png";
import ethlogo from "../../../../../images/logos/ethereum_eth_logo_e69b1c2368.png";
import usdtlogo from "../../../../../images/logos/tether_usdt_logo_0231fbcde8.png";
import usdclogo from "../../../../../images/logos/usd_coin_usdc_logo_3e68fafa38.png";
import dodgelogo from "../../../../../images/logos/dogecoin_doge_logo_f18d59aae4.png";
import bnblogo from "../../../../../images/logos/bnb_bnb_logo_a2a64c3335.png";
import xrplogo from "../../../../../images/logos/xrp_xrp_logo_c2ba16fdd9.png";
import { Avatar } from "@mui/material";
import "./styles.css";
import { Link } from "react-router-dom";

const CaseTableCrypto = ({ paymentinfo }) => {
  const { cryptopayments } = paymentinfo;

  const getType = (type) => {
    switch (type) {
      case "BTC":
        return btclogo;
      case "ETH":
        return ethlogo;
      case "USDT":
        return usdtlogo;
      case "USDC":
        return usdclogo;
      case "DODGE":
        return dodgelogo;
      case "BNB":
        return bnblogo;
      case "XRP":
        return xrplogo;
      default:
        return null;
    }
  };
  return (
    <div className="casetable">
      <Table sx={{ minWidth: "5rem", maxWidth: 1 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cryptopayments.map((payment) => (
            <TableRow key={payment.id}>
              <TableCell component="th" scope="row">
                <div className="typeandvalue">
                  <Avatar
                    src={getType(payment.type)}
                    sx={{ width: "1.2rem", height: "1.2rem" }}
                  />
                  <h4>
                    {" "}
                    {payment.value} <span className="margin1"></span>
                    {payment.type}
                  </h4>
                </div>
              </TableCell>
              <TableRow>
                <TableCell>{payment.address}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {" "}
                  <Link to={payment.txid}> {payment.txid}</Link>
                </TableCell>
              </TableRow>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default CaseTableCrypto;
