import React from "react";
import { Typography } from "@mui/material";
import GridWrapper from "../../../../../common/GridWrapper/GridWrapper";
import locationimg from "../../../../../../assets/helpimg/location.png";
import { useMediaQuery } from "react-responsive";
import GridWrapper2 from "../../../../../common/GridWrapper/GridWrapper2";

const HelpStatus = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  if (isMobile) {
    return (
      <GridWrapper2>
        <div className="scaminfodata">
          <header>How to start a new case</header>
        </div>

        <div className="updateimgbox"></div>

        <div className="helpinfodata">
          <Typography>
            <h4>Tutorial: Understanding Status Information for Each Case</h4>

            <p>
              Welcome to this detailed guide on how to interpret the status
              information provided for each case on our fraud prevention and
              tracking platform. Our status page is an essential tool that
              offers you insights into the progress of your case, including
              preliminary tracking data and detailed reports following
              investigations.
            </p>

            <h4>Step 1: Accessing the Status Page</h4>

            <p>
              To begin understanding your case status, you need to navigate to
              the <b>Status Page</b> within our platform.
            </p>

            <ul>
              <li>Log in to your account.</li>
              <li>
                Locate the <b>Status</b> tab in the main menu and click on it.
                This will take you to a comprehensive view of your case
                statuses.
              </li>
            </ul>

            <h4>Step 2: Preliminary Tracking Data</h4>

            <p>
              The status page provides preliminary tracking data, which is
              crucial for initial insights into your case.
            </p>

            <ul>
              <li>
                This section includes information gathered from social
                engagements and payments forensics.
              </li>
              <li>
                Our team uses various tools to collect data, enhancing the
                accuracy of the information presented.
              </li>
            </ul>

            <h4>Step 3: Detailed Track Report</h4>

            <p>
              After the investigation process, you will receive a detailed track
              report that elaborates on the findings.
            </p>

            <ul>
              <li>
                The report includes extensive information derived from multiple
                investigations, allowing for a clearer understanding of the
                case.
              </li>
              <li>
                Detailed reports are vital as they provide a comprehensive view
                of the efforts made to track the suspect.
              </li>
            </ul>

            <h4>Step 4: Location Tracking</h4>

            <p>
              One of the key features of our status page is the ability to view
              the suspect's location based on IP checks.
            </p>

            <ul>
              <li>
                We conduct multiple IP checks using our dedicated IP to uncover
                location data.
              </li>
              <li>
                Our tools can identify location data even when the suspect uses
                proxies or VPNs, allowing us to pinpoint their true location
                accurately.
              </li>
              <li>
                Using data from various inputs over a short period, we can
                determine the suspect's location within approximately 50 meters.
              </li>
            </ul>
            <div className="updateimgbox">
              <img src={locationimg} alt="" />
            </div>

            <h4>Step 5: Geographical Coordinates</h4>

            <p>
              The status page also provides the geographical coordinates of
              where the scammer is operating from.
            </p>

            <ul>
              <li>
                This information is vital for recovery efforts, as it helps us
                understand the regional context of the fraud.
              </li>
              <li>
                Recovery efforts can be limited by regional laws and the
                cooperation of local law enforcement agencies, making this data
                crucial for our strategies.
              </li>
            </ul>

            <h4>
              Step 6: Understanding P<sub>K</sub> Value
            </h4>

            <p>
              Our status page includes a P<sub>K</sub> value that assesses the
              chances of success or failure for your case.
            </p>

            <ul>
              <li>
                This value helps to estimate the likelihood of recovering lost
                assets and the potential outcomes based on the collected data.
              </li>
              <li>
                We will delve deeper into the significance of the P<sub>K</sub>{" "}
                value in our next tutorial, providing you with a comprehensive
                understanding of its implications.
              </li>
            </ul>

            <h4>Conclusion</h4>

            <p>
              Understanding the status information for each case is crucial for
              effective engagement with our platform. The status page not only
              keeps you informed but also provides insights that are vital for
              the recovery process. By following the steps outlined in this
              tutorial, you can better navigate the information available and
              make informed decisions regarding your case.
            </p>

            <p>
              If you have any questions or need further assistance in
              interpreting your status information, please feel free to reach
              out through our help desk. Thank you for your commitment to making
              our community safer!
            </p>
          </Typography>
        </div>
      </GridWrapper2>
    );
  }
  return (
    <GridWrapper>
      <div className="scaminfodata">
        <header>How to start a new case</header>
      </div>

      <div className="updateimgbox"></div>

      <div className="helpinfodata">
        <Typography>
          <h4>Tutorial: Understanding Status Information for Each Case</h4>

          <p>
            Welcome to this detailed guide on how to interpret the status
            information provided for each case on our fraud prevention and
            tracking platform. Our status page is an essential tool that offers
            you insights into the progress of your case, including preliminary
            tracking data and detailed reports following investigations.
          </p>

          <h4>Step 1: Accessing the Status Page</h4>

          <p>
            To begin understanding your case status, you need to navigate to the{" "}
            <b>Status Page</b> within our platform.
          </p>

          <ul>
            <li>Log in to your account.</li>
            <li>
              Locate the <b>Status</b> tab in the main menu and click on it.
              This will take you to a comprehensive view of your case statuses.
            </li>
          </ul>

          <h4>Step 2: Preliminary Tracking Data</h4>

          <p>
            The status page provides preliminary tracking data, which is crucial
            for initial insights into your case.
          </p>

          <ul>
            <li>
              This section includes information gathered from social engagements
              and payments forensics.
            </li>
            <li>
              Our team uses various tools to collect data, enhancing the
              accuracy of the information presented.
            </li>
          </ul>

          <h4>Step 3: Detailed Track Report</h4>

          <p>
            After the investigation process, you will receive a detailed track
            report that elaborates on the findings.
          </p>

          <ul>
            <li>
              The report includes extensive information derived from multiple
              investigations, allowing for a clearer understanding of the case.
            </li>
            <li>
              Detailed reports are vital as they provide a comprehensive view of
              the efforts made to track the suspect.
            </li>
          </ul>

          <h4>Step 4: Location Tracking</h4>

          <p>
            One of the key features of our status page is the ability to view
            the suspect's location based on IP checks.
          </p>

          <ul>
            <li>
              We conduct multiple IP checks using our dedicated IP to uncover
              location data.
            </li>
            <li>
              Our tools can identify location data even when the suspect uses
              proxies or VPNs, allowing us to pinpoint their true location
              accurately.
            </li>
            <li>
              Using data from various inputs over a short period, we can
              determine the suspect's location within approximately 50 meters.
            </li>
          </ul>
          <div className="updateimgbox">
            <img src={locationimg} alt="" />
          </div>

          <h4>Step 5: Geographical Coordinates</h4>

          <p>
            The status page also provides the geographical coordinates of where
            the scammer is operating from.
          </p>

          <ul>
            <li>
              This information is vital for recovery efforts, as it helps us
              understand the regional context of the fraud.
            </li>
            <li>
              Recovery efforts can be limited by regional laws and the
              cooperation of local law enforcement agencies, making this data
              crucial for our strategies.
            </li>
          </ul>

          <h4>
            Step 6: Understanding P<sub>K</sub> Value
          </h4>

          <p>
            Our status page includes a P<sub>K</sub> value that assesses the
            chances of success or failure for your case.
          </p>

          <ul>
            <li>
              This value helps to estimate the likelihood of recovering lost
              assets and the potential outcomes based on the collected data.
            </li>
            <li>
              We will delve deeper into the significance of the P<sub>K</sub>{" "}
              value in our next tutorial, providing you with a comprehensive
              understanding of its implications.
            </li>
          </ul>

          <h4>Conclusion</h4>

          <p>
            Understanding the status information for each case is crucial for
            effective engagement with our platform. The status page not only
            keeps you informed but also provides insights that are vital for the
            recovery process. By following the steps outlined in this tutorial,
            you can better navigate the information available and make informed
            decisions regarding your case.
          </p>

          <p>
            If you have any questions or need further assistance in interpreting
            your status information, please feel free to reach out through our
            help desk. Thank you for your commitment to making our community
            safer!
          </p>
        </Typography>
      </div>
    </GridWrapper>
  );
};

export default HelpStatus;
