export const cardHeaderStyles = {
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "1.25rem",
    paddingRight: "1.25rem",
    height: "4rem",
    backgroundColor: "#f5f5f5",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  addUserButton: {
    fontSize: "1.05rem",
  },
};
