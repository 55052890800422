import React, { useState } from "react";
import { TextField, Button, Box, Typography, Paper } from "@mui/material";
import { useNavigate } from "react-router";
import { sendRaf } from "../../services/landing";

const RAF = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contactinfo: "",
    website: "",
    sociallinks: "",
    extradetails: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    try {
      await sendRaf(formData);
      console.log(formData);
    } catch (ex) {
      console.log(ex);
    }
  };

  return (
    <div className="mainlandingpage rafpage">
      <Paper
        elevation={3}
        sx={{
          padding: 3,
          backgroundColor: "white",
          maxWidth: "600px",
          margin: "auto",
        }}
      >
        <Typography variant="h5" gutterBottom>
          Help Build Our Database by Reporting Suspicious Activities
        </Typography>
        <Typography variant="body1" gutterBottom>
          Please fill out the form below to report any suspicious activity.
        </Typography>
        <form onSubmit={handleSubmit}>
          <Box mb={2}>
            <TextField
              fullWidth
              name="name"
              label="Your Name (Optional)"
              variant="outlined"
              value={formData.name}
              onChange={handleChange}
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              name="email"
              label="Email of Suspect (Optional)"
              variant="outlined"
              value={formData.email}
              onChange={handleChange}
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              name="contactinfo" // Updated field name
              label="Contact Info of Suspect (Optional)"
              variant="outlined"
              value={formData.contactinfo} // Updated state key
              onChange={handleChange}
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              name="website"
              label="Website Name (Optional)"
              variant="outlined"
              value={formData.website}
              onChange={handleChange}
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              name="sociallinks" // Updated field name
              label="Social Media Link (Optional)"
              variant="outlined"
              value={formData.sociallinks} // Updated state key
              onChange={handleChange}
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              name="extradetails" // Updated field name
              label="Extra Details (Optional)"
              variant="outlined"
              multiline
              rows={4}
              value={formData.extradetails} // Updated state key
              onChange={handleChange}
            />
          </Box>
          <div className="rafbuttons">
            <Button type="submit" variant="contained" color="primary">
              Submit Report
            </Button>
            <div>
              <Button
                type="button" // Change to 'button'
                variant="outlined"
                style={{ backgroundColor: "black" }}
                onClick={() => navigate("/")}
              >
                Back
              </Button>
            </div>
          </div>
        </form>
      </Paper>
    </div>
  );
};

export default RAF;
