import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export const deleteUser = async (id) => {
  const token = localStorage.getItem("token");

  // Handle missing token case
  if (!token) {
    throw new Error("No authentication token found. Please log in again.");
  }

  try {
    // Perform the DELETE request
    const response = await axios.delete(`${apiUrl}/api/master/users/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  } catch (error) {
    console.error("Error deleting user:", error);
    throw new Error("Failed to delete user. Please try again.");
  }
};

export function getUser(id) {
  const token = localStorage.getItem("token");
  return axios.get(`${apiUrl}/api/master/users/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function updateUser(id, user) {
  const token = localStorage.getItem("token");
  return axios.put(`${apiUrl}/api/master/users/${id}`, user, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getErrors(userId) {
  const token = localStorage.getItem("token");

  return axios.get(`${apiUrl}/api/errorlogs`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      userId: userId, // Pass userId as query parameter
    },
  });
}

export function sendError(formData, userId) {
  const token = localStorage.getItem("token");
  return axios.post(`${apiUrl}/api/errorlogs`, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      userId: userId,
    },
  });
}

export const deleteError = (id) => {
  const token = localStorage.getItem("token");

  axios.delete(`${apiUrl}/api/errorlogs/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
