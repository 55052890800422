import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";

import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const CaseTable = ({ paymentinfo }) => {
  const { bankpayments } = paymentinfo;
  return (
    <div className="casetable">
      <Table sx={{ minWidth: "40.6rem" }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bankpayments.map((payment) => (
            <TableRow key={payment.id}>
              <TableCell component="th" scope="row">
                {payment.accountnumber}
              </TableCell>
              <TableCell>{payment.bankaccount}</TableCell>
              <TableCell> {payment.amount}</TableCell>
              <TableCell>{payment.date}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default CaseTable;
