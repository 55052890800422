import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

const AnalyticsCard = () => {
  return (
    <div className="analyticscard">
      <header>Analytic Information</header>
      <div className="analysiscontent">
        <div className="analyticcontentbox">
          <article>Tracking Status</article>
          <p>
            {" "}
            <FontAwesomeIcon icon={faCheck} color="green" />
            <span className="margin1"></span>
            Cypher CoinBuster v1.32
          </p>
          <p>
            <FontAwesomeIcon icon={faCheck} color="green" />
            <span className="margin1"></span>
            Cyphertrace v1.6
          </p>
          <p>
            <FontAwesomeIcon icon={faCheck} color="green" />
            <span className="margin1"></span>
            Cyphertrace helper
          </p>
          <p>
            <FontAwesomeIcon icon={faCheck} color="green" />
            <span className="margin1"></span>
            SM Bundler pro{" "}
          </p>
          <p>
            <FontAwesomeIcon icon={faCheck} color="green" />
            <span className="margin1"></span>
            Shodan pro
          </p>
          <p>
            <FontAwesomeIcon icon={faCheck} color="green" />
            <span className="margin1"></span>
            MaxMind GeoIP pro
          </p>
          <p>
            <FontAwesomeIcon color="green" icon={faCheck} />
            <span className="margin1"></span>
            Arin<span className="margin1"></span>
            <span className="mini">
              (American registry for internent numbers)
            </span>
          </p>
          <p>
            {" "}
            <FontAwesomeIcon icon={faCheck} color="green" />
            <span className="margin1"></span>
            CypherBrute
          </p>
          <article></article>
        </div>
        <div className="analyticcontentbox">
          <article>Tracking Limitations</article>
          <p>
            <Link
              to={
                "https://www.google.com/url?sa=t&source=web&rct=j&opi=89978449&url=https://www.justice.gov/jm/jm-9-48000-computer-fraud&ved=2ahUKEwi1leCgoeOIAxXKF1kFHTI-CIwQFnoECA8QAQ&usg=AOvVaw3mWnu8rUOeFcomFKpA3-6H"
              }
            >
              Computer Fraud & Abuse Act (CFFA) [1986]
            </Link>
          </p>
          <p>
            {" "}
            <Link
              to={`https://www.google.com/url?sa=t&source=web&rct=j&opi=89978449&url=https://bja.ojp.gov/program/it/privacy-civil-liberties/authorities/statutes/1285&ved=2ahUKEwiPk-DGoeOIAxWtMlkFHRjPNcoQFnoECBgQAQ&usg=AOvVaw3twt3Hyf71Eh6aFMXolM_W`}
            >
              {" "}
              Electronic Communications Privacy Act[ECPA]{" "}
            </Link>
          </p>
          <p>
            {" "}
            <Link
              to={`https://www.google.com/url?sa=t&source=web&rct=j&opi=89978449&url=https://gdpr-info.eu/&ved=2ahUKEwjFuNegouOIAxV6EFkFHbjPEtUQFnoECBQQAQ&usg=AOvVaw1akHzzz224Oq1yU0pd6qSw`}
            >
              {" "}
              EU General Data Protection Regulation (GDPR){" "}
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsCard;
