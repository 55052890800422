import React from "react";
import previmg from "../../../assets/illustrations/undraw_Secure_server_re_8wsq.png";
import ipimg from "../../../assets/illustrations/undraw_Location_search_re_ttoj.png";
import recoveryimg from "../../../assets/illustrations/undraw_Transfer_money_re_6o1h.png";

const LandingMain = () => {
  return (
    <>
      <div className="sectionaboxa">
        <h5>Fraud Prevention Services</h5> Safeguard your organization with our
        robust fraud prevention solutions. We conduct comprehensive risk
        assessments to identify potential vulnerabilities within your
        operational framework. By implementing tailored strategies and controls,
        we empower firms to effectively mitigate risks and protect their assets,
        ensuring the integrity of their business operations.
        <img
          style={{ marginTop: "5rem" }}
          src={previmg}
          alt=""
          className="landingmainimg"
        />
      </div>
      <div className="sectionaboxb">
        <h5>IP Tracing & Forensic Accounting</h5>Our expert services combine
        sophisticated IP tracing methodologies with detailed forensic accounting
        practices. We accurately identify online fraudsters while meticulously
        examining financial transactions to uncover fraudulent activities. Our
        proficiency in tracing offshore accounts associated with LLCs provides
        firms with critical insights necessary for effective resolution of
        financial misconduct.
        <img
          style={{ marginTop: "3rem" }}
          src={ipimg}
          alt=""
          className="landingmainimg"
        />
      </div>
      <div className="sectionaboxc">
        <h5> Digital Assets Tracking & Recovery</h5>
        Navigate the intricate landscape of digital assets with our
        comprehensive tracking and recovery services. We specialize in
        identifying and analyzing suspicious activities within digital
        transactions. While we strive to recover misappropriated funds, it is
        crucial to recognize that not all digital assets may be recoverable. Our
        dedicated team is committed to maximizing recovery efforts and providing
        steadfast support throughout the process.
        <img src={recoveryimg} alt="" className="landingmainimg" />
      </div>
    </>
  );
};

export default LandingMain;
