import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import { Button } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import "../CasesComponent/styles.css";
import { useEffect } from "react";
import { getCases } from "../../../../services/cases";

const CaseList = () => {
  const navigate = useNavigate();
  const [caselist, setCaselist] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchCases = async () => {
      try {
        const response = await getCases();
        const newdata = response.data;
        setCaselist(newdata);
        console.log("caselist", caselist);
        console.log("newdata", newdata);
      } catch (err) {
        setError("Failed to fetch cases.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCases(); // Call the async function
  }, []);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <TableContainer component={Paper} className="case-table-container">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Case Name</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>
              {" "}
              {isMobile && (
                <Button onClick={() => navigate("/account/add-case")}>
                  Add
                </Button>
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {caselist &&
            caselist.map((caseItem) => (
              <TableRow key={caseItem._id} className="case-row">
                <TableCell className="case-name">{caseItem.name}</TableCell>

                <TableCell
                  className={`case-status ${
                    caseItem.status?.toLowerCase() || ""
                  }`}
                >
                  {caseItem.status}
                </TableCell>
                <TableCell className="case-target">
                  {caseItem.fraudtype}
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    size="medium"
                    onClick={() => navigate(`/account/cases/${caseItem._id}`)}
                  >
                    Open
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CaseList;
