import React from "react";
import styled from "styled-components";
import LandingHeader from "./LandingComponents/LandingHeader";
import LandingFooter from "./LandingComponents/LandingFooter";

const Blog = () => {
  const AboutCard = styled.div`
    background-color: rgba(0, 0, 0, 0.6);
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 50rem;
    margin: 2rem auto;
    font-family: "Arial", sans-serif;
    color: wheat;
    display: flex;
    flex-direction: column;
    justify-contents: center;
  `;

  // Styled component for the header
  const Header = styled.h1`
    font-size: 1.5rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #0077ff;
    background: linear-gradient(90deg, #0077ff, #00d4ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 1.5rem;
    text-align: center;

    position: relative;
    display: inline-block;
    font-weight: 800;
    padding-bottom: 0.5rem;

    /* Subtle underline effect */
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 3px;
      width: 100%;
      background-color: #0077ff;
      transform: scaleX(0);
      transform-origin: right;
      transition: transform 0.4s ease;
    }

    &:hover::after {
      transform: scaleX(1);
      transform-origin: left;
    }
  `;

  // Styled component for the paragraph
  const Paragraph = styled.p`
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 1rem;
  `;

  // Styled component for the contact section
  const ContactInfo = styled.div`
    margin-top: 1.5rem;
    padding-top: 1rem;
    border-top: 1px solid #ddd;
    text-align: center;
  `;

  // Styled component for individual contact details
  const ContactItem = styled.p`
    margin: 0.2rem 0;
    font-weight: bold;
    a {
      text-decoration: none;
      color: #0073e6;
    }
  `;
  return (
    <>
      <div className="mainlandingpage apipadding">
        <LandingHeader />

        <div className="contactinfobox">
          <AboutCard>
            <Header>Cypher Forenscis </Header>
            <Paragraph>
              Cypher is a leading cybersecurity and fraud prevention platform
              dedicated to safeguarding corporate data and assets from online
              threats. We specialize in the prevention of fraud, recovery of
              lost assets, and scam tracing, focusing particularly on wire fraud
              and business email compromise (BEC) scams.
            </Paragraph>
            <Paragraph>
              Our expert team excels in forensic accounting, advanced asset
              tracing, and scam investigation. While we provide strong recovery
              solutions in traditional finance, we offer limited support in
              crypto asset tracking. Cypher's mission is to eradicate online
              fraud and ensure the integrity of your business.
            </Paragraph>
            <ContactInfo>
              <ContactItem>
                <a href="mailto:info@cypher.com">info@cypherforensics.com</a>
              </ContactItem>
              <ContactItem>
                <a href="mailto:products@cypher.com">
                  products@cypherforensics.com
                </a>
              </ContactItem>
              <ContactItem>
                <a href="mailto:contact@cypher.com">
                  contact@cypherforensics.com
                </a>
              </ContactItem>
              <ContactItem>
                Phone: +41 43 584 19 05, +41 43 601 25 25
              </ContactItem>
              <ContactItem>
                Address: Zugerstrasse 1, 6300 Zug, Switzerland
              </ContactItem>
            </ContactInfo>
          </AboutCard>
        </div>
      </div>
      <LandingFooter />
    </>
  );
};

export default Blog;
