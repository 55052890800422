import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getCases, updateCase } from "../../../services/admin";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
  Typography,
  TextField,
  Button,
  Box,
  Checkbox,
  FormGroup,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { flags } from "./flags";

const AdminCases = () => {
  const [caseData, setCaseData] = useState([]);
  const { id } = useParams();
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedCase, setSelectedCase] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    const fetchCaseData = async () => {
      try {
        const response = await getCases(id);
        setCaseData(response.data);
      } catch (error) {
        console.error("Error fetching case data:", error);
        setError("Failed to load cases. Please try again.");
      } finally {
        setLoading(false);
      }
    };
    fetchCaseData();
  }, [id]);

  const handleChange = (event) => {
    const caseValue = event.target.value;
    setSelectedValue(caseValue);
    const casedata = caseData.find((c) => c.value === caseValue);
    setSelectedCase(casedata);
    if (casedata) {
      setFormData({ ...casedata });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePkChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      pk: {
        ...prev.pk,
        [name]: value,
      },
    }));
  };

  const handleNestedChange = (key, event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        [name]: value,
      },
    }));
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData((prev) => ({
      ...prev,
      blockchaintypes: {
        ...prev.blockchaintypes,
        [name]: checked,
      },
    }));
  };

  const handlePaymentChange = (type, index, event) => {
    const { name, value } = event.target;
    setFormData((prev) => {
      const payments = [...prev.paymentinfo[type]];
      payments[index][name] = value;
      return {
        ...prev,
        paymentinfo: {
          ...prev.paymentinfo,
          [type]: payments,
        },
      };
    });
  };

  const handleAddPayment = (type) => {
    setFormData((prev) => ({
      ...prev,
      paymentinfo: {
        ...prev.paymentinfo,
        [type]: [
          ...(prev.paymentinfo[type] || []),
          type === "cryptopayments"
            ? { address: "", txid: "", value: "", type: "" }
            : { bankaccount: "", accountnumber: "", amount: "", date: "" },
        ],
      },
    }));
  };

  const handleRemovePayment = (type, index) => {
    setFormData((prev) => ({
      ...prev,
      paymentinfo: {
        ...prev.paymentinfo,
        [type]: prev.paymentinfo[type].filter((_, i) => i !== index),
      },
    }));
  };
  const handleSocialMediaChange = (platform, index, event) => {
    const { value } = event.target;
    setFormData((prev) => {
      const links = [...prev.socialmedia[platform]];
      links[index].link = value;
      return {
        ...prev,
        socialmedia: {
          ...prev.socialmedia,
          [platform]: links,
        },
      };
    });
  };

  const handleAddSocialMediaLink = (platform) => {
    setFormData((prev) => ({
      ...prev,
      socialmedia: {
        ...prev.socialmedia,
        [platform]: [...(prev.socialmedia[platform] || []), { link: "" }],
      },
    }));
  };

  const handleRemoveSocialMediaLink = (platform, index) => {
    setFormData((prev) => ({
      ...prev,
      socialmedia: {
        ...prev.socialmedia,
        [platform]: prev.socialmedia[platform].filter((_, i) => i !== index),
      },
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedCase || !selectedCase._id) {
      alert("No case selected for update.");
      return;
    }
    try {
      await updateCase(selectedCase._id, formData);
      alert("Case updated successfully!");
    } catch (error) {
      console.error("Error updating case:", error);
      setError("Failed to update case. Please try again.");
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div className="admincases">
      {error && <Typography color="error">{error}</Typography>}
      <div className="statusheader">
        <FormControl fullWidth>
          <InputLabel id="select-case-label">Select Case</InputLabel>
          <Select
            labelId="select-case-label"
            id="select-case"
            value={selectedValue}
            label="Select Case"
            onChange={handleChange}
          >
            {caseData.map((c) => (
              <MenuItem key={c.id} value={c.value}>
                {c.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      {selectedCase && (
        <form onSubmit={handleSubmit} style={{ marginTop: "20px" }}>
          <Typography variant="h6">Edit Case</Typography>

          <TextField
            label="Case Name"
            name="name"
            value={formData.name || ""}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Status"
            name="status"
            value={formData.status || ""}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Stage"
            name="stage"
            value={formData.stage || ""}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required
          />

          <TextField
            label="Bill"
            name="bill"
            type="number"
            value={formData.bill || ""}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />

          <TextField
            label="Body"
            name="body"
            value={formData.body || ""}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            multiline
            rows={4}
          />

          <Typography variant="h6">Payment Information</Typography>
          {formData.paymentinfo?.cryptopayments?.map((payment, index) => (
            <div key={index}>
              <TextField
                label="Crypto Address"
                name="address"
                value={payment.address || ""}
                onChange={(e) =>
                  handlePaymentChange("cryptopayments", index, e)
                }
                fullWidth
                margin="normal"
              />
              <TextField
                label="Transaction ID"
                name="txid"
                value={payment.txid || ""}
                onChange={(e) =>
                  handlePaymentChange("cryptopayments", index, e)
                }
                fullWidth
                margin="normal"
              />
              <TextField
                label="Crypto Value"
                name="value"
                type="number"
                value={payment.value || ""}
                onChange={(e) =>
                  handlePaymentChange("cryptopayments", index, e)
                }
                fullWidth
                margin="normal"
              />
              <TextField
                label="Type"
                name="type"
                value={payment.type || ""}
                onChange={(e) =>
                  handlePaymentChange("cryptopayments", index, e)
                }
                fullWidth
                margin="normal"
              />
              <IconButton
                color="red"
                onClick={() => handleRemovePayment("cryptopayments", index)}
              >
                <RemoveIcon color="red" />
              </IconButton>
            </div>
          ))}
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => handleAddPayment("cryptopayments")}
          >
            Add Crypto Payment
          </Button>

          {formData.paymentinfo?.bankpayments?.map((payment, index) => (
            <div key={index}>
              <TextField
                label="Bank Account"
                name="bankaccount"
                value={payment.bankaccount || ""}
                onChange={(e) => handlePaymentChange("bankpayments", index, e)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Account Number"
                name="accountnumber"
                type="number"
                value={payment.accountnumber || ""}
                onChange={(e) => handlePaymentChange("bankpayments", index, e)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Amount"
                name="amount"
                value={payment.amount || ""}
                onChange={(e) => handlePaymentChange("bankpayments", index, e)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Date"
                name="date"
                type="date"
                value={payment.date ? payment.date.split("T")[0] : ""}
                onChange={(e) => handlePaymentChange("bankpayments", index, e)}
                fullWidth
                margin="normal"
              />
              <IconButton
                onClick={() => handleRemovePayment("bankpayments", index)}
              >
                <RemoveIcon color="red" />
              </IconButton>
            </div>
          ))}
          <Button
            variant="contained"
            style={{ marginLeft: "1rem" }}
            startIcon={<AddIcon />}
            onClick={() => handleAddPayment("bankpayments")}
          >
            Add Bank Payment
          </Button>

          <Typography variant="h6">Blockchain Types</Typography>
          <FormGroup>
            {["btc", "eth", "sol", "dodge"].map((type) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.blockchaintypes?.[type] || false}
                    onChange={handleCheckboxChange}
                    name={type}
                  />
                }
                label={type.toUpperCase()}
                key={type}
              />
            ))}
          </FormGroup>

          <Typography variant="h6">Track Info</Typography>
          {Object.keys(formData.trackinfo || {}).map((key) => (
            <TextField
              key={key}
              label={key
                .replace(/([A-Z])/g, " $1")
                .replace(/^./, (str) => str.toUpperCase())}
              name={key}
              value={formData.trackinfo[key] || ""}
              onChange={(e) => handleNestedChange("trackinfo", e)}
              fullWidth
              margin="normal"
            />
          ))}

          <Typography variant="h6">Geodata</Typography>
          {Object.keys(formData.geodata || {}).map((key) => (
            <TextField
              key={key}
              label={key
                .replace(/([A-Z])/g, " $1")
                .replace(/^./, (str) => str.toUpperCase())}
              name={key}
              value={formData.geodata[key] || ""}
              onChange={(e) => handleNestedChange("geodata", e)}
              fullWidth
              margin="normal"
            />
          ))}

          <Typography variant="h6">PK Information</Typography>
          <TextField
            label="Boosters"
            name="boosters"
            value={(formData.pk?.boosters || []).join(", ")}
            onChange={(e) =>
              handlePkChange({
                target: {
                  name: "boosters",
                  value: e.target.value.split(", "),
                },
              })
            }
            fullWidth
            margin="normal"
          />
          <TextField
            label="Antagonist"
            name="antagonist"
            value={(formData.pk?.antagonist || []).join(", ")}
            onChange={(e) =>
              handlePkChange({
                target: {
                  name: "antagonist",
                  value: e.target.value.split(", "),
                },
              })
            }
            fullWidth
            margin="normal"
          />
          <TextField
            label="PK Value"
            name="value"
            value={formData.pk?.value || ""}
            onChange={(e) =>
              handlePkChange({
                target: {
                  name: "value",
                  value: e.target.value,
                },
              })
            }
            fullWidth
            margin="normal"
          />
          <TextField
            label="Change"
            name="change"
            value={formData.pk?.change || ""}
            onChange={(e) =>
              handlePkChange({
                target: {
                  name: "change",
                  value: e.target.value,
                },
              })
            }
            fullWidth
            margin="normal"
          />
          <Typography variant="h6">Social Media Links</Typography>
          {["facebook", "twitter", "linkedin", "instagram", "tiktok"].map(
            (platform) => (
              <div key={platform}>
                <Typography variant="subtitle1">{platform}</Typography>
                {formData.socialmedia?.[platform]?.map((social, index) => (
                  <div key={index}>
                    <TextField
                      label={`${platform} Link`}
                      value={social.link}
                      onChange={(e) =>
                        handleSocialMediaChange(platform, index, e)
                      }
                      fullWidth
                      margin="normal"
                    />
                    <IconButton
                      onClick={() =>
                        handleRemoveSocialMediaLink(platform, index)
                      }
                    >
                      <RemoveIcon />
                    </IconButton>
                  </div>
                ))}
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={() => handleAddSocialMediaLink(platform)}
                >
                  Add {platform} Link
                </Button>
              </div>
            )
          )}

          <div>
            {" "}
            <article>Classname and geoclassname help</article>
            <select>
              {flags.map(([country, flagClass]) => (
                <option key={flagClass} value={flagClass}>
                  <span style={{ color: "black" }} className={flagClass}></span>{" "}
                  {`country: ${country}, flagclass: ${flagClass}`}
                </option>
              ))}
            </select>
          </div>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ marginTop: "20px" }}
          >
            Update Case
          </Button>
        </form>
      )}
    </div>
  );
};

export default AdminCases;
