import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export function getNews() {
  const token = localStorage.getItem("token");
  return axios.get(`${apiUrl}/api/news`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
export function getArticle() {
  const token = localStorage.getItem("token");
  return axios.get(`${apiUrl}/api/articles`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function addNews(news) {
  const token = localStorage.getItem("token");
  return axios.post(`${apiUrl}/api/news`, news, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function addArticle(article) {
  const token = localStorage.getItem("token");
  return axios.post(`${apiUrl}/api/articles`, article, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getUpdate(id) {
  const token = localStorage.getItem("token");
  return axios.get(`${apiUrl}/api/news/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function deleteNews(id) {
  const token = localStorage.getItem("token");
  return axios.delete(`${apiUrl}/api/news/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
export function deleteArticle(id) {
  const token = localStorage.getItem("token");
  return axios.delete(`${apiUrl}/api/articles/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
