import HelpIcon from "@mui/icons-material/Help";
import GpsNotFixedIcon from "@mui/icons-material/GpsNotFixed";
import ConstructionIcon from "@mui/icons-material/Construction";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import LogoutIcon from "@mui/icons-material/Logout";
import ApiIcon from "@mui/icons-material/Api";
export const mainNavHelpItems = [
  {
    id: 0,
    icon: <HelpIcon />,
    label: "Help Desk",
    route: "help-desk",
  },
  {
    id: 1,
    icon: <GpsNotFixedIcon />,
    label: "Fraud Tips",
    route: "fraudtips",
  },
  {
    id: 3,
    icon: <ConstructionIcon />,
    label: "Cypher Tools",
    route: "cyphertools",
  },

  {
    id: 4,
    icon: <ApiIcon />,
    label: "Requests",
    route: "requests",
  },
  {
    id: 5,
    icon: <WorkHistoryIcon />,
    label: "History",
    route: "history",
  },
];
