import React from "react";
import "./styles.css";
import { Link } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { getCases } from "../../../../services/cases";
import { useState, useEffect } from "react";

const CypherToolsMain = ({ errors }) => {
  const [caselist, setCaselist] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchCases = async () => {
      try {
        const response = await getCases();
        const newdata = response.data;
        setCaselist(newdata);
        console.log("caselist", caselist);
        console.log("newdata", newdata);
      } catch (err) {
        setError("Failed to fetch cases.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCases();
  }, []);

  const casestatus = caselist.some((c) => {
    if (c.status === "Active" || c.status === "Pending") {
      let casestatus = true;
      return casestatus;
    }
  });

  return (
    <div>
      <div className="cyphertoolsmain">
        <div className="cypherbox">
          <article>Cypher Tools</article>
          <p>Cypher CoinBuster v1.32</p>
          <p>Cyphertrace v1.6</p>
          <p>Cyphertrace helper</p>
          <p>SM Bundler pro</p>
          <p>CypherBrute</p>
        </div>
        <div className="cypherbox">
          <article>Paid Subscriptions</article>
          <p>
            {" "}
            <Link to="https://www.shodan.io">Shodan pro</Link>
          </p>
          <p>
            {" "}
            <Link to="https://www.maxmind.com/en/locate-my-ip-address">
              MaxMind GeoIP pro
            </Link>
          </p>
          <p>
            <Link to="https://www.arin.net">
              Arin(American registry for internent numbers)
            </Link>
          </p>
        </div>
      </div>
      <div className="cyphertoolsstatus">
        <article>Tool State</article>
        <Table className="toolstable">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="right">Hash Id</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Cypher CoinBuster v1.32</TableCell>
              <TableCell align="right">
                {casestatus ? (
                  <p style={{ color: "green" }}>
                    {" "}
                    <FontAwesomeIcon
                      icon={faCircle}
                      color="green"
                      size="2xs"
                      beat
                    />
                    <span className="margin1"></span>
                    Active
                  </p>
                ) : (
                  <p style={{ color: "red" }}>
                    {" "}
                    <FontAwesomeIcon icon={faCircle} size="2xs" beat />
                    <span className="margin1"></span>
                    inactive
                  </p>
                )}
              </TableCell>
              <TableCell align="right">
                {" "}
                {casestatus ? "#091002609876543" : "N|A"}{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>CypherTrace v1.6</TableCell>
              <TableCell align="right">
                {casestatus ? (
                  <p style={{ color: "green" }}>
                    {" "}
                    <FontAwesomeIcon
                      icon={faCircle}
                      color="green"
                      size="2xs"
                      beat
                    />
                    <span className="margin1"></span>
                    Active
                  </p>
                ) : (
                  <p style={{ color: "red" }}>
                    {" "}
                    <FontAwesomeIcon icon={faCircle} size="2xs" beat />
                    <span className="margin1"></span>
                    inactive
                  </p>
                )}
              </TableCell>
              <TableCell align="right">
                {" "}
                {casestatus ? "#E1006700-10D3002024" : "N|A"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>CypherTrace Helper</TableCell>
              <TableCell align="right">
                {casestatus ? (
                  <p style={{ color: "green" }}>
                    {" "}
                    <FontAwesomeIcon
                      icon={faCircle}
                      color="green"
                      size="2xs"
                      beat
                    />
                    <span className="margin1"></span>
                    Active
                  </p>
                ) : (
                  <p style={{ color: "red" }}>
                    {" "}
                    <FontAwesomeIcon icon={faCircle} size="2xs" beat />
                    <span className="margin1"></span>
                    inactive
                  </p>
                )}
              </TableCell>
              <TableCell align="right">
                {" "}
                {casestatus ? "#091002609876543" : "N|A"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>SM Bundler pro</TableCell>
              <TableCell align="right">
                {casestatus ? (
                  <p style={{ color: "green" }}>
                    {" "}
                    <FontAwesomeIcon
                      icon={faCircle}
                      color="green"
                      size="2xs"
                      beat
                    />
                    <span className="margin1"></span>
                    Active
                  </p>
                ) : (
                  <p style={{ color: "red" }}>
                    {" "}
                    <FontAwesomeIcon icon={faCircle} size="2xs" beat />
                    <span className="margin1"></span>
                    inactive
                  </p>
                )}
              </TableCell>
              <TableCell align="right">
                {" "}
                {casestatus ? "#011-C0010-DE3005101DE" : "N|A"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>CypherBrute</TableCell>
              <TableCell align="right">
                {casestatus ? (
                  <p style={{ color: "green" }}>
                    {" "}
                    <FontAwesomeIcon
                      icon={faCircle}
                      color="green"
                      size="2xs"
                      beat
                    />
                    <span className="margin1"></span>
                    Active
                  </p>
                ) : (
                  <p style={{ color: "red" }}>
                    {" "}
                    <FontAwesomeIcon icon={faCircle} size="2xs" beat />
                    <span className="margin1"></span>
                    inactive
                  </p>
                )}
              </TableCell>
              <TableCell align="right"> N|A</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Shodan Pro</TableCell>
              <TableCell align="right">
                {casestatus ? (
                  <p style={{ color: "green" }}>
                    {" "}
                    <FontAwesomeIcon
                      icon={faCircle}
                      color="green"
                      size="2xs"
                      beat
                    />
                    <span className="margin1"></span>
                    Active
                  </p>
                ) : (
                  <p style={{ color: "red" }}>
                    {" "}
                    <FontAwesomeIcon icon={faCircle} size="2xs" beat />
                    <span className="margin1"></span>
                    inactive
                  </p>
                )}
              </TableCell>
              <TableCell align="right">
                {casestatus ? (
                  <Link to="https://account.shodan.io/login?continue=https%3A%2F%2Faccount.shodan.io%2Fbilling%2Fmember">
                    #CE0100120500-DE0112024
                  </Link>
                ) : (
                  "N|A"
                )}{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>MaxMind GeoIP pro</TableCell>
              <TableCell align="right">
                {casestatus ? (
                  <p style={{ color: "green" }}>
                    {" "}
                    <FontAwesomeIcon
                      icon={faCircle}
                      color="green"
                      size="2xs"
                      beat
                    />
                    <span className="margin1"></span>
                    Active
                  </p>
                ) : (
                  <p style={{ color: "red" }}>
                    {" "}
                    <FontAwesomeIcon icon={faCircle} size="2xs" beat />
                    <span className="margin1"></span>
                    inactive
                  </p>
                )}
              </TableCell>
              <TableCell align="right">
                {" "}
                {casestatus ? (
                  <Link to="https://www.maxmind.com/en/account">
                    {casestatus ? "" : "N|A"}
                    #000-001-00-0CE11001
                  </Link>
                ) : (
                  "N|A"
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>

      <div className="errorlogs">
        <article>Error Logs</article>
        {errors && errors.length ? (
          errors.map((e) => (
            <p>
              {" "}
              <span>
                <FontAwesomeIcon icon={faCircle} size="2xs" color="red" />
              </span>
              <span className="margin1"></span>
              {e.errorlog}
            </p>
          ))
        ) : (
          <div style={{ padding: "1rem", color: "rgba(0,0,0,0.5)" }}>
            No errors logged{" "}
          </div>
        )}
      </div>
    </div>
  );
};

export default CypherToolsMain;
