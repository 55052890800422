import React from "react";
import "./styles.css";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid2";
import ScamInfoSide from "../../ScamInfo/ScamInfoComponents/ScamInfoSide";
import { scamupdates } from "../../../consts/scampudates";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import { useMediaQuery } from "react-responsive";

const HelpDeskMain = () => {
  const linkStyle = {
    textDecoration: "none",
    color: "rgba(0,0,0,0.6)",
  };

  const helpItems = [
    {
      title: `Adding a new case`,
      link: "/account/help-desk/newcase",
    },
    {
      title: `Editing an old case`,
      link: `/account/help-desk/editcase`,
    },
    {
      title: `Understanding case status information`,
      link: "/account/help-desk/help-status",
    },
    {
      title: `Understanding case analytic information`,
      link: "/account/help-desk/help-analytics",
    },
    {
      title: `Tutorial on outcome probability (Pk and recovery)`,
      link: "/account/help-desk/help-outcome",
    },
    {
      title: `Understanding boosters and antagonists`,
      link: "/account/help-desk/help-boosters&antagonists",
    },
    {
      title: `Case confidentiality during investigations`,
      link: "/account/help-desk/help-secure",
    },
  ];
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  return (
    <Grid container>
      <Grid size={isMobile ? 12 : 8}>
        <div className="scaminfo">
          <header>Help Desk</header>{" "}
          {helpItems.map((h, index) => (
            <Link
              className="fraudinfolink"
              style={linkStyle}
              key={index}
              to={h.link}
            >
              <h5>
                <div className="helpinfohead">
                  <HelpCenterIcon color="action" />
                  <span className="margin1"></span>
                  {h.title}
                </div>
              </h5>
            </Link>
          ))}
        </div>
      </Grid>
      <Grid size={4}>
        <div className="scaminfoside">
          <header>Popular Updates</header>

          {scamupdates.map((u) => (
            <ScamInfoSide
              key={u.id}
              sidelinks={`/scaminfo/${u.id}`}
              sidelinkstitle={u.subject}
            />
          ))}
        </div>
      </Grid>
    </Grid>
  );
};

export default HelpDeskMain;
