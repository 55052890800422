import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBitcoin } from "@fortawesome/free-brands-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import {
  faHeartCrack,
  faBuildingColumns,
  faCreditCard,
  faMoneyBillTransfer,
} from "@fortawesome/free-solid-svg-icons";
import "./styles.css";
import SocialInputs from "../../Cases/CasesComponent/SocialInputs";
import {
  faSquareInstagram,
  faSquareXTwitter,
  faLinkedin,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

const StatusTarget = ({ fraudtype, socialmedia, target }) => {
  const getFraudType = () => {
    if (fraudtype === "Cryptocurrency Fraud")
      return (
        <div>
          <FontAwesomeIcon color={"orange"} icon={faBitcoin} /> Cryptocurrency
          Fraud
        </div>
      );
    else if (fraudtype === "Bank Fraud")
      return (
        <div>
          <FontAwesomeIcon icon={faBuildingColumns} color={"grey"} />{" "}
          Banking/Wire Fraud
        </div>
      );
    else if (fraudtype === "Romance Fraud")
      return (
        <div>
          <FontAwesomeIcon icon={faHeartCrack} color={"red"} /> Romance Fraud
        </div>
      );
    else if (fraudtype === "Credit Fraud")
      return (
        <div>
          <FontAwesomeIcon icon={faCreditCard} color={"gold"} /> Credit Card
          Fraud
        </div>
      );
    else if (fraudtype === "Investment Fraud")
      return (
        <div>
          <FontAwesomeIcon icon={faMoneyBillTransfer} color={"green"} />{" "}
          Investment/Forex Fraud
        </div>
      );
    else return null;
  };

  const facebookIcon = <FontAwesomeIcon color="#1877F2" icon={faFacebook} />;
  const instagramIcon = (
    <FontAwesomeIcon color="#DD2A7B" icon={faSquareInstagram} />
  );
  const twitterIcon = <FontAwesomeIcon color="black" icon={faSquareXTwitter} />;
  const linkedinIcon = <FontAwesomeIcon color="#0077B5" icon={faLinkedin} />;
  const tiktokIcon = <FontAwesomeIcon color="black" icon={faTiktok} />;
  const { facebook, twitter, instagram, tiktok, linkedin } = socialmedia;
  const socialsize = "large";

  return (
    <div className="statustarget">
      <header>Forensic Target Details</header>
      <div className="statustargetbox">
        <div>
          <article>Target</article>
          <p>{target}</p>
        </div>
        <div>
          <article>Fraud Type</article>
          <p>{getFraudType()}</p>
        </div>
      </div>
      <header>Tracked Social Media Accounts </header>
      <div className="trackedlinks">
        {facebook &&
          facebook.map((v) => (
            <a
              key={v.id}
              href={v.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialInputs
                value={v.link}
                socialIcon={facebookIcon}
                size={socialsize}
              />
            </a>
          ))}
        {instagram &&
          instagram.map((v) => (
            <a
              href={v.link}
              key={v.id}
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialInputs
                value={v.link}
                socialIcon={instagramIcon}
                size={socialsize}
              />
            </a>
          ))}
        {linkedin &&
          linkedin.map((v) => (
            <a
              href={v.link}
              key={v.id}
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <SocialInputs
                value={v.link}
                socialIcon={linkedinIcon}
                size={socialsize}
              />
            </a>
          ))}
        {twitter &&
          twitter.map((v) => (
            <a
              key={v.id}
              href={v.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialInputs
                value={v.link}
                socialIcon={twitterIcon}
                size={socialsize}
              />
            </a>
          ))}
        {tiktok &&
          tiktok.map((v) => (
            <a
              key={v.id}
              href={v.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialInputs
                value={v.link}
                socialIcon={tiktokIcon}
                size={socialsize}
              />
            </a>
          ))}
      </div>
    </div>
  );
};

export default StatusTarget;
