import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import LandingHeader from "./LandingHeader";
import LandingFooter from "./LandingFooter";

const API = () => {
  const [value, setValue] = React.useState(0);
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <div className="mainlandingpage apipadding">
        <LandingHeader />
        <div className="apiwrapperbox">
          <h2 style={{ color: "wheat" }}>
            Boost your company’s security with our reliable scam detection APIs.
            Protect your customers and elevate your reputation.
          </h2>
          <div className="apiwrapper">
            {" "}
            <Box
              sx={{ maxWidth: { xs: 320, sm: 480 } }}
              className="apitabshead"
            >
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons={false}
                aria-label="scrollable prevent tabs example"
              >
                <Tab label="Data Feed" style={{ color: "wheat" }} />
                <Tab label="Scam & Brand Alerts" style={{ color: "wheat" }} />
                <Tab
                  label="Brand Monitoring Service"
                  style={{ color: "wheat" }}
                />
                <Tab
                  label="Domain Trust Score API"
                  style={{ color: "wheat" }}
                />

                <Tab
                  label="Online Brand Protection"
                  style={{ color: "wheat" }}
                />
              </Tabs>
            </Box>
            <Box
              sx={{ maxWidth: { xs: 320, sm: 480 } }}
              className="apitabsbody"
            >
              {" "}
              <TabPanel value={value} index={0}>
                <article style={{ color: "white" }}>
                  <h5>Access to Cypher Forensics Dataset</h5>
                  <p>
                    For antivirus providers, cybersecurity companies, and
                    malware detection organizations, we offer seamless access to
                    the Cypher Forensics comprehensive dataset via a live feed.
                    An update file is generated every 30 minutes, reflecting the
                    latest changes, which are then compiled into hourly and
                    daily files.
                  </p>

                  <h6>Each file comprises:</h6>
                  <ul>
                    <li>
                      Newly scanned and updated websites from the past 24 hours
                    </li>
                    <li>An MD5 checksum for authenticity</li>
                    <li>Domain name information</li>
                    <li>
                      Trust score ranging from 0 to 100, along with explanations
                      for each score
                    </li>
                    <li>Full WHOIS data</li>
                    <li>Consolidated consumer reviews</li>
                    <li>Comments from Facebook</li>
                    <li>Technologies utilized on the domains</li>
                  </ul>

                  <p>
                    The data is formatted in JSON. Pricing is determined by the
                    specific usage of the data feed.
                  </p>
                </article>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <article>
                  <h5>Cypher Alerts and Reports</h5>
                  <p>
                    With over 50k websites added each month, Cypher delivers
                    daily, weekly, and ad hoc reports to law enforcement,
                    brands, and brand protection agencies on newly registered
                    websites.
                  </p>

                  <h6>Customizable Alerts</h6>
                  <p>
                    Alerts can be tailored to be as generic or specific as
                    needed. Examples include:
                  </p>
                  <ul>
                    <li>
                      Which domains were added this week using my brand name?
                    </li>
                    <li>
                      Which French sites are offering “research chemicals”?
                    </li>
                    <li>
                      Which hosting companies in the Netherlands have the most
                      scammers?
                    </li>
                  </ul>

                  <p>
                    Our alerts are fully customizable to suit your requirements,
                    and you can easily create and set new alerts using our
                    Domain Analyzer.
                  </p>
                </article>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <article>
                  <h5>Domain Trust Score API</h5>
                  <p>
                    The Cypher Trust Score API offers a quick and efficient way
                    for businesses to assess the trustworthiness of a domain,
                    seamlessly integrating into their Know Your Customer (KYC)
                    risk assessment process.
                  </p>

                  <p>
                    All you need is the website's address. With this single
                    input, Cypher handles the analysis and provides you with a
                    custom Trust Score.
                  </p>

                  <p>
                    Our algorithm evaluates any online (sub)domain, weighing
                    both positive and negative factors to determine its
                    reliability. Additionally, Cypher can notify you of any
                    changes in the Trust Score, allowing you to manage and
                    monitor your risk after approving the website.
                  </p>

                  <h6>Who Benefits from the Cypher Trust Score API?</h6>
                  <p>Various types of companies can leverage our API:</p>
                  <ul>
                    <li>
                      <strong>Advertising & Social Media Platforms:</strong> For
                      social media platforms, affiliate networks, or display
                      advertising systems, our Trust Score API helps quickly
                      identify potentially fraudulent advertisers.
                    </li>
                    <li>
                      <strong>Financial Services Providers:</strong> Several
                      Know Your Business (KYB) service providers and Payment
                      Service Providers (PSPs) have integrated the Cypher API as
                      an additional data point in their verification processes.
                    </li>
                    <li>
                      <strong>Internet Service Providers:</strong> Whether you
                      offer payment solutions, email marketing services, or
                      other online offerings, aiding a fraudulent website can
                      lead to chargebacks, refund requests, or legal action from
                      customers.
                    </li>
                  </ul>

                  <p>
                    Cypher empowers you to determine how to evaluate a website
                    based on the factors that matter most to you. Pricing is
                    based on the number of API calls made.
                  </p>
                </article>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <article>
                  <h5>Brand Monitoring Service</h5>
                  <p>
                    We monitor all advertising and sales activities on Facebook
                    and Instagram related to your brand, identifying fake
                    webshops. Additionally, we can swiftly and easily facilitate
                    the removal of ads on these platforms for you.
                  </p>

                  <p>
                    Our service also enables you to track sales offers and
                    sellers globally on Facebook Marketplace using various
                    search parameters, including keyword and image searches. You
                    can take action against specific infringements or entire
                    accounts as needed.
                  </p>
                </article>
              </TabPanel>
              <TabPanel value={value} index={4}>
                <article>
                  <h5>Online Brand Protection</h5>
                  <p>
                    Benefit from our comprehensive app monitoring service for
                    your brand:
                  </p>

                  <ul>
                    <li>Monitoring of over 30 app stores worldwide</li>
                    <li>
                      Automated tracking of various app types based on keywords
                    </li>
                    <li>
                      Evaluation of unauthorized developers and contractors
                      acting without your permission
                    </li>
                    <li>
                      Identification of replicated, phishing versions of
                      official apps
                    </li>
                    <li>
                      Monitoring the latest versions of your apps to identify
                      security vulnerabilities and ensure a consistent corporate
                      identity
                    </li>
                    <li>
                      Quick and easy takedowns or comprehensive enforcement
                      programs
                    </li>
                  </ul>
                </article>
              </TabPanel>
            </Box>
          </div>
        </div>
      </div>
      <LandingFooter />
    </>
  );
};

export default API;
