import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import Loader2 from "../dashcomponents/pages/Loaders/Loader2";

const AuthEmail = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const display = () => {
      setLoading(true);
    };

    display();
  }, []);

  const returnNow = () => {
    setTimeout(() => {
      toast.error("Something failed");
      setLoading(false);
      navigate("/reset-password");
    }, [10000]);
  };

  if (loading) {
    return (
      <div className="mainlandingpage">
        {" "}
        <Loader2 />
        {returnNow()}
      </div>
    );
  }
};

export default AuthEmail;
