import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GridWrapper from "../../../../common/GridWrapper/GridWrapper2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTags } from "@fortawesome/free-solid-svg-icons";
import { faCalendarDays } from "@fortawesome/free-regular-svg-icons";
import { useMediaQuery } from "react-responsive";
import { getUpdate } from "../../../../services/updates";
import Loader3 from "../../Loaders/Loader4";
import { Typography } from "@mui/material";

const ScamInfoData = () => {
  const [news, setNews] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  useEffect(() => {
    const fetchMessage = async () => {
      try {
        const response = await getUpdate(id);
        setNews(response.data);
      } catch (error) {
        console.error("Error fetching case data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMessage();
  }, [id]);

  if (loading) {
    return <Loader3 />;
  }

  if (!news) {
    return <div className="scaminfodata">Update not found.</div>;
  }

  const { subject, body, tags, date, img } = news || {};

  if (isMobile) {
    return (
      <GridWrapper>
        <div className="scaminfodatacover">
          <div className="scaminfodata">
            <header>{subject}</header>
            <div className="tagdatebox">
              <div className="tagbox">
                <FontAwesomeIcon color="inherit" icon={faTags} />
                <span className="margin1"></span>

                {tags.join(", ")}
              </div>
              <div className="infodatebox">
                <FontAwesomeIcon icon={faCalendarDays} color="inherit" />{" "}
                <span className="margin1"></span>
                {new Date(date).toLocaleDateString()}
              </div>
            </div>
          </div>
          <div className="updateimgbox">
            <img src={img} alt="image test" />{" "}
          </div>
          <article className="updatebody">
            <div className="updatenewsbody">
              <Typography variant="body1" component="div">
                <div dangerouslySetInnerHTML={{ __html: body }} />
              </Typography>
            </div>
          </article>
        </div>
      </GridWrapper>
    );
  }

  return (
    <>
      <div className="scaminfodata">
        <header>{subject}</header>
        <div className="tagdatebox">
          <div className="tagbox">
            <FontAwesomeIcon color="inherit" icon={faTags} />
            <span className="margin1"></span>
            {tags.join(", ")}
          </div>
          <div className="infodatebox">
            <FontAwesomeIcon icon={faCalendarDays} color="inherit" />{" "}
            <span className="margin1"></span>
            {new Date(date).toLocaleDateString()}
          </div>
        </div>
      </div>
      <div className="updateimgbox">
        <img src={img} alt="image test" />{" "}
      </div>
      <article className="updatebody">
        <div className="updatenewsbody">{body}</div>
      </article>
    </>
  );
};

export default ScamInfoData;
