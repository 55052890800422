import React from "react";
import Grid from "@mui/material/Grid2";
import { gridWrapperStyles } from "./styles2";

const GridWrapper = ({ children, color }) => {
  return (
    <Grid item xs={12} sx={gridWrapperStyles}>
      {children}
    </Grid>
  );
};

export default GridWrapper;
