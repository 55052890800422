import locationimg from "../../../assets/helpimg/location.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import product2 from "../../../assets/landingimg/background/product2.png";
import smjpg from "../../../assets/landingimg/background/sm.jpg";
import analyticsimg from "../../../assets/helpimg/analyticslocation.png";
import {
  faArrowRightLong,
  faChartSimple,
  faMagnifyingGlassDollar,
  faMagnifyingGlassLocation,
  faSquareShareNodes,
} from "@fortawesome/free-solid-svg-icons";

export const products = [
  {
    id: 0,
    head: (
      <h2>
        {" "}
        <FontAwesomeIcon icon={faMagnifyingGlassLocation} />
        <span> </span>True Location Tracking
      </h2>
    ),
    body: (
      <p>
        Bypass VPNs and proxies to uncover the true locations of fraud suspects
        using Cypher-Trace and social engineering constructs
      </p>
    ),
    src: locationimg,
  },
  {
    id: 1,
    head: (
      <h2>
        {" "}
        <FontAwesomeIcon icon={faMagnifyingGlassDollar} />
        <span> </span>Precision Forensics
      </h2>
    ),
    body: (
      <p>
        Digital Asset Tracking leveraging on-chain analytics with CypherBrute to
        gather significant wallet address identifiers for tracing fraudulent
        actors
      </p>
    ),
    src: product2,
  },
  {
    id: 2,
    head: (
      <h2>
        <FontAwesomeIcon icon={faSquareShareNodes} />
        <span> </span>Social Forensics
      </h2>
    ),
    body: (
      <p>
        Social Media Forensics leveraging SM Bundler to trace and group accounts
        linked to suspected fraudsters across eight major platforms
      </p>
    ),
    src: smjpg,
  },
  {
    id: 3,
    head: (
      <h2>
        <FontAwesomeIcon icon={faChartSimple} />
        <span> </span>Real-time Analytics
      </h2>
    ),
    body: (
      <p>
        Case Monitoring Hub offering users a comprehensive analytics page to
        track their case status and progress in real time.
      </p>
    ),
    src: analyticsimg,
  },
];
