import React, { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { mainNavBarItems } from "./consts/navBarItems"; // Adjust the path as necessary
import { mainNavHelpItems } from "./consts/navHelp"; // Adjust the path as necessary
import { mobilenavbarStyles } from "./styles";
import { KeyboardArrowDown } from "@mui/icons-material";
import { Box } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import { NavLink } from "react-router-dom";
import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";
import { getInboxProp } from "../services/inbox";

const MobileNavbar = ({ handleDrawer, mobileOpen }) => {
  const [open, setOpen] = useState(true);
  const [open1, setOpen1] = useState(true);
  const [messageCount, setMessageCount] = useState(10);
  const fetchMessages = async () => {
    try {
      const response = await getInboxProp();
      setMessageCount(response.data.count);
    } catch (ex) {
      console.error("Failed to fetch inbox:", ex);
    }
  };

  useEffect(() => {
    fetchMessages();

    const intervalId = setInterval(() => {
      fetchMessages();
    }, 6000);

    return () => clearInterval(intervalId);
  }, []);

  const badgeNo = messageCount;

  const updatedNavBarItems = mainNavBarItems.map((item) => {
    if (item.id === 1) {
      return {
        ...item,
        icon:
          badgeNo > 0 ? (
            <Badge badgeContent={badgeNo} color="primary">
              <MailIcon />
            </Badge>
          ) : (
            <MailIcon />
          ),
      };
    }
    return item;
  });

  return (
    <>
      <Drawer
        variant="temporary" // Temporary variant that overlays content
        open={mobileOpen}
        onClose={handleDrawer}
        sx={mobilenavbarStyles.drawer}
      >
        <Toolbar />
        <Divider />
        <Box
          sx={[
            open ? { bgcolor: null } : { bgcolor: null },
            open ? { pb: 2 } : { pb: 0 },
          ]}
        >
          <h1 className="logoh1">Cypher</h1>
          <ListItemButton
            alignItems="flex-start"
            onClick={() => setOpen(!open)}
            sx={[
              { px: 3, pt: 2.5 },
              open ? { pb: 0 } : { pb: 2.5 },
              open
                ? { "&:hover, &:focus": { "& svg": { opacity: 1 } } }
                : { "&:hover, &:focus": { "& svg": { opacity: 0 } } },
            ]}
          >
            <ListItemText
              primary="Case Management"
              primaryTypographyProps={{
                fontSize: `0.75rem`,
                fontWeight: "medium",
                lineHeight: "20px",
                mb: "2px",
              }}
              secondary="Home, Inbox, Cases, Status, Analytics and Scam info"
              secondaryTypographyProps={{
                noWrap: true,
                fontSize: "0.5rem",
                lineHeight: "16px",
                color: open ? "rgba(0,0,0,0)" : "rgba(255,255,255,0.5)",
              }}
              sx={{ my: 0 }}
            />
            <KeyboardArrowDown
              sx={[
                { mr: -1, opacity: 0, transition: "0.2s" },
                open
                  ? { transform: "rotate(-180deg)" }
                  : { transform: "rotate(0)" },
              ]}
            />
          </ListItemButton>
          <List>
            {open &&
              updatedNavBarItems.map((item) => (
                <NavLink
                  to={item.route}
                  key={item.id}
                  style={({ isActive }) => ({
                    textDecoration: "none",
                    color: isActive ? "white" : "inherit",
                    backgroundColor: isActive ? "#1976d2" : "transparent", // Highlight color
                  })}
                >
                  {({ isActive }) => (
                    <ListItem button>
                      <ListItemIcon
                        sx={{
                          color: isActive ? "white" : "inherit", // Change icon color based on active state
                        }}
                      >
                        {React.cloneElement(item.icon, {
                          style: { color: isActive ? "white" : "inherit" }, // Change icon color based on active state
                        })}
                      </ListItemIcon>
                      <ListItemText
                        sx={mobilenavbarStyles.text}
                        primary={item.label}
                      />
                    </ListItem>
                  )}
                </NavLink>
              ))}
          </List>
        </Box>
        <Divider />
        <Box
          sx={[
            open1 ? { bgcolor: null } : { bgcolor: null },
            open1 ? { pb: 2 } : { pb: 0 },
          ]}
        >
          <ListItemButton
            alignItems="flex-start"
            onClick={() => setOpen1(!open1)}
            sx={[
              { px: 3, pt: 2.5 },
              open1 ? { pb: 0 } : { pb: 2.5 },
              open1
                ? { "&:hover, &:focus": { "& svg": { opacity: 1 } } }
                : { "&:hover, &:focus": { "& svg": { opacity: 0 } } },
            ]}
          >
            <ListItemText
              primary="Helper Tools"
              primaryTypographyProps={{
                fontSize: `0.75rem`,
                fontWeight: "medium",
                lineHeight: "20px",
                mb: "2px",
              }}
              secondary="Pk, Help desk, Cypher tools, History, Log out"
              secondaryTypographyProps={{
                noWrap: true,
                fontSize: 12,
                lineHeight: "16px",
                color: open1 ? "rgba(0,0,0,0)" : "rgba(255,255,255,0.5)",
              }}
              sx={{ my: 0 }}
            />
            <KeyboardArrowDown
              sx={[
                { mr: -1, opacity: 0, transition: "0.2s" },
                open1
                  ? { transform: "rotate(-180deg)" }
                  : { transform: "rotate(0)" },
              ]}
            />
          </ListItemButton>
          <List>
            {open1 &&
              mainNavHelpItems.map((item) => (
                <NavLink
                  to={item.route}
                  key={item.id}
                  style={({ isActive }) => ({
                    textDecoration: "none",
                    color: isActive ? "white" : "inherit",
                    backgroundColor: isActive ? "#1976d2" : "transparent", // Highlight color
                  })}
                >
                  {({ isActive }) => (
                    <ListItem button>
                      <ListItemIcon
                        sx={{
                          color: isActive ? "white" : "inherit", // Change icon color based on active state
                        }}
                      >
                        {React.cloneElement(item.icon, {
                          style: { color: isActive ? "white" : "inherit" }, // Change icon color based on active state
                        })}
                      </ListItemIcon>
                      <ListItemText
                        sx={mobilenavbarStyles.text}
                        primary={item.label}
                      />
                    </ListItem>
                  )}
                </NavLink>
              ))}
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default MobileNavbar;
