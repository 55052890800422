import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export function registerUser(user) {
  const userData = {
    firstName: user.firstName || user.businessName || "",
    lastName: user.lastName || "",
    email: user.email || user.businessEmail || "",
    phone: user.phone || user.businessPhone || "",
    country: user.country || "",
    password: user.password,
    isFirm: false,
  };

  // Check if business fields are provided
  if (
    user.businessName ||
    user.businessEmail ||
    user.businessPhone ||
    user.businessLocation
  ) {
    userData.isFirm = true; // Set isFirm to true if any business details are provided
  }

  return axios.post(`${apiUrl}/api/users`, userData);
}

export function sendRaf(form) {
  return axios.post(`${apiUrl}/api/raf`, form);
}

export function addEmail(email) {
  return axios.post(`${apiUrl}/api/newsletter`, email);
}
export function getEmails() {
  return axios.get(`${apiUrl}/api/newsletter`);
}
