import React, { useState } from "react";
import { Box, TextField, Button, Typography } from "@mui/material";
import GridWrapper from "../../../common/GridWrapper/GridWrapper";
import { addNews, addArticle } from "../../../services/updates";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

const News = () => {
  const [newsData, setNewsData] = useState({
    subject: "",
    tags: "",
    preview: "",
    body: "",
    image: "",
    date: "",
  });

  const [articleData, setArticleData] = useState({
    body: "",
    link: "",
  });

  const navigate = useNavigate();

  // Handle changes for news form fields
  const handleNewsChange = (e) => {
    const { name, value } = e.target;
    setNewsData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle changes for article form fields
  const handleArticleChange = (e) => {
    const { name, value } = e.target;
    setArticleData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle news submission
  const submitNews = async () => {
    try {
      await addNews(newsData);
      toast.success("News added");
    } catch (ex) {
      toast.error("Error saving news");
    }
  };

  // Handle article submission
  const submitArticle = async () => {
    try {
      console.log(articleData);

      await addArticle(articleData);
      toast.success("Article added");
    } catch (ex) {
      toast.error("Error saving article");
    }
  };

  return (
    <GridWrapper>
      <Box sx={{ padding: 4, backgroundColor: "white", borderRadius: 2 }}>
        <Button
          variant="contained"
          style={{ marginBottom: "2rem" }}
          onClick={() => navigate("/admin/news/list")}
        >
          News and Articles
        </Button>
        <Typography variant="h5">Post News</Typography>

        {/* News Form Fields */}
        <Box sx={{ mt: 2 }}>
          <TextField
            label="News Subject"
            name="subject"
            value={newsData.subject}
            onChange={handleNewsChange}
            fullWidth
            margin="normal"
          />

          <TextField
            label="News Tags"
            name="tags"
            value={newsData.tags}
            onChange={handleNewsChange}
            fullWidth
            margin="normal"
          />

          <TextField
            label="News Preview"
            name="preview"
            value={newsData.preview}
            onChange={handleNewsChange}
            fullWidth
            margin="normal"
          />

          <TextField
            label="News Body"
            name="body"
            value={newsData.body}
            onChange={handleNewsChange}
            fullWidth
            margin="normal"
            multiline
            rows={4}
          />

          <TextField
            label="Image URL"
            name="image"
            value={newsData.image}
            onChange={handleNewsChange}
            fullWidth
            margin="normal"
          />

          <TextField
            label="News Date"
            name="date"
            value={newsData.date}
            onChange={handleNewsChange}
            type="date"
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
          />

          <Button
            variant="contained"
            color="primary"
            onClick={submitNews}
            sx={{ mt: 2 }}
          >
            Submit News
          </Button>
        </Box>

        <Typography variant="h5" sx={{ mt: 4 }}>
          Post Article Links
        </Typography>

        {/* Article Form Fields */}
        <Box sx={{ mt: 2 }}>
          <TextField
            label="Article Info"
            name="info"
            value={articleData.info}
            onChange={handleArticleChange}
            fullWidth
            margin="normal"
          />

          <TextField
            label="Article Link"
            name="link"
            value={articleData.link}
            onChange={handleArticleChange}
            fullWidth
            margin="normal"
          />

          <Button
            variant="contained"
            color="primary"
            onClick={submitArticle}
            sx={{ mt: 2 }}
          >
            Submit Article
          </Button>
        </Box>
      </Box>
    </GridWrapper>
  );
};

export default News;
