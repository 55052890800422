import {
  faCircle,
  faClock,
  faHashtag,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Typography } from "@mui/material";
import React from "react";
import cbrute from "../../../../../assets/cypherbrute.jpeg";
import btclogo from "../../../../../images/logos/bitcoin.png";
import dodgelogo from "../../../../../images/logos/dogecoin_doge_logo_f18d59aae4.png";
import ethlogo from "../../../../../images/logos/ethereum_eth_logo_e69b1c2368.png";

import sollogo from "../../../../../images/logos/solana-sol-logo.png";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";

const AnalyticsForensics = ({ blockchaintype, pk, date }) => {
  const getPkType = (value) => {
    if (value <= 0.2) {
      return { color: "darkred" };
    } else if (value <= 0.4) {
      return { color: "rgb(200,0,0)" };
    } else if (value === 0.5) {
      return { color: "inherit" };
    } else if (value > 0.5 && value < 0.8) {
      return { color: "darkgreen" };
    } else if (value >= 0.8) {
      return { color: "rgb(0,200,0)" };
    }
  };

  const { btc, eth, sol, dodge } = blockchaintype || {};
  const { boosters, antagonist, value, change } = pk || {};
  function convertDateToMMDDYY(dateString) {
    const date = new Date(dateString);

    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2);
    return `${month}/${day}/${year}`;
  }

  const pkstyle = getPkType(value);
  const getChangeType = (change) => {
    if (change.startsWith("-")) {
      return { color: "red" };
    } else if (change.startsWith("+")) {
      return { color: "green" };
    }
    return { color: "black" };
  };
  const changestyle = getChangeType(change);
  const calculateDays = (date) => {
    const givenDate = new Date(date.split("/").reverse().join("-"));
    const today = new Date();
    const timeDifference = today - givenDate;
    return Math.floor(timeDifference / (1000 * 3600 * 24));
  };
  return (
    <div className="analyticsforensics">
      <header>Forensic Analysis</header>
      <div className="analyticsforensicsbox">
        <div className="analyticsbox">
          <article style={{ marginLeft: "2rem" }} className="cypherbruteimg">
            CypherBrute <img src={cbrute} alt="" />
          </article>
          <p className="cbrutestatus">ACTIVE</p>
          <article style={{ marginLeft: "2rem" }}>Blockchain Type </article>

          {btc && (
            <h4 className="analyticscryptotype">
              <Avatar
                src={btclogo}
                sx={{ width: "1.2rem", height: "1.2rem" }}
              />{" "}
              <span className="margin1"></span>
              Bitcoin Network (SHA-256 Hashing)
            </h4>
          )}

          {eth && (
            <h4 className="analyticscryptotype">
              <Avatar
                src={ethlogo}
                sx={{ width: "1.2rem", height: "1.2rem" }}
              />{" "}
              <span className="margin1"></span>
              Ethereum Network (Keccak-256 Hashing)
            </h4>
          )}

          {dodge && (
            <h4 className="analyticscryptotype">
              <Avatar
                src={dodgelogo}
                sx={{ width: "1.2rem", height: "1.2rem" }}
              />{" "}
              <span className="margin1"></span>
              Dodge Network (Scrypt Hashing)
            </h4>
          )}

          {sol && (
            <h4 className="analyticscryptotype">
              <Avatar
                src={sollogo}
                sx={{ width: "1.2rem", height: "1.2rem" }}
              />{" "}
              <span className="margin1"></span>
              Solana Network (SHA-256 Hashing)
            </h4>
          )}
        </div>
        <div className="analyticsbox">
          <article>
            <FontAwesomeIcon icon={faHashtag} />{" "}
            <span className="margin1"></span>
            HashID
          </article>
          <p className="forensicsp">NA</p>
          <article className="analyticsaddress">Target Address Type(s)</article>
          <p className="forensicsp">P2PKH (Legacy)</p>
        </div>
      </div>
      <header>
        P<sub>K</sub> <span>(Probability of Kill)</span>
      </header>
      <div className="pokbox">
        <div className="pokboosters">
          <article>Boosters</article>
          {boosters.map((b, index) => (
            <p key={index}>
              {" "}
              <FontAwesomeIcon color={"green"} size="xs" icon={faCircle} />{" "}
              <span className="margin1"></span>
              {b}
            </p>
          ))}
        </div>
        <div className="pokantagonist">
          <article>Antagonist</article>

          {antagonist.map((a, index) => (
            <p key={index}>
              {" "}
              <FontAwesomeIcon color={"red"} size="xs" icon={faCircle} />{" "}
              <span className="margin1"></span>
              {a}
            </p>
          ))}
        </div>
      </div>
      <div className="pokmain">
        <article>
          P<sub>K</sub>
        </article>
        <div className="pkdisplay">
          {value && (
            <p style={pkstyle}>
              {" "}
              {value !== "NA" ? (
                `${value}/1`
              ) : (
                <p
                  style={{
                    color: "rgba(0,0,0,0.5",
                    fontWeight: "200",
                    fontSize: "1rem",
                  }}
                >
                  Not yet available
                </p>
              )}{" "}
            </p>
          )}

          <h5 style={changestyle}>{change}</h5>
        </div>
      </div>{" "}
      <div className="pkmainlecture">
        {pk.value <= 0.2 && pk.value == 0.1 && (
          <div>
            <Typography style={{ color: "darkred" }}>
              P<sub>k</sub> score is too low due to insufficient data or
              cypher-trace setbacks. Add more data (social links, transaction
              hx, images or documents) to boost your chances.
            </Typography>
          </div>
        )}
        {pk.value <= 0.4 && pk.value > 0.2 && (
          <div>
            <Typography style={{ color: "rgb(200,0,0)" }}>
              We have a very low probability of success due to insufficient data
              or early trace setbacks. Add more data (social links, transaction
              hx, images or documents) to boost your chances
            </Typography>
          </div>
        )}

        {pk.value == 0.5 && (
          <div>
            <Typography>
              We have an average probability of success. You can boost our
              chances even further by adding more data (social links,
              transaction hx, images or documents)
            </Typography>
          </div>
        )}

        {pk.value > 0.5 && pk.value < 0.8 && (
          <div>
            <Typography style={{ color: "darkgreen" }}>
              We have a good chance at success. While extra data is not
              required, we can boost our chances even more with more data(social
              links, transaction hx, images or documents)
            </Typography>
          </div>
        )}

        {pk.value >= 0.8 && (
          <div>
            <Typography style={{ color: "rgb(0,200,0)" }}>
              We have an extremely high probability of success within an even
              shorter timeframe
            </Typography>
          </div>
        )}
      </div>
      <header style={{ marginTop: "5rem" }}>Package Duration</header>
      <div className="analyticsflexbox">
        <div className="analyticsdate">
          <article>
            <FontAwesomeIcon icon={faCalendar} />{" "}
            <span className="margin11"></span>
            Date Started
          </article>
          <p>{convertDateToMMDDYY(date)}</p>
        </div>
        <div className="analyticsnewdate">
          <article>
            <FontAwesomeIcon icon={faClock} />{" "}
            <span className="margin11"></span>
            Package Duration
          </article>
          <p style={{ color: "rgba(250,0,0,0.6)" }}>
            {calculateDays(date)} days{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsForensics;
