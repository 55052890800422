import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import CaseDataSide from "./CaseDataSide";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBitcoin } from "@fortawesome/free-brands-svg-icons";
import {
  faHeartCrack,
  faBuildingColumns,
  faCreditCard,
  faMoneyBillTransfer,
} from "@fortawesome/free-solid-svg-icons";
import CaseCard from "./CaseCard";
import CaseDataMobile from "./CaseDataMobile";
import { getCase } from "../../../../services/cases";
import { useMediaQuery } from "react-responsive";
import Loader4 from "../../Loaders/Loader4";

const CaseData = () => {
  const [caseData, setCaseData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    const fetchCaseData = async () => {
      try {
        const response = await getCase(id); // Assuming getCase returns the case directly
        setCaseData(response.data); // Set case data directly
      } catch (error) {
        console.error("Error fetching case data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCaseData(); // Call the async function
  }, [id]);

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const {
    socialmedia,
    fraudtype,
    tracktype,
    geodata,
    paymentinfo,
    status,
    datestarted,
    name,
  } = caseData || {};
  console.log(caseData, "casedata");

  const getFraudType = () => {
    if (!fraudtype) return null;
    switch (fraudtype) {
      case "Cryptocurrency Fraud":
        return (
          <div>
            <FontAwesomeIcon color={"orange"} icon={faBitcoin} /> Cryptocurrency
            Fraud
          </div>
        );
      case "Bank Fraud":
        return (
          <div>
            <FontAwesomeIcon icon={faBuildingColumns} color={"grey"} />{" "}
            Banking/Wire Fraud
          </div>
        );

      case "Romance Fraud":
        return (
          <div>
            <FontAwesomeIcon icon={faHeartCrack} color={"red"} /> Romance Fraud
          </div>
        );
      case "Credit Fraud":
        return (
          <div>
            <FontAwesomeIcon icon={faCreditCard} color={"gold"} /> Credit Card
            Fraud
          </div>
        );
      case "Investment Fraud":
        return (
          <div>
            <FontAwesomeIcon icon={faMoneyBillTransfer} color={"green"} />{" "}
            Investment/Forex Fraud
          </div>
        );
      default:
        return null;
    }
  };

  if (isMobile) {
    return <CaseDataMobile />;
  }

  // Handle loading state
  if (loading) {
    return <Loader4 />;
  }

  return (
    <Grid container>
      <Grid item xs={8}>
        <CaseCard
          fraudtype={getFraudType()}
          paymentinfo={paymentinfo}
          date={datestarted}
          name={name}
          id={id}
        />
      </Grid>
      <Grid item xs={4} className="grid2">
        <CaseDataSide
          socialmedia={socialmedia}
          tracktype={tracktype}
          fraudtype={getFraudType()}
          trackinfo={geodata}
          status={status}
        />
      </Grid>
    </Grid>
  );
};

export default CaseData;
