import React, { useEffect, useState } from "react";
import GridWrapper from "../../../common/GridWrapper/GridWrapper";
import CypherToolsMain from "./CypherToolsComponents/CypherToolsMain";
import { useMediaQuery } from "react-responsive";
import GridWrapper2 from "../../../common/GridWrapper/GridWrapper2";
import { getErrors } from "../../../services/master";
import { getUserMe } from "../../../services/user";

const CypherTools = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const [errors, setErrors] = useState([]);
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await getUserMe();
        const newdata = response.data;
        setUser(newdata);
      } catch (err) {
        setError("Failed to fetch User.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, []);

  useEffect(() => {
    const fetchErrors = async () => {
      try {
        const { data } = await getErrors(user._id);
        setErrors(data);
      } catch (ex) {}
    };
    fetchErrors();
  });

  if (isMobile) {
    return (
      <GridWrapper2>
        {" "}
        <CypherToolsMain errors={errors} />
      </GridWrapper2>
    );
  }
  return (
    <GridWrapper>
      <CypherToolsMain errors={errors} />
    </GridWrapper>
  );
};

export default CypherTools;
