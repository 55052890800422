import React from "react";
import Grid from "@mui/material/Grid";
import GridWrapper from "../../../common/GridWrapper/GridWrapper";
import MainStatus from "./StatusComponents/MainStatus";
import StatusMobile from "./StatusComponents/StatusMobile";
import { useMediaQuery } from "react-responsive";
import { getCases } from "../../../services/cases";
import { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import Loader2 from "../Loaders/Loader2";

function Status() {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [caselist, setCaselist] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchCases = async () => {
      try {
        const response = await getCases();
        const newdata = response.data;
        setCaselist(newdata);
        console.log("caselist", caselist);
        console.log("newdata", newdata);
      } catch (err) {
        setError("Failed to fetch cases.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCases(); // Call the async function
  }, []);
  if (loading) return <Loader2 />;

  if (caselist.length == 0) {
    return (
      <Typography
        align="center"
        sx={{
          margin: "40px 16px",
          color: "rgba(0, 0, 0, 0.6)",
          fontSize: "1.3rem",
        }}
      >
        No active cases
      </Typography>
    );
  }

  if (isMobile) {
    return <StatusMobile caselist={caselist} />;
  }
  return (
    <GridWrapper>
      <MainStatus caselist={caselist} />
    </GridWrapper>
  );
}

export default Status;
