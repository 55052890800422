import { Box, Button, Container, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { toast } from "react-toastify";
import Loader4 from "../dashcomponents/pages/Loaders/Loader4";
import LandingHeader from "./LandingComponents/LandingHeader";
import { useNavigate } from "react-router";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  return (
    <>
      <div className="mainlandingpage ">
        {" "}
        <LandingHeader />
        <div className="resetpassword">
          <div className="resetpasswordbox">
            <Container maxWidth="xs">
              <Box
                sx={{
                  marginTop: 8,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography component="h5" variant="h5">
                  Search account via email
                </Typography>

                {/* Current Password Input */}

                {/* New Password Input */}
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  label="Email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  sx={{ color: "white" }}
                  style={{ color: "white", backgroundColor: "white" }}
                />

                {/* Confirm New Password Input */}

                {/* Reset Password Button */}
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={() => navigate("/auth-email")}
                >
                  Confirm
                </Button>
              </Box>
            </Container>
            );
          </div>
        </div>
      </div>
      ;
    </>
  );
};

export default ResetPassword;
