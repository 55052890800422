import { Typography } from "@mui/material";
import React from "react";
import LandingFooter from "./LandingFooter";

const Disclaimer = () => {
  return (
    <>
      <div className="disclaimerbox">
        <div className="disclaimerinfo">
          <header>Disclaimer</header>
          <Typography>
            {" "}
            <article>
              The information and services provided by Cypher are intended for
              educational and informational purposes only. While we strive to
              offer accurate, reliable, and up-to-date content related to fraud
              prevention and corporate security, we make no guarantees regarding
              the completeness, reliability, or suitability of this information
              for any particular purpose. Users are advised to seek professional
              advice tailored to their specific situations. <br /> <br /> Cypher
              specializes in the prevention of corporate fraud and the
              protection of corporate secrets. Our range of services includes
              domain analysis, brand protection, brand app monitoring, and
              forensic tracking. Although we utilize advanced technologies and
              methodologies in our services, the effectiveness and outcomes may
              vary based on individual circumstances, external factors, and the
              dynamic nature of fraud schemes. Therefore, we cannot guarantee
              specific results from our services.
              <br /> <br />
              <br /> In addition to our fraud prevention offerings, we provide
              limited cryptocurrency tracking services based on outcome
              probability. It is important to note that we reserve the right to
              decline any crypto-related cases that we determine exceed our
              operational capabilities or expertise. Users should not rely
              solely on our services for financial decisions or investigations
              and are encouraged to conduct their own due diligence.
              <br /> <br /> Cypher is committed to maintaining the privacy and
              security of our clients; however, we cannot guarantee the complete
              security of any data transmitted over the internet or through our
              platforms. By using our services, you acknowledge that any
              information you provide may be subject to breaches and that we are
              not liable for any such occurrences. Cypher shall not be liable
              for any direct, indirect, incidental, or consequential damages
              arising from the use or inability to use our services, or any
              reliance on information provided by us. This includes, but is not
              limited to, damages for loss of profits, goodwill, use, data, or
              other intangible losses, even if we have been advised of the
              possibility of such damages. By accessing or using our services,
              you acknowledge that you have read, understood, and agreed to this
              disclaimer. If you do not agree with any part of this disclaimer,
              you should refrain from using our services. For any questions or
              concerns regarding our services or this disclaimer, please contact
              us directly.
            </article>
          </Typography>
        </div>
      </div>
      <LandingFooter />
    </>
  );
};

export default Disclaimer;
