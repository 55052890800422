export const navbarStyles = {
  drawer: {
    width: "100%",
    maxWidth: "100%",
    backgroundColor: "red",
    flexShrink: 1,
    "& .MuiDrawer-paper": {
      boxSizing: "border-box",
      backgroundColor: "#101F33",
      color: "rgba(255, 255, 255, 0.7)",
      width: "20%",
    },
    "& .Mui-selected": {
      color: "red",
    },
  },
  icons: {
    marginLeft: "20px",
  },
  text: {
    "& span": {
      marginLeft: "-10px",
      fontWeight: "600",
      fontSize: "1rem",
    },
  },
};

export const mobilenavbarStyles = {
  drawer: {
    width: "100%",
    flexShrink: 1,
    "& .MuiDrawer-paper": {
      width: "40%",
      boxSizing: "border-box",
      backgroundColor: "rgba(16, 31, 51, 0.9)",
      color: "rgba(255, 255, 255, 0.7)",
    },
    "& .Mui-selected": {
      color: "red",
    },
  },
  icons: {
    marginLeft: "20px",
  },
  text: {
    "& span": {
      marginLeft: "-10px",
      fontWeight: "600",
      fontSize: "1rem",
      opacity: 1,
    },
  },
};
