import React from "react";
import "./styles.css";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";

const SocialInputs = ({ value, socialIcon, size }) => {
  return (
    <div className="sidelisttargetinput">
      <Input
        size={size}
        id="input-with-icon-adornment"
        value={value}
        style={{ width: "100%", height: "2rem" }}
        disabled
        startAdornment={
          <InputAdornment position="start">{socialIcon}</InputAdornment>
        }
      />
    </div>
  );
};

export default SocialInputs;
