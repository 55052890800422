import { Avatar } from "@mui/material";
import React from "react";
import { patchInbox } from "../../../../services/inbox";

const InboxWideMobile = ({
  sender,
  subject,
  body,
  date,
  src,
  onClick,
  isNew,
}) => {
  function formatMessageDate(dateString) {
    const now = new Date();
    const messageDate = new Date(dateString);
    const elapsedTime = now - messageDate;

    const seconds = Math.floor(elapsedTime / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);

    if (seconds < 60) {
      return `${seconds} sec${seconds !== 1 ? "s" : ""}`;
    } else if (minutes < 60) {
      return `${minutes} min${minutes !== 1 ? "s" : ""}`;
    } else if (hours < 24) {
      return `${hours} hr${hours !== 1 ? "s" : ""}`;
    } else if (days < 7) {
      return `${days} day${days !== 1 ? "s" : ""}`;
    } else if (weeks < 5) {
      return `${weeks} wk${weeks !== 1 ? "s" : ""}`;
    } else {
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      return messageDate.toLocaleDateString(undefined, options);
    }
  }

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }
  return (
    <div className="inboxwidemobile" onClick={onClick}>
      <div className="inboxwidemobileavatar">
        <div style={{ textTransform: "capitalize" }}>
          <Avatar {...stringAvatar(`${sender}`)} />
        </div>
      </div>
      <div className="inboxwidemobilecontent">
        <div
          className="inboxmobilename"
          style={{ fontWeight: isNew ? "bold" : "normal" }}
        >
          {sender}
        </div>
        <div
          className="inboxmobilesubject"
          style={{ fontWeight: isNew ? "bold" : "normal" }}
        >
          {subject}
        </div>
        <div className="inboxmobiledetail">
          <span>{body}</span>
        </div>
      </div>
      <div className="inboxwidemobiledate">{formatMessageDate(date)}</div>
    </div>
  );
};

export default InboxWideMobile;
