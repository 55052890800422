import React, { useEffect, useState } from "react";

import { Button, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { products } from "./products";
import {
  faArrowRightLong,
  faMagnifyingGlassLocation,
} from "@fortawesome/free-solid-svg-icons";
import locationimg from "../../../assets/helpimg/location.png";
import { useNavigate } from "react-router";

const LandingProductCard = () => {
  const navigate = useNavigate();
  const [currentProductIndex, setCurrentProductIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentProductIndex((prevIndex) => (prevIndex + 1) % products.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const currentProduct = products[currentProductIndex];

  return (
    <div className="landingbox">
      <div className="landingboxcontent">
        {currentProduct.head}
        <div className="landingboxcontentdetails">
          <Typography>{currentProduct.body}</Typography>
          <div className="landingproductimg">
            {" "}
            <img
              src={currentProduct.src}
              className="landingproductimg"
              alt=""
            />
          </div>
        </div>
        <div className="landingcontentbtn">
          <Button
            sx={{ fontSize: "1.2rem" }}
            variant="outlined"
            size="large"
            onClick={() => navigate("/register")}
          >
            Get Started <span style={{ marginRight: "1rem" }}> </span>{" "}
            <FontAwesomeIcon icon={faArrowRightLong} beatFade />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LandingProductCard;
