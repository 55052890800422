import React from "react";
import { Typography } from "@mui/material";
import GridWrapper from "../../../../../common/GridWrapper/GridWrapper";
import addcaseimg from "../../../../../../assets/helpimg/addcase.png";
import socialimg from "../../../../../../assets/helpimg/socials.png";
import confirmimg from "../../../../../../assets/helpimg/confirmimg.png";
import { useMediaQuery } from "react-responsive";
import GridWrapper2 from "../../../../../common/GridWrapper/GridWrapper2";
const HelpNewCases = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  if (isMobile) {
    return (
      <GridWrapper2>
        {" "}
        <div className="scaminfodata">
          <header>How to start a new case</header>
        </div>
        <div className="updateimgbox"></div>
        <div className="helpinfodata">
          <Typography>
            <h4>Tutorial: How to Add a New Case</h4>

            <p>
              Welcome to our comprehensive tutorial on how to add a new case to
              our fraud prevention and tracking platform. This guide will walk
              you through the process step-by-step, ensuring you understand how
              to effectively report fraudulent activities and contribute to the
              fight against scams.
            </p>

            <h4>Overview</h4>

            <p>
              Adding a new case is an essential feature of our platform that
              allows users to report incidents of fraud and initiate the
              recovery process. The process is designed to be user-friendly and
              straightforward. By providing detailed information about the
              fraud, you enable our team to track and trace lost funds
              effectively. Let’s dive into the process.
            </p>

            <h4>Step 1: Navigating to the Case Addition Section</h4>

            <h5>1.1 Access the Home Tab</h5>
            <p>
              Start by navigating to the <b>Home</b> tab on our platform. This
              is where you’ll find the main navigation options available to you.
              The Home tab is your starting point for most activities on our
              site.
            </p>

            <h5>1.2 Click on the Add Button</h5>
            <p>
              Once you’re on the Home tab, look for the <b>Add Case</b> button.
              This button is typically located above the section displaying
              existing cases. Clicking this will direct you to the case
              submission page.
            </p>

            <h5>1.3 Alternative Access</h5>
            <p>
              Alternatively, you can also click on the <b>Add Case</b> button
              located at the top of your navigation menu. This is a quick access
              point for initiating a new case submission from anywhere on the
              site.
            </p>

            <div className="imgbox updateimgbox">
              <img src={addcaseimg} alt="" />
            </div>

            <h4>Step 2: Inputting General Information</h4>

            <p>
              Adding a new case is broken down into several steps, starting with
              the <b>General Information</b> section. This step is crucial as it
              provides the foundational data for your report.
            </p>

            <h5>2.1 Entering Suspect Information</h5>
            <ul>
              <li>
                <b>Suspect Name</b>: Input the name of the suspected scammer.
                This should be the name they used during the fraudulent
                activity. If you have multiple names associated with the
                suspect, include all relevant aliases to improve tracking.
              </li>
            </ul>

            <h5>2.2 Selecting the Fraud Type</h5>
            <ul>
              <li>
                <b>Fraud Type</b>: Choose the type of fraud that best describes
                the incident. Our platform categorizes various types of fraud,
                such as romance scams, investment fraud, and cryptocurrency
                scams. Understanding the type of fraud is vital since recovery
                chances can vary. For instance, romance scams often yield better
                recovery results than cryptocurrency scams due to their
                semi-anonymous nature.
              </li>
            </ul>

            <h5>2.3 Reporting Financial Loss</h5>
            <ul>
              <li>
                <b>Amount Lost</b>: Enter the total value of assets lost due to
                the fraudulent activity. This should be the most accurate figure
                you can provide, as it helps us prioritize cases based on
                severity.
              </li>
            </ul>

            <h5>2.4 Document Upload</h5>
            <ul>
              <li>
                <b>Documents Upload</b>: It’s essential to support your case
                with evidence. Upload any documents, images, or chat
                conversations that prove the fraudulent activity. This
                information will be verified as part of our fact-checking
                process and is crucial for recovering lost assets.
              </li>
            </ul>

            <div className="imgbox updateimgbox">
              <img src={socialimg} alt="" />
            </div>

            <h4>Step 3: Social Engagement</h4>

            <p>
              The second stage involves collecting social engagement
              information, which is vital for tracking and tracing the suspect.
            </p>

            <h5>3.1 Enter Known Contact Information</h5>
            <ul>
              <li>
                <b>Email</b>: Provide any known email addresses associated with
                the suspect. This could be the email they used to communicate
                with you or any emails linked to the fraudulent activity.
              </li>
              <li>
                <b>Phone Number</b>: Enter any known phone numbers belonging to
                the suspect. This can include numbers used for contact or those
                appearing in correspondence.
              </li>
            </ul>

            <h5>3.2 Website Information</h5>
            <ul>
              <li>
                <b>Website Information</b>: If applicable, input the URL of any
                websites connected to the suspect. This could be a site where
                the fraud occurred or one that the suspect owns.
              </li>
            </ul>

            <h5>3.3 Additional Information</h5>
            <ul>
              <li>
                <b>Additional Info</b>: Use this section to provide any other
                pertinent details that may assist in the investigation. This
                could include unusual behaviors, patterns of communication, or
                any other observations.
              </li>
            </ul>

            <h5>3.4 Social Media Links</h5>
            <ul>
              <li>
                <b>Social Links</b>: Include links to the suspect's social media
                profiles or any accounts that were involved in the fraud.
                Providing at least one social link is mandatory, as it
                significantly enhances our ability to track and trace the
                suspect’s activities.
              </li>
            </ul>

            <h5>3.5 Utilizing SM Bundler</h5>
            <ul>
              <li>
                If you have multiple valid social media links, our{" "}
                <b>SM Bundler</b> tool can help aggregate and identify other
                associated accounts. This tool uses the information you provide
                to uncover more about the suspect, increasing the likelihood of
                successful recovery.
              </li>
            </ul>

            <h4>Step 4: Payment Forensics</h4>

            <p>
              The final stage of adding a new case is focused on payment
              forensics. This section involves tracing financial transactions
              related to the fraud.
            </p>

            <h5>4.1 Forensic Accounting</h5>
            <ul>
              <li>
                <b>Payments Forensics</b>: This involves detailing any
                transactions that occurred as part of the fraud. Providing
                transaction IDs, payment methods, and any other financial data
                is critical for uncovering information about the scammer.
              </li>
            </ul>

            <h5>4.2 On-Chain Analysis</h5>
            <ul>
              <li>
                <b>CypherBrute Tool</b>: For cases involving cryptocurrency, we
                have a specialized tool called <b>CypherBrute</b>. This tool is
                used for on-chain analysis and can help monitor suspected crypto
                wallets. Please note that CypherBrute is limited to certain
                blockchains, so ensure that your case fits within those
                parameters.
              </li>
            </ul>
            <div className="imgbox updateimgbox">
              <img src={confirmimg} alt="" />
            </div>

            <h4>Step 5: Confirming Your Case</h4>

            <h5>5.1 Review Your Information</h5>
            <p>
              Before confirming, take a moment to review all the information
              you’ve entered. Ensure that it is accurate and complete, as this
              will directly impact the success of your case.
            </p>

            <h5>5.2 Click on the Confirm Button</h5>
            <p>
              Once you are satisfied with the details provided, click the{" "}
              <b>Confirm</b> button. This action will officially submit your
              case to our platform.
            </p>

            <h5>5.3 Check Your Status Page</h5>
            <p>
              After submission, you can check your <b>Status Page</b> for
              updates regarding your case. This page will provide ongoing
              information about the progress and any additional actions needed.
            </p>

            <h4>Conclusion</h4>

            <p>
              Congratulations! You’ve successfully learned how to add a new case
              to our fraud prevention and tracking platform. By following these
              steps, you contribute to our collective efforts in combating fraud
              and recovering lost assets. Remember, the more detailed and
              accurate information you provide, the better our chances of
              tracing and tracking the suspect. If you have any questions or
              need further assistance, don’t hesitate to reach out through our
              help desk. Thank you for playing a vital role in our community!
            </p>
          </Typography>
        </div>
      </GridWrapper2>
    );
  }
  return (
    <GridWrapper>
      <div className="scaminfodata">
        <header>How to start a new case</header>
      </div>

      <div className="updateimgbox"></div>

      <div className="helpinfodata">
        <Typography>
          <h4>Tutorial: How to Add a New Case</h4>

          <p>
            Welcome to our comprehensive tutorial on how to add a new case to
            our fraud prevention and tracking platform. This guide will walk you
            through the process step-by-step, ensuring you understand how to
            effectively report fraudulent activities and contribute to the fight
            against scams.
          </p>

          <h4>Overview</h4>

          <p>
            Adding a new case is an essential feature of our platform that
            allows users to report incidents of fraud and initiate the recovery
            process. The process is designed to be user-friendly and
            straightforward. By providing detailed information about the fraud,
            you enable our team to track and trace lost funds effectively. Let’s
            dive into the process.
          </p>

          <h4>Step 1: Navigating to the Case Addition Section</h4>

          <h5>1.1 Access the Home Tab</h5>
          <p>
            Start by navigating to the <b>Home</b> tab on our platform. This is
            where you’ll find the main navigation options available to you. The
            Home tab is your starting point for most activities on our site.
          </p>

          <h5>1.2 Click on the Add Button</h5>
          <p>
            Once you’re on the Home tab, look for the <b>Add Case</b> button.
            This button is typically located above the section displaying
            existing cases. Clicking this will direct you to the case submission
            page.
          </p>

          <h5>1.3 Alternative Access</h5>
          <p>
            Alternatively, you can also click on the <b>Add Case</b> button
            located at the top of your navigation menu. This is a quick access
            point for initiating a new case submission from anywhere on the
            site.
          </p>

          <div className="imgbox updateimgbox">
            <img src={addcaseimg} alt="" />
          </div>

          <h4>Step 2: Inputting General Information</h4>

          <p>
            Adding a new case is broken down into several steps, starting with
            the <b>General Information</b> section. This step is crucial as it
            provides the foundational data for your report.
          </p>

          <h5>2.1 Entering Suspect Information</h5>
          <ul>
            <li>
              <b>Suspect Name</b>: Input the name of the suspected scammer. This
              should be the name they used during the fraudulent activity. If
              you have multiple names associated with the suspect, include all
              relevant aliases to improve tracking.
            </li>
          </ul>

          <h5>2.2 Selecting the Fraud Type</h5>
          <ul>
            <li>
              <b>Fraud Type</b>: Choose the type of fraud that best describes
              the incident. Our platform categorizes various types of fraud,
              such as romance scams, investment fraud, and cryptocurrency scams.
              Understanding the type of fraud is vital since recovery chances
              can vary. For instance, romance scams often yield better recovery
              results than cryptocurrency scams due to their semi-anonymous
              nature.
            </li>
          </ul>

          <h5>2.3 Reporting Financial Loss</h5>
          <ul>
            <li>
              <b>Amount Lost</b>: Enter the total value of assets lost due to
              the fraudulent activity. This should be the most accurate figure
              you can provide, as it helps us prioritize cases based on
              severity.
            </li>
          </ul>

          <h5>2.4 Document Upload</h5>
          <ul>
            <li>
              <b>Documents Upload</b>: It’s essential to support your case with
              evidence. Upload any documents, images, or chat conversations that
              prove the fraudulent activity. This information will be verified
              as part of our fact-checking process and is crucial for recovering
              lost assets.
            </li>
          </ul>

          <div className="imgbox updateimgbox">
            <img src={socialimg} alt="" />
          </div>

          <h4>Step 3: Social Engagement</h4>

          <p>
            The second stage involves collecting social engagement information,
            which is vital for tracking and tracing the suspect.
          </p>

          <h5>3.1 Enter Known Contact Information</h5>
          <ul>
            <li>
              <b>Email</b>: Provide any known email addresses associated with
              the suspect. This could be the email they used to communicate with
              you or any emails linked to the fraudulent activity.
            </li>
            <li>
              <b>Phone Number</b>: Enter any known phone numbers belonging to
              the suspect. This can include numbers used for contact or those
              appearing in correspondence.
            </li>
          </ul>

          <h5>3.2 Website Information</h5>
          <ul>
            <li>
              <b>Website Information</b>: If applicable, input the URL of any
              websites connected to the suspect. This could be a site where the
              fraud occurred or one that the suspect owns.
            </li>
          </ul>

          <h5>3.3 Additional Information</h5>
          <ul>
            <li>
              <b>Additional Info</b>: Use this section to provide any other
              pertinent details that may assist in the investigation. This could
              include unusual behaviors, patterns of communication, or any other
              observations.
            </li>
          </ul>

          <h5>3.4 Social Media Links</h5>
          <ul>
            <li>
              <b>Social Links</b>: Include links to the suspect's social media
              profiles or any accounts that were involved in the fraud.
              Providing at least one social link is mandatory, as it
              significantly enhances our ability to track and trace the
              suspect’s activities.
            </li>
          </ul>

          <h5>3.5 Utilizing SM Bundler</h5>
          <ul>
            <li>
              If you have multiple valid social media links, our{" "}
              <b>SM Bundler</b> tool can help aggregate and identify other
              associated accounts. This tool uses the information you provide to
              uncover more about the suspect, increasing the likelihood of
              successful recovery.
            </li>
          </ul>

          <h4>Step 4: Payment Forensics</h4>

          <p>
            The final stage of adding a new case is focused on payment
            forensics. This section involves tracing financial transactions
            related to the fraud.
          </p>

          <h5>4.1 Forensic Accounting</h5>
          <ul>
            <li>
              <b>Payments Forensics</b>: This involves detailing any
              transactions that occurred as part of the fraud. Providing
              transaction IDs, payment methods, and any other financial data is
              critical for uncovering information about the scammer.
            </li>
          </ul>

          <h5>4.2 On-Chain Analysis</h5>
          <ul>
            <li>
              <b>CypherBrute Tool</b>: For cases involving cryptocurrency, we
              have a specialized tool called <b>CypherBrute</b>. This tool is
              used for on-chain analysis and can help monitor suspected crypto
              wallets. Please note that CypherBrute is limited to certain
              blockchains, so ensure that your case fits within those
              parameters.
            </li>
          </ul>
          <div className="imgbox updateimgbox">
            <img src={confirmimg} alt="" />
          </div>

          <h4>Step 5: Confirming Your Case</h4>

          <h5>5.1 Review Your Information</h5>
          <p>
            Before confirming, take a moment to review all the information
            you’ve entered. Ensure that it is accurate and complete, as this
            will directly impact the success of your case.
          </p>

          <h5>5.2 Click on the Confirm Button</h5>
          <p>
            Once you are satisfied with the details provided, click the{" "}
            <b>Confirm</b> button. This action will officially submit your case
            to our platform.
          </p>

          <h5>5.3 Check Your Status Page</h5>
          <p>
            After submission, you can check your <b>Status Page</b> for updates
            regarding your case. This page will provide ongoing information
            about the progress and any additional actions needed.
          </p>

          <h4>Conclusion</h4>

          <p>
            Congratulations! You’ve successfully learned how to add a new case
            to our fraud prevention and tracking platform. By following these
            steps, you contribute to our collective efforts in combating fraud
            and recovering lost assets. Remember, the more detailed and accurate
            information you provide, the better our chances of tracing and
            tracking the suspect. If you have any questions or need further
            assistance, don’t hesitate to reach out through our help desk. Thank
            you for playing a vital role in our community!
          </p>
        </Typography>
      </div>
    </GridWrapper>
  );
};

export default HelpNewCases;
