import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getUser, updateUser } from "../../../services/master"; // Assuming you have an updateUser service
import { Button, TextField, Checkbox, FormControlLabel } from "@mui/material";

const EditUser = () => {
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    country: "",
    isFirm: false,
    isVerified: false,
    isAdmin: false,
    isMaster: false,
  });
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Fetch user data
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await getUser(id); // Fetch user data by ID
        setUser(response.data);
      } catch (error) {
        console.error("Error fetching user", error);
        setError("Failed to load user. Please try again.");
      } finally {
        setLoading(false);
      }
    };
    fetchUser();
  }, [id]);

  // Handle input change for text fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  // Handle checkbox change
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: checked,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await updateUser(id, user); // Send updated user data to the backend
      navigate("/master"); // Redirect to the user list or another page after successful update
    } catch (error) {
      console.error("Error updating user", error);
      setError("Failed to update user. Please try again.");
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="editusermaster">
      <h2>Edit User</h2>
      <form onSubmit={handleSubmit}>
        {/* Text Inputs for user fields */}
        <TextField
          label="First Name"
          name="firstName"
          value={user.firstName}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Last Name"
          name="lastName"
          value={user.lastName}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Email"
          name="email"
          value={user.email}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Phone"
          name="phone"
          value={user.phone}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Country"
          name="country"
          value={user.country}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />

        {/* Checkbox Inputs for boolean fields */}
        <FormControlLabel
          control={
            <Checkbox
              checked={user.isFirm}
              onChange={handleCheckboxChange}
              name="isFirm"
            />
          }
          label="Is Firm"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={user.isVerified}
              onChange={handleCheckboxChange}
              name="isVerified"
            />
          }
          label="Is Verified"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={user.isAdmin}
              onChange={handleCheckboxChange}
              name="isAdmin"
            />
          }
          label="Is Admin"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={user.isMaster}
              onChange={handleCheckboxChange}
              name="isMaster"
            />
          }
          label="Is Master"
        />

        {/* Submit Button */}
        <Button variant="contained" type="submit">
          Save Changes
        </Button>
      </form>
    </div>
  );
};

export default EditUser;
