import React, { useState } from "react";
import { TextField, Button, Box, Typography, Container } from "@mui/material";
import { resetPassword } from "../../../services/auth"; // Import the resetPassword function
import { toast } from "react-toastify";

const ResetPassword = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleReset = async () => {
    toast.dismiss();

    if (!currentPassword || !newPassword || !confirmPassword) {
      toast.error("All fields are required.");
      return;
    }

    if (newPassword !== confirmPassword) {
      toast.error("New passwords do not match.");
      return;
    }

    try {
      // Call the resetPassword function from the service
      await resetPassword(currentPassword, newPassword);
      toast.success("Password changed successfully!"); // Show success toast
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (error) {
      toast.error("An error occurred. Please try again."); // Show error toast
    }
  };

  return (
    <Container maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>

        {/* Current Password Input */}
        <TextField
          margin="normal"
          required
          fullWidth
          label="Current Password"
          type="password"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          autoFocus
        />

        {/* New Password Input */}
        <TextField
          margin="normal"
          required
          fullWidth
          label="New Password"
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />

        {/* Confirm New Password Input */}
        <TextField
          margin="normal"
          required
          fullWidth
          label="Confirm New Password"
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />

        {/* Reset Password Button */}
        <Button
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 3, mb: 2 }}
          onClick={handleReset}
        >
          Reset Password
        </Button>
      </Box>
    </Container>
  );
};

export default ResetPassword;
