import React from "react";
import GridWrapper from "../../../common/GridWrapper/GridWrapper2";
import CaseList from "./CasesComponent/CaseList";

import { Typography } from "@mui/material";

const CasesMobile = ({ caselist }) => {
  const getContent = () => (
    <Typography
      align="center"
      sx={{
        margin: "40px 16px",
        color: "rgba(0, 0, 0, 0.6)",
        fontSize: "1.3rem",
      }}
    >
      No active cases
    </Typography>
  );
  return (
    <GridWrapper>{caselist.length ? <CaseList /> : getContent()}</GridWrapper>
  );
};

export default CasesMobile;
