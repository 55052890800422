import React from "react";
import "./styles.css";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Link } from "react-router-dom";

const StatusLocation = ({
  trackedip,
  ispname,
  osname,
  coordinates,
  mapdata,
  geoip,
  geoispname,
  geocoordinates,
  geomapdata,
  geoosname,
  classname,
  geoclassname,
  location,
  geolocation,
}) => {
  return (
    <div className="statuslocation">
      <div className="statuslocationboxinner">
        <div className="statuslocationbox">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Location</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>IP</TableCell>
                <TableCell className="locationmargin">
                  {trackedip}
                  <span className={classname}></span>
                  {trackedip && <Link to={mapdata}>{location}</Link>}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>ISP</TableCell>
                <TableCell>
                  {ispname || <div className="notavailable">NA</div>}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>OS</TableCell>
                <TableCell>
                  {osname || <div className="notavailable">NA</div>}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Geo-tag</TableCell>
                <TableCell>
                  {coordinates || <div className="notavailable">NA</div>}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Geo Location</TableCell>
                <TableCell
                  className="mapdatatab"
                  style={{ overflow: "hidden" }}
                >
                  {(
                    <Link to={mapdata}>
                      <div
                        style={{
                          overflow: "hidden",
                          maxWidth: "15rem",
                          textWrap: "nowrap",
                        }}
                      >
                        {mapdata}
                      </div>
                    </Link>
                  ) || <div className="notavailable">Not available</div>}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
        <div className="statuslocationbox">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>True location</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow>
                <TableCell>IP</TableCell>
                <TableCell className="locationmargin">
                  {geoip || <div className="notavailable">NA</div>}{" "}
                  <span className={geoclassname}></span>
                  {geoip && <Link to={geomapdata}>{geolocation}</Link>}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>ISP</TableCell>
                <TableCell>
                  {geoispname || <div className="notavailable">NA</div>}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>OS</TableCell>
                <TableCell>
                  {geoosname || <div className="notavailable">NA</div>}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Geo-tag</TableCell>
                <TableCell>
                  {geocoordinates || <div className="notavailable">NA</div>}{" "}
                  <br />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Geo Location</TableCell>
                <TableCell
                  className="mapdatatab"
                  style={{ overflow: "hidden" }}
                  sx={{ overflow: "hidden" }}
                >
                  {(
                    <Link to={geomapdata}>
                      <div
                        style={{
                          overflow: "hidden",
                          maxWidth: "15rem",
                          textWrap: "nowrap",
                        }}
                      >
                        {geomapdata}
                      </div>
                    </Link>
                  ) || <div className="notavailable">pending</div>}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default StatusLocation;
