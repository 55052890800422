import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

const CaseHistory = ({ caselist }) => {
  const countCaseStatuses = (c) => {
    const statusCounts = {
      active: 0,
      inactive: 0,
      pending: 0,
    };

    c.forEach((caseItem) => {
      switch (caseItem.status.toLowerCase()) {
        case "active":
          statusCounts.active += 1;
          break;
        case "inactive":
          statusCounts.inactive += 1;
          break;
        case "pending":
          statusCounts.pending += 1;
          break;
        default:
          break;
      }
    });

    return statusCounts;
  };

  const calculateAveragePkValue = (caseList) => {
    let total = 0;
    let count = 0;

    caseList.forEach((caseItem) => {
      const value = parseFloat(caseItem.pk?.value);
      if (!isNaN(value)) {
        total += value;
        count++;
      }
    });

    return count > 0 ? total / count : 0;
  };

  const { active, inactive, pending } = countCaseStatuses(caselist);

  return (
    <div className="casehistory">
      <div className="casehistorybox">
        <Table sx={{ width: `40%` }}>
          <TableHead>
            <TableRow>
              <TableCell> Case History</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Active cases</TableCell>
              <TableCell>{active}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Inactive cases</TableCell>
              <TableCell>{inactive}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Pending cases</TableCell>
              <TableCell>{pending}</TableCell>
            </TableRow>
          </TableBody>
          <TableHead>
            <TableRow>
              <TableCell>
                P<sub>K</sub> Ratio
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Successful cases</TableCell>
              <TableCell>0</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Failed cases</TableCell>
              <TableCell>0</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Win ratio</TableCell>
              <TableCell>NA</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Combined p<sub>k</sub>
              </TableCell>
              <TableCell>{calculateAveragePkValue(caselist)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default CaseHistory;
