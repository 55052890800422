import React from "react";
import "./styles.css";
import "/node_modules/flag-icons/css/flag-icons.min.css";

const MiniLocation = ({ classname, trackedip, osname, ispname }) => {
  const boxstyles = {
    marginTop: "0.5rem",
    marginLeft: "0.5rem",
  };
  return (
    <div className="locationbox">
      <div className=" casetrackedbox">
        <article className="casetrackedboxarticle" style={boxstyles}>
          True Location
        </article>
        <div>
          <p className="locationipname" style={boxstyles}>
            <span className={classname}></span>{" "}
            <span className="margin1"></span>
            {trackedip || "NA"}
          </p>
        </div>
      </div>
      <div className=" casetrackedbox">
        <article className="casetrackedboxarticle" style={boxstyles}>
          ISP
        </article>
        <p style={boxstyles}>{ispname || "NA"}</p>
      </div>
      <div className=" casetrackedbox">
        <article className="casetrackedboxarticle" style={boxstyles}>
          OS
        </article>
        <p style={boxstyles}>{osname || "NA"}</p>
      </div>
    </div>
  );
};

export default MiniLocation;
