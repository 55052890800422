import React from "react";
import GridWrapper from "../../../common/GridWrapper/GridWrapper2";
import BasicCard from "../../../common/BasicCard/BasicCard";
import Typography from "@mui/material/Typography";
import CaseList from "../Cases/CasesComponent/CaseList";
import ProductHome from "./ProductsHome";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import { useNavigate } from "react-router";

const HomeMobile = ({ caselist, products }) => {
  const navigate = useNavigate();
  const getcases = () => {
    if (caselist.length) return <CaseList />;
    else return getContent();
  };
  const getContent = () => (
    <Typography
      align="center"
      sx={{
        margin: "2.5rem 1rem",
        color: "rgba(0, 0, 0, 0.6)",
        fontSize: "1.3rem",
      }}
    >
      No active cases <br />
      <Button
        style={{ marginTop: "1rem" }}
        onClick={() => navigate("/account/add-case")}
        variant="contained"
      >
        Add
      </Button>
    </Typography>
  );
  const productClick = () => {
    toast.info("API endpoints are for commercial use only");
  };
  return (
    <GridWrapper>
      <BasicCard content={getcases()} />
      {products &&
        products.map((p) => (
          <ProductHome products={p} productClick={productClick} />
        ))}
    </GridWrapper>
  );
};

export default HomeMobile;
