import React, { useEffect, useState } from "react";
import { deleteError, getErrors, sendError } from "../../../services/master";
import {
  TextField,
  Button,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Table,
} from "@mui/material";
import { useParams } from "react-router";
import { toast } from "react-toastify";

const ErrorLogs = () => {
  const { id } = useParams();
  const [errors, setErrors] = useState([]);
  const [formData, setFormData] = useState({ errorlogs: "" });

  useEffect(() => {
    const getLogs = async () => {
      try {
        const { data } = await getErrors(id);
        setErrors(data);
      } catch (error) {
        toast.error("Failed to fetch error logs");
      }
    };
    getLogs();
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    const { errorlogs } = formData;
    if (!errorlogs) {
      toast.warn("Please fill in all fields before submitting.");
      return;
    }

    try {
      await sendError(formData, id);
      toast.success("Error sent successfully!");
      setFormData({ errorlogs: "" });
    } catch (error) {
      toast.error("Failed to send error. Please try again.");
    }
  };

  const handleDelete = async (errorId) => {
    try {
      await deleteError(errorId);
      setErrors(errors.filter((e) => e._id !== errorId)); // Update the state to remove the deleted error
      toast.success("Error log deleted successfully");
    } catch (ex) {
      toast.error("Failed to delete error log");
    }
  };

  return (
    <div className="errorlogs" style={{ padding: "3rem" }}>
      <Table container>
        <TableHead>
          <TableRow>
            <TableCell>Error</TableCell>
            <TableCell>Date</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {errors.map((e) => (
            <TableRow key={e._id}>
              <TableCell>{e.errorlog}</TableCell>
              <TableCell>{e.date}</TableCell>
              <TableCell>
                <Button variant="contained" onClick={() => handleDelete(e._id)}>
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <div className="adminsendcontent">
        <TextField
          name="errorlogs"
          value={formData.errorlogs}
          onChange={handleInputChange}
          id="outlined-multiline-static"
          label="Error Logs"
          multiline
          rows={2}
          placeholder="Enter error logs here..."
          fullWidth
        />
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Send Error
        </Button>
      </div>
    </div>
  );
};

export default ErrorLogs;
