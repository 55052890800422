import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export function getInboxes() {
  const token = localStorage.getItem("token");
  return axios.get(`${apiUrl}/api/messages`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getInboxProp() {
  const token = localStorage.getItem("token");
  return axios.get(`${apiUrl}/api/props`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getInbox(id) {
  const token = localStorage.getItem("token");
  return axios.get(`${apiUrl}/api/messages/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function patchInbox(id) {
  const token = localStorage.getItem("token");
  return axios.patch(`${apiUrl}/api/messages/${id}/new`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
