import React from "react";
import { Typography } from "@mui/material";
import GridWrapper from "../../../../../common/GridWrapper/GridWrapper";
import cyberimg from "../../../../../../assets/helpimg/cyberhashing.png";
import alocalimg from "../../../../../../assets/helpimg/analyticslocation.png";
import { useMediaQuery } from "react-responsive";
import GridWrapper2 from "../../../../../common/GridWrapper/GridWrapper2";

const HelpAnalytics = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  if (isMobile) {
    return (
      <GridWrapper2>
        <div className="scaminfodata helpinfoheader">
          <header>Understanding Case Analytics</header>
        </div>

        <div className="helpinfodata">
          <Typography>
            <h4>Tutorial: Understanding Case Analytics</h4>

            <p>
              Welcome to this tutorial on how to interpret the analytics
              information pertaining to your case on our fraud prevention and
              tracking platform. The analytics page is a powerful tool that
              provides you with valuable insights into various aspects of your
              case, enhancing your understanding and engagement with our
              services.
            </p>

            <h4>Step 1: Accessing the Analytics Page</h4>

            <p>
              To start exploring the analytics for your case, navigate to the{" "}
              <b>Analytics</b> page within the platform.
            </p>

            <ul>
              <li>Log in to your account.</li>
              <li>
                Click on the <b>Analytics</b> tab in the main menu to access
                detailed information related to your case.
              </li>
            </ul>

            <h4>Step 2: True Location Details</h4>
            <div className="updateimgbox">
              <img src={alocalimg} alt="" />
            </div>

            <p>
              One of the key features of the analytics page is the display of
              true location details of the suspect.
            </p>

            <ul>
              <li>
                Here, you will find accurate location information that our tools
                have gathered.
              </li>
              <li>
                This data is essential for understanding where the suspect is
                operating from and is vital for recovery efforts.
              </li>
            </ul>

            <h4>Step 3: Tracking Valid Social Links</h4>

            <p>
              The analytics page also shows valid social links that are
              currently being tracked.
            </p>

            <ul>
              <li>
                This section provides insights into the social media accounts
                and profiles associated with the suspect.
              </li>
              <li>
                Tracking these links enhances our ability to gather more
                information and aids in the recovery process.
              </li>
            </ul>

            <h4>Step 4: Payments Forensics Information</h4>

            <p>
              In addition to social links, the analytics page provides
              information on valid payments forensics that are currently
              undergoing tracking.
            </p>

            <ul>
              <li>
                This includes details about any payments made by the suspect and
                ongoing forensic analysis of those transactions.
              </li>
              <li>
                Understanding these payments is crucial in tracing the flow of
                funds and uncovering additional evidence.
              </li>
            </ul>

            <h4>Step 5: Tools for Enhanced Tracking</h4>
            <div className="updateimgbox">
              <img src={cyberimg} alt="" />
            </div>

            <p>
              Our platform employs both indigenous tools and paid subscriptions
              to geo-locating tools to enhance tracking results.
            </p>

            <ul>
              <li>
                Indigenous tools are developed in-house to aid in tracking
                attempts, while paid subscriptions provide access to advanced
                geo-locating technologies.
              </li>
              <li>
                This combination is designed to increase our win ratio and
                improve the effectiveness of our recovery efforts.
              </li>
            </ul>

            <h4>
              Step 6: Viewing P<sub>K</sub> Values
            </h4>

            <p>
              The analytics page also displays the P<sub>K</sub> values relevant
              to your case.
            </p>

            <ul>
              <li>
                P<sub>K</sub> values are general indicators that reflect the
                chances of success or failure in recovering lost assets.
              </li>
              <li>
                You can also see P<sub>K</sub> boosters and antagonists, which
                are factors causing positive or negative shifts in your P
                <sub>K</sub> value.
              </li>
            </ul>

            <h4>Step 7: Case Duration and Date</h4>

            <p>
              On the analytics tab, you can view the date the case was created
              and its current duration.
            </p>

            <ul>
              <li>
                This information is useful for understanding how long the case
                has been active and provides context for the analysis presented.
              </li>
              <li>
                Tracking the duration of your case can help you gauge the
                ongoing efforts and their effectiveness over time.
              </li>
            </ul>

            <h4>Conclusion</h4>

            <p>
              Understanding case analytics is crucial for effective engagement
              with our platform. The analytics page provides comprehensive
              insights that can significantly influence your approach to
              recovery efforts. By following the steps outlined in this
              tutorial, you can make the most of the data available to you and
              enhance your strategy for case management.
            </p>

            <p>
              If you have any questions or need further assistance in
              interpreting your case analytics, please feel free to reach out
              through our help desk. Thank you for being a part of our community
              dedicated to fighting fraud!
            </p>
          </Typography>
        </div>
      </GridWrapper2>
    );
  }

  return (
    <GridWrapper>
      <div className="scaminfodata">
        <header>How to start a new case</header>
      </div>

      <div className="updateimgbox"></div>

      <div className="helpinfodata">
        <Typography>
          <h4>Tutorial: Understanding Case Analytics</h4>

          <p>
            Welcome to this tutorial on how to interpret the analytics
            information pertaining to your case on our fraud prevention and
            tracking platform. The analytics page is a powerful tool that
            provides you with valuable insights into various aspects of your
            case, enhancing your understanding and engagement with our services.
          </p>

          <h4>Step 1: Accessing the Analytics Page</h4>

          <p>
            To start exploring the analytics for your case, navigate to the{" "}
            <b>Analytics</b> page within the platform.
          </p>

          <ul>
            <li>Log in to your account.</li>
            <li>
              Click on the <b>Analytics</b> tab in the main menu to access
              detailed information related to your case.
            </li>
          </ul>

          <h4>Step 2: True Location Details</h4>
          <div className="updateimgbox">
            <img src={alocalimg} alt="" />
          </div>

          <p>
            One of the key features of the analytics page is the display of true
            location details of the suspect.
          </p>

          <ul>
            <li>
              Here, you will find accurate location information that our tools
              have gathered.
            </li>
            <li>
              This data is essential for understanding where the suspect is
              operating from and is vital for recovery efforts.
            </li>
          </ul>

          <h4>Step 3: Tracking Valid Social Links</h4>

          <p>
            The analytics page also shows valid social links that are currently
            being tracked.
          </p>

          <ul>
            <li>
              This section provides insights into the social media accounts and
              profiles associated with the suspect.
            </li>
            <li>
              Tracking these links enhances our ability to gather more
              information and aids in the recovery process.
            </li>
          </ul>

          <h4>Step 4: Payments Forensics Information</h4>

          <p>
            In addition to social links, the analytics page provides information
            on valid payments forensics that are currently undergoing tracking.
          </p>

          <ul>
            <li>
              This includes details about any payments made by the suspect and
              ongoing forensic analysis of those transactions.
            </li>
            <li>
              Understanding these payments is crucial in tracing the flow of
              funds and uncovering additional evidence.
            </li>
          </ul>

          <h4>Step 5: Tools for Enhanced Tracking</h4>
          <div className="updateimgbox">
            <img src={cyberimg} alt="" />
          </div>

          <p>
            Our platform employs both indigenous tools and paid subscriptions to
            geo-locating tools to enhance tracking results.
          </p>

          <ul>
            <li>
              Indigenous tools are developed in-house to aid in tracking
              attempts, while paid subscriptions provide access to advanced
              geo-locating technologies.
            </li>
            <li>
              This combination is designed to increase our win ratio and improve
              the effectiveness of our recovery efforts.
            </li>
          </ul>

          <h4>
            Step 6: Viewing P<sub>K</sub> Values
          </h4>

          <p>
            The analytics page also displays the P<sub>K</sub> values relevant
            to your case.
          </p>

          <ul>
            <li>
              P<sub>K</sub> values are general indicators that reflect the
              chances of success or failure in recovering lost assets.
            </li>
            <li>
              You can also see P<sub>K</sub> boosters and antagonists, which are
              factors causing positive or negative shifts in your P<sub>K</sub>{" "}
              value.
            </li>
          </ul>

          <h4>Step 7: Case Duration and Date</h4>

          <p>
            On the analytics tab, you can view the date the case was created and
            its current duration.
          </p>

          <ul>
            <li>
              This information is useful for understanding how long the case has
              been active and provides context for the analysis presented.
            </li>
            <li>
              Tracking the duration of your case can help you gauge the ongoing
              efforts and their effectiveness over time.
            </li>
          </ul>

          <h4>Conclusion</h4>

          <p>
            Understanding case analytics is crucial for effective engagement
            with our platform. The analytics page provides comprehensive
            insights that can significantly influence your approach to recovery
            efforts. By following the steps outlined in this tutorial, you can
            make the most of the data available to you and enhance your strategy
            for case management.
          </p>

          <p>
            If you have any questions or need further assistance in interpreting
            your case analytics, please feel free to reach out through our help
            desk. Thank you for being a part of our community dedicated to
            fighting fraud!
          </p>
        </Typography>
      </div>
    </GridWrapper>
  );
};

export default HelpAnalytics;
