import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export function getProducts() {
  const token = localStorage.getItem("token");
  return axios.get(`${apiUrl}/api/products`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function addProducts(product) {
  const token = localStorage.getItem("token");
  return axios.post(`${apiUrl}/api/products`, product, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getProduct(id) {
  const token = localStorage.getItem("token");
  return axios.get(`${apiUrl}/api/products/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
