import { Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode"; // For decoding JWT

const PublicRoute = ({ children }) => {
  const token = localStorage.getItem("token");

  // Helper function to check if the token is valid and not expired
  const isAuthenticated = () => {
    if (!token) return false;

    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Time in seconds
      return decodedToken.exp > currentTime; // Token is valid if it's not expired
    } catch (error) {
      return false; // If decoding fails, treat it as not authenticated
    }
  };

  // If the user is already authenticated, redirect to the dashboard (or any private route)
  if (isAuthenticated()) {
    return <Navigate to="/account/home" replace />;
  }

  // Return the public page (landing, login, etc.) if the user is not authenticated
  return children;
};

export default PublicRoute;
